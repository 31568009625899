import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { MouseEvent, ReactNode, useId, useState } from 'react';
import { TemporaryNonShiftType, RosterEmployee } from '@stationwise/share-types';
import { filterTemporaryNonShiftPayCodes } from '@stationwise/shift-summary-helper';
import { ChevronDownIcon16 } from '../../../../assets';
import { getVisibleTags } from '../../../../utils/utils';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { PreviewButton, ViewProfileButton } from '../../../EmployeePreview';
import { useRosterContext } from '../RosterContext';

interface ManageShiftActionsProps {
  employee: RosterEmployee;
}

export const ManageShiftActions = ({ employee }: ManageShiftActionsProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const overtimeTradeEnabled = departmentInfoState.departmentInfo.settings.overtimeTradeEnabled;
  const { forceShiftTradeState, selectedFilledPositionState, splitShiftOrTimeOffState } = useRosterContext();

  const theme = useTheme();

  const id = useId();
  const [manageAnchorEl, setManageAnchorEl] = useState<HTMLElement | null>(null);

  const openManageMenu = (event: MouseEvent<HTMLButtonElement>) => setManageAnchorEl(event.currentTarget);
  const closeManageMenu = () => setManageAnchorEl(null);

  const openSplitShiftOrTimeOffModal = () => {
    splitShiftOrTimeOffState.setIsDialogOpen(true);
    closeManageMenu();
  };

  const openMoveToTemporaryNonShiftModal = (temporaryNonShiftType: TemporaryNonShiftType) => {
    selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(temporaryNonShiftType);
    closeManageMenu();
  };

  const openForceShiftTrade = (overtimeTrade?: boolean) => {
    forceShiftTradeState.setIsOvertimeShiftTrade(!!overtimeTrade);
    forceShiftTradeState.setIsDialogOpen(true);
    closeManageMenu();
  };

  const checkIsTemporaryNonShiftTypeEnabled = (temporaryNonShiftType: TemporaryNonShiftType, visibleTags: string[]) => {
    return (
      filterTemporaryNonShiftPayCodes(departmentInfoState.departmentInfo.payCodes, temporaryNonShiftType, visibleTags).length > 0
    );
  };

  const defaultMenuItem = (
    <MenuItem key="split-shift-or-time-off" onClick={openSplitShiftOrTimeOffModal}>
      Modify or split shift
    </MenuItem>
  );

  const getVisibleTagsForTemporaryNonShiftType = (temporaryNonShiftType: TemporaryNonShiftType) => {
    const visibilityTag = temporaryNonShiftType === TemporaryNonShiftType.EXTENDED_LEAVE ? 'EXT_LEAVE' : 'LIGHT_DUTY';
    return getVisibleTags(departmentInfoState.departmentInfo, visibilityTag, 'paycode');
  };

  const eligibleForOvertimeShiftTrade = (employee: RosterEmployee) => {
    return overtimeTradeEnabled && employee.payCodes.some((payCode) => payCode.payCodeType === 'OVERTIME') && !employee.trade;
  };
  const disableRegularTradeOnOvertime = (employee: RosterEmployee) => {
    return !employee.payCodes.some((payCode) => payCode.payCodeType === 'OVERTIME');
  };

  const menuItems: ReactNode[] = [
    defaultMenuItem,
    checkIsTemporaryNonShiftTypeEnabled(
      TemporaryNonShiftType.LIGHT_DUTY,
      getVisibleTagsForTemporaryNonShiftType(TemporaryNonShiftType.LIGHT_DUTY),
    ) && (
      <MenuItem key="light-duty" onClick={() => openMoveToTemporaryNonShiftModal(TemporaryNonShiftType.LIGHT_DUTY)}>
        Move to light duty (on roster)
      </MenuItem>
    ),
    checkIsTemporaryNonShiftTypeEnabled(
      TemporaryNonShiftType.EXTENDED_LEAVE,
      getVisibleTagsForTemporaryNonShiftType(TemporaryNonShiftType.EXTENDED_LEAVE),
    ) && (
      <MenuItem
        data-cy="move-to-extended-leave-button"
        key="extended-leave"
        onClick={() => openMoveToTemporaryNonShiftModal(TemporaryNonShiftType.EXTENDED_LEAVE)}
      >
        Move to extended leave (off roster)
      </MenuItem>
    ),
    disableRegularTradeOnOvertime(employee) && (
      <MenuItem key="force-shift-trade" onClick={() => openForceShiftTrade()}>
        Force shift trade
      </MenuItem>
    ),

    eligibleForOvertimeShiftTrade(employee) && (
      <MenuItem key="force-overtime-shift-trade" onClick={() => openForceShiftTrade(true)}>
        Force overtime trade
      </MenuItem>
    ),
  ].filter(Boolean);

  if (!menuItems.length) {
    return null;
  }

  if (menuItems.length === 1 && menuItems[0] === defaultMenuItem) {
    return (
      <Box sx={{ mt: 2, '& .MuiButton-root': { width: '100%' } }}>
        <PreviewButton variant="contained" onClick={openSplitShiftOrTimeOffModal}>
          Manage shift
        </PreviewButton>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, minWidth: '274px', '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          data-cy="manage-shift-button"
          variant="contained"
          id={`${id}manage-button`}
          aria-controls={manageAnchorEl ? `${id}manage-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={manageAnchorEl ? 'true' : undefined}
          onClick={openManageMenu}
          sx={{
            gap: 0.5,
            '& svg': { minWidth: '16px' },
            '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
          }}
        >
          <Box component="span" sx={{ ml: 0.25 }}>
            Manage shift
          </Box>
          <ChevronDownIcon16 />
        </PreviewButton>
      </Box>
      <Menu
        id={`${id}manage-menu`}
        anchorEl={manageAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!manageAnchorEl}
        onClose={closeManageMenu}
        MenuListProps={{ 'aria-labelledby': `${id}manage-button` }}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.stationGray[700],
            border: `1px solid ${theme.palette.stationGray[600]}`,
            borderRadius: '12px',
          },
          '& .MuiMenu-list': {
            p: theme.spacing(1.5),
          },
          '& .MuiMenuItem-root': {
            backgroundColor: theme.palette.stationGray[700],
            borderRadius: '8px',
            color: theme.palette.common.white,
            p: theme.spacing(1, 1.5),
            typography: 'bodySRegular',
          },
          '& .MuiMenuItem-root.Mui-disabled': {
            color: theme.palette.stationGray[400],
            opacity: 1,
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: theme.palette.stationGray[800],
          },
        }}
      >
        {menuItems}
      </Menu>
    </Box>
  );
};
