import { Box, Tab, TabProps, Tabs } from '@mui/material';
import React, { useState } from 'react';
import {
  CapabilityPermission,
  Certification,
  DetailCode,
  PayCodeComplete,
  RankComplete,
  TimeOffAccruals,
} from '@stationwise/share-types';
import { DepartmentSettingsTab } from './DepartmentSettingsTab';
import { ElementTypes } from './DepartmentSettingsUtil';
import { HiringEngineDepartmentSettingsTab } from './HiringEngineDepartmentSettingsTab';

interface CustomTabProps extends TabProps {
  label: string;
  value: number;
}

const CustomTab = React.forwardRef<HTMLDivElement, CustomTabProps>(function CustomTab(props, ref) {
  const { label, value, ...otherProps } = props;
  return (
    <Tab
      ref={ref}
      {...otherProps}
      sx={(theme) => ({
        textTransform: 'none',
        color: theme.palette.stationGray[400],
        typography: 'bodyXLMedium',
        '&.Mui-selected': {
          color: theme.palette.stationGray[900],
        },
        '&:hover': {
          color: theme.palette.stationGray[900],
        },
      })}
      label={label}
      value={value}
    />
  );
});

export const DepartmentSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [selectedCertification, setSelectedCertification] = useState<Certification>();
  const [ranks, setRanks] = useState<RankComplete[]>([]);
  const [selectedRank, setSelectedRank] = useState<RankComplete>();
  const [detailCodes, setDetailCodes] = useState<DetailCode[]>([]);
  const [selectedDetailCode, setSelectedDetailCode] = useState<DetailCode>();
  const [payCodes, setPayCodes] = useState<PayCodeComplete[]>([]);
  const [selectedPayCode, setSelectedPayCode] = useState<PayCodeComplete>();
  const [capabilityPermissions, setCapabilityPermissions] = useState<CapabilityPermission[]>([]);
  const [selectedCapabilityPermission, setSelectedCapabilityPermission] = useState<CapabilityPermission>();
  const [timeOffAccruals, setTimeOffAccruals] = useState<TimeOffAccruals[]>([]);
  const [selectedTimeOffAccrual, setSelectedTimeOffAccrual] = useState<TimeOffAccruals>();

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedRank(undefined);
    setSelectedCertification(undefined);
    setSelectedDetailCode(undefined);
    setSelectedPayCode(undefined);
    setSelectedCapabilityPermission(undefined);
    setSelectedTimeOffAccrual(undefined);
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Box sx={{ px: 5, pt: 4, pb: 8 }}>
        <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
          Department Settings
        </Box>
      </Box>
      <Box sx={{ minWidth: '1040px', width: '80%', px: 5, mx: 'auto' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={(theme) => ({
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.stationPurple[500],
            },
            textTransform: 'none',
          })}
        >
          <CustomTab label="Ranks" value={0} data-cy={`rank-settings-tab`} />
          <CustomTab label="Certifications" value={1} data-cy={`certification-settings-tab`} />
          <CustomTab label="Detail Codes" value={2} data-cy={`detail-code-settings-tab`} />
          <CustomTab label="Pay Codes" value={3} data-cy={`pay-code-settings-tab`} />
          <CustomTab label="Permissions" value={4} data-cy={`permission-settings-tab`} />
          <CustomTab label="Hiring Engine" value={5} data-cy={`hiring-engine-settings-tab`} />
          <CustomTab label="Accrual Banks" value={6} data-cy={`accrual-bank-settings-tab`} />
        </Tabs>
        <Box sx={{ py: 2 }}>
          {selectedTab === 0 && (
            <DepartmentSettingsTab
              elements={ranks}
              setElements={setRanks}
              selectedElement={selectedRank}
              setSelectedElement={setSelectedRank}
              createUpdateUrl={'organization/ranks/'}
              elementType={ElementTypes.RANK}
              exportUrl={'organization/ranks/export/'}
            />
          )}
          {selectedTab === 1 && (
            <DepartmentSettingsTab
              elements={certifications}
              setElements={setCertifications}
              selectedElement={selectedCertification}
              setSelectedElement={setSelectedCertification}
              createUpdateUrl={'certification/certifications/'}
              elementType={ElementTypes.CERTIFICATION}
              isFilterLowerCase={true}
              exportUrl={'certification/certifications/export/'}
            />
          )}
          {selectedTab === 2 && (
            <DepartmentSettingsTab
              elements={detailCodes}
              setElements={setDetailCodes}
              selectedElement={selectedDetailCode}
              setSelectedElement={setSelectedDetailCode}
              createUpdateUrl={'payroll/detail-code/'}
              elementType={ElementTypes.DETAIL_CODE}
              exportUrl={'payroll/detail-code/export/'}
            />
          )}
          {selectedTab === 3 && (
            <DepartmentSettingsTab
              elements={payCodes}
              setElements={setPayCodes}
              selectedElement={selectedPayCode}
              setSelectedElement={setSelectedPayCode}
              createUpdateUrl={'payroll/pay-code/'}
              elementType={ElementTypes.PAY_CODE}
              exportUrl={'payroll/pay-code/export/'}
            />
          )}
          {selectedTab === 4 && (
            <DepartmentSettingsTab
              elements={capabilityPermissions}
              setElements={setCapabilityPermissions}
              selectedElement={selectedCapabilityPermission}
              setSelectedElement={setSelectedCapabilityPermission}
              createUpdateUrl={'customization/capability-permissions/'}
              elementType={ElementTypes.CAPABILITY_PERMISSION}
            />
          )}
          {selectedTab === 5 && <HiringEngineDepartmentSettingsTab />}

          {selectedTab === 6 && (
            <DepartmentSettingsTab
              elements={timeOffAccruals}
              setElements={setTimeOffAccruals}
              selectedElement={selectedTimeOffAccrual}
              setSelectedElement={setSelectedTimeOffAccrual}
              createUpdateUrl={'payroll/time-off-accruals/'}
              elementType={ElementTypes.TIME_OFF_ACCRUAL}
              hasFilters={false}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
