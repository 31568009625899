import { Box, Dialog, FormHelperText, useTheme } from '@mui/material';
import { format, isValid, startOfDay, setMinutes } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  AdminSelectItem,
  AdminAutocomplete,
  Button,
  DatePicker,
  InputLabel,
  RankBadge,
  TeamBadge,
  SimpleTimePicker,
  getTeamColors,
  asDepartmentDateTime,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { isAxiosError } from '@stationwise/share-api';
import { AllowedColors, ShiftPlanAction, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { differenceInUTCMinutes, shortenEmployeeName } from '@stationwise/share-utils';
import { diffCycleMinutes, getDepartmentPayCodes } from '@stationwise/shift-summary-helper';
import { initializeAssignments } from '../../../helpers/readAssignments';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { ShiftPlanDialogXButton, ShiftPlanDialogActions } from '../../ShiftPlanDialog';
import { UpsertAssignmentConflictsMessage } from './UpsertAssignmentConflictsMessage';

interface UpsertAssignmentFormProps {
  person: ShiftPlanCandidate | ShiftPlanAssignment | null;
  team: ShiftPlanAssignment['employee']['team'] | null;
  setSelectedPersonPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  setCandidateAssignments: (candidateId: string, assignments: ShiftPlanAssignment[]) => void;
}

const NULL_DATE = new Date('NULL_DATE');

export const UpsertAssignmentForm = ({ person, ...props }: UpsertAssignmentFormProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;
  const { shiftDuration, battalion, selectedPersonnelStruct, selectedAction, isSaving, setIsSaving, saveDraft } =
    useShiftPlanContext();

  const today = startOfDay(asDepartmentDateTime(departmentInfoState, new Date()));
  const { station, apparatus, position } = selectedPersonnelStruct;
  const assignment = person && 'employee' in person ? (person as ShiftPlanAssignment) : null;
  const employee = person && 'employee' in person ? person.employee : person;
  const teamOptions = departmentInfo.teams.map((t) => ({ color: t.color, label: t.name, value: t.name }));
  const payCodeOptions = departmentInfo.payCodes.map((pc) => ({ label: pc.name, value: pc.code, secondLabel: pc.code }));
  const detailCodeOptions = departmentInfo.detailCodes.map((dc) => ({ label: dc.name, value: dc.code, secondLabel: dc.code }));
  const isAddAction = selectedAction === ShiftPlanAction.ADD_ASSIGNMENT;
  const isEditAction = !!assignment && selectedAction === ShiftPlanAction.EDIT_ASSIGNMENT;

  const [startDate, setStartDate] = useState(() => {
    return isEditAction ? assignment.activationDate : today;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    return isEditAction ? assignment.deactivationDate : null;
  });
  const [startTime, setStartTime] = useState(() => {
    return isEditAction ? assignment.startDateTime : position?.startDateTime || shiftDuration.startTime;
  });
  const [endTime, setEndTime] = useState(() => {
    return isEditAction ? assignment.endDateTime : position?.endDateTime || shiftDuration.endTime;
  });
  const [selectedTeamOption, setSelectedTeamOption] = useState<AdminSelectItem | null>(() => {
    return teamOptions.find((o) => o.value === props.team?.name) || null;
  });
  const [selectedPayCodeOptions, setSelectedPayCodeOptions] = useState<AdminSelectItem[]>(() => {
    const codes = new Set(assignment?.payCodes.map((pc) => pc.code) || []);
    if (!assignment && employee) {
      getDepartmentPayCodes(departmentInfo, [employee.defaults.regularAssignmentPayCode]).forEach((pc) => codes.add(pc.code));
    }
    return payCodeOptions.filter((o) => codes.has(o.value));
  });
  const [selectedDetailCodeOptions, setSelectedDetailCodeOptions] = useState<AdminSelectItem[]>(() => {
    const codes = new Set(assignment?.detailCodes.map((pc) => pc.code) || []);
    return detailCodeOptions.filter((o) => codes.has(o.value));
  });
  const [conflictingAssignments, setConflictingAssignments] = useState<ShiftPlanAssignment[]>([]);
  const [isConflictingAssignmentsDialogOpen, setIsConflictingAssignmentsDialogOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  if (!(isAddAction || isEditAction) || !station || !apparatus || !position || !employee) {
    return null;
  }

  const getShiftTime = (key: 'start' | 'end', time: Date | null) => {
    if (!time || !isValid(time)) {
      return NULL_DATE;
    }

    const minutes = diffCycleMinutes(time, shiftDuration.startTime) || (key === 'end' ? 24 * 60 : 0);
    return setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minutes);
  };

  const errors = (() => {
    const errors: Record<string, string> = {};
    if (!isValid(startDate)) {
      errors.startDate = '';
    } else if (isAddAction && startDate < today) {
      errors.startDate = 'Start date cannot be before today';
    }
    if (endDate) {
      if (!isValid(endDate) || errors.startDate !== undefined) {
        errors.endDate = '';
      } else if (endDate < startDate) {
        errors.endDate = 'End date cannot be before start date';
      }
    }
    if (!isValid(startTime)) {
      errors.startTime = '';
    } else if (!(position.startDateTime <= startTime && startTime <= position.endDateTime)) {
      errors.startTime = 'Shift start time must be between position times';
    }
    if (!isValid(endTime) || errors.startTime !== undefined) {
      errors.endTime = '';
    } else if (!(startTime < endTime && endTime <= position.endDateTime)) {
      errors.endTime = 'Shift end time must be between position times and after shift start time';
    }
    if (!selectedTeamOption) {
      errors.team = '';
    }
    if (selectedPayCodeOptions.length === 0) {
      errors.payCodes = '';
    }
    return errors;
  })();

  const canSave = Object.keys(errors).length === 0;

  const onCancel = () => !isSaving && props.setSelectedPersonPopoverAnchorEl(null);

  const save = (isConfirmed: boolean) => {
    const selectedTeam = departmentInfo.teams.find((t) => t.name === selectedTeamOption?.value);
    const selectedPayCodeValues = new Set(selectedPayCodeOptions.map((t) => t.value));
    const selectedPayCodes = departmentInfo.payCodes.filter((pc) => selectedPayCodeValues.has(pc.code));
    const selectedDetailCodeValues = new Set(selectedDetailCodeOptions.map((t) => t.value));
    const selectedDetailCodes = departmentInfo.detailCodes.filter((dc) => selectedDetailCodeValues.has(dc.code));
    const payload = {
      action: 'UPSERT_ASSIGNMENT',
      battalionId: battalion.id,
      isConfirmed,
      assignmentId: isEditAction ? assignment.id : undefined,
      employeeId: employee.id,
      positionId: position.id,
      activationDate: format(startDate, 'yyyy-MM-dd'),
      deactivationDate: endDate ? format(endDate, 'yyyy-MM-dd') : null,
      startTime: differenceInUTCMinutes(startTime, shiftDuration.startTime),
      endTime: differenceInUTCMinutes(endTime, shiftDuration.startTime),
      shiftTeamId: selectedTeam?.id,
      payCodeIds: selectedPayCodes.map((pc) => pc.id),
      detailCodeIds: selectedDetailCodes.map((dc) => dc.id),
    };

    setIsConfirming(isConfirmed);
    saveDraft(
      payload,
      (response) => {
        if (response.data.employeeAssignments) {
          props.setCandidateAssignments(employee.id, initializeAssignments(departmentInfo, response.data.employeeAssignments));
        }
        setIsConflictingAssignmentsDialogOpen(false);
        props.setSelectedPersonPopoverAnchorEl(null);
      },
      (error) => {
        setIsConfirming(false);
        if (isAxiosError(error) && error.response && error.response.data.error === 'CONFLICTING_ASSIGNMENTS') {
          setConflictingAssignments(initializeAssignments(departmentInfo, error.response.data.employeeAssignments));
          setIsConflictingAssignmentsDialogOpen(true);
          return;
        }

        return error;
      },
    );
  };

  const onSave = () => save(false);

  const onCancelSave = () => {
    !isConfirming && setIsConflictingAssignmentsDialogOpen(false);
    !isConfirming && setIsSaving(false);
  };

  const onConfirmSave = () => save(true);

  return (
    <Box sx={{ px: 2, width: '376px', maxHeight: '670px' }}>
      <Box sx={{ pt: 2, typography: 'heading6' }}>{isEditAction ? 'Edit assignment' : 'New assignment'}</Box>
      <Box sx={{ color: theme.palette.text.secondary, typography: 'subtitle1' }}>
        {`${station.stationName}, ${apparatus.name}`}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pt: 1.5 }}>
        <RankBadge rank={employee.rank} />
        <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(employee.name)}</Box>
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <InputLabel>Staffing pattern</InputLabel>
        <AdminAutocomplete
          multiple={false}
          placeholder="Choose staffing pattern"
          options={teamOptions}
          value={selectedTeamOption}
          onChange={(_event, value) => setSelectedTeamOption(value)}
          getChipSx={(option) => ({
            ...getTeamColors({ theme, color: option.color }),
            '& .MuiChip-deleteIcon': {
              color: theme.palette.common.white,
              '&:hover': { color: theme.palette.stationGray[100] },
            },
          })}
          renderOptionValue={(option) => {
            return <TeamBadge team={{ name: option.value, color: (option.color || 'gray') as AllowedColors }} />;
          }}
        />
        {errors.team && <FormHelperText error={true}>{errors.team}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel>Shift start</InputLabel>
            <SimpleTimePicker
              value={startTime}
              setValue={(value) => setStartTime(getShiftTime('start', value))}
              sxProps={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column', flex: 1 }}>
            <InputLabel>Shift end</InputLabel>
            <SimpleTimePicker
              value={endTime}
              setValue={(value) => setEndTime(getShiftTime('end', value))}
              sxProps={{ width: '100%' }}
            />
          </Box>
        </Box>
        {errors.startTime && <FormHelperText error={true}>{errors.startTime}</FormHelperText>}
        {errors.endTime && <FormHelperText error={true}>{errors.endTime}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <InputLabel>Assignment start</InputLabel>
        <DatePicker
          minDate={today}
          disabled={isEditAction && assignment.activationDate < today}
          value={startDate}
          onChange={(value) => setStartDate(value || NULL_DATE)}
          sx={{ width: '100%' }}
          slotProps={{ textField: { placeholder: 'Choose start' } }}
        />
        {errors.startDate && <FormHelperText error={true}>{errors.startDate}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <InputLabel>Assignment end (optional)</InputLabel>
        <DatePicker
          minDate={isValid(startDate) && startDate > today ? startDate : today}
          value={endDate}
          onChange={(value) => setEndDate(value)}
          sx={{ width: '100%' }}
          slotProps={{ textField: { placeholder: 'Choose end' } }}
        />
        {errors.endDate && <FormHelperText error={true}>{errors.endDate}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <InputLabel>Pay code/s</InputLabel>
        <AdminAutocomplete
          multiple={true}
          placeholder="Choose pay code/s"
          options={payCodeOptions}
          value={selectedPayCodeOptions}
          onChange={(_event, value) => setSelectedPayCodeOptions(value)}
        />
        {errors.payCodes && <FormHelperText error={true}>{errors.payCodes}</FormHelperText>}
      </Box>
      <Box sx={{ pt: 1.25 }}>
        <InputLabel>Detail code/s (optional)</InputLabel>
        <AdminAutocomplete
          multiple={true}
          placeholder="Choose detail code/s"
          options={detailCodeOptions}
          value={selectedDetailCodeOptions}
          onChange={(_event, value) => setSelectedDetailCodeOptions(value)}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pt: 3, pb: 2 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!canSave} loading={isSaving} onClick={onSave}>
          Confirm
        </Button>
      </Box>
      <Dialog open={isConflictingAssignmentsDialogOpen} onClose={onCancelSave}>
        <ShiftPlanDialogXButton onClick={onCancelSave} />
        <UpsertAssignmentConflictsMessage
          startDate={startDate}
          endDate={endDate}
          conflictingAssignments={conflictingAssignments}
        />
        <ShiftPlanDialogActions isSaveLoading={isConfirming} saveText="Confirm" onCancel={onCancelSave} onSave={onConfirmSave} />
      </Dialog>
    </Box>
  );
};
