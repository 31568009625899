import { Box, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Button, ResponseBadgeApproved20, ResponseBadgeRejected20 } from '@stationwise/component-module';
import { RequestVolunteersDetailsView } from '@stationwise/share-types';

interface RequestVolunteersHistoryCardProps {
  requestVolunteers: RequestVolunteersDetailsView | null;
  index: number;
  setSelectedRequestVolunteers?: (requestVolunteers: RequestVolunteersDetailsView | null) => void;
  setIsRequestVolunteersDetailsModalOpen?: (isOpen: boolean) => void;
  setSelectedRequestVolunteersIndex?: (index: number) => void;
}
export const RequestVolunteersHistoryCard = ({
  requestVolunteers,
  index,
  setSelectedRequestVolunteers,
  setIsRequestVolunteersDetailsModalOpen,
  setSelectedRequestVolunteersIndex,
}: RequestVolunteersHistoryCardProps) => {
  const volunteeredEmployeeCount =
    requestVolunteers?.bulkCallHistory.filter((history) => history.signUpTime !== null).length ?? 0;
  const notifiedEmployeeCount = requestVolunteers?.bulkCallHistory.length ?? 0;
  const notificationOpenedEmployeeCount = requestVolunteers?.bulkCallHistory.filter(
    (history) => history.timeSeen !== null && history.signUpTime === null,
  ).length;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        p: theme.spacing(2, 0),
      })}
    >
      <Typography
        variant="bodySRegular"
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
        })}
      >
        Request {index}
      </Typography>
      <Typography
        variant="bodyMSemibold"
        sx={(theme) => ({
          color: theme.palette.stationGray[800],
        })}
      >
        {notifiedEmployeeCount} People{' '}
        <Typography component="span" variant="bodyMRegular" sx={{ display: 'inline' }}>
          on
        </Typography>{' '}
        {requestVolunteers?.createdAt ? format(parseISO(requestVolunteers.createdAt), 'MMM dd, HH:mm aa') : 'N/A'}
      </Typography>

      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgeApproved20 />
          <Typography variant="bodySRegular">{volunteeredEmployeeCount} Volunteered</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgeRejected20 />
          <Typography variant="bodySRegular">
            {notificationOpenedEmployeeCount} Notification Opened, {notifiedEmployeeCount} Notified
          </Typography>
        </Box>
      </Box>
      {setSelectedRequestVolunteers && setIsRequestVolunteersDetailsModalOpen && setSelectedRequestVolunteersIndex && (
        <Button
          variant="outlined"
          sx={{ width: '100%' }}
          onClick={() => {
            setSelectedRequestVolunteers(requestVolunteers);
            setIsRequestVolunteersDetailsModalOpen(true);
            setSelectedRequestVolunteersIndex(index);
          }}
        >
          Show details
        </Button>
      )}
    </Box>
  );
};
