import { Box } from '@mui/material';
import { RankBadge, CustomTable, Edit02Icon16, TableProps, CardCertBadges } from '@stationwise/component-module';
import { RankComplete } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface RanksTableProps {
  ranks: RankComplete[];
  handleEditAction: (rank: RankComplete) => void;
}

export const RanksTable = ({ ranks, handleEditAction }: RanksTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'canActCertification',
      label: '"ACTING" CERTIFICATION',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'isOfficer',
      label: 'IS OFFICER?',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'payPeriodType',
      label: 'PAY PERIOD TYPE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'defaultCertifications',
      label: 'DEFAULT CERTIFICATIONS',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 100,
      align: 'left',
    },
  ];

  const data: TableProps['data'] = ranks.map((rank) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{rank.name}</Box>,
    code: <RankBadge rank={rank} />,
    canActCertification: rank.canActCertification ? (
      <CardCertBadges certs={[rank.canActCertification]} />
    ) : (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>-</Box>
    ),
    isOfficer: rank.isOfficer ? 'Yes' : 'No',
    payPeriodType: (
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          textTransform: 'capitalize',
          typography: 'bodySRegular',
        })}
      >
        {rank.payPeriodType.toLowerCase()}
      </Box>
    ),
    defaultCertifications: (
      <Box display="flex" gap={0.5} flexWrap="wrap">
        {rank.defaultCertifications?.length ? (
          <CardCertBadges certs={rank.defaultCertifications} />
        ) : (
          <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>-</Box>
        )}
      </Box>
    ),
    actions: (
      <Box>
        <Box
          data-cy={`edit-rank-${makeTestIdentifier(rank.name)}`}
          onClick={() => handleEditAction(rank)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Rank"
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
