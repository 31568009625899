import { Box } from '@mui/material';
import { Badge, CustomTable, Edit02Icon16, TableProps } from '@stationwise/component-module';
import { PayCode, PayCodeComplete } from '@stationwise/share-types';
import { makeTestIdentifier } from '../../../../../libs/share-utils/src/lib/string';

interface PayCodeTableProps {
  existingPayCodes: PayCode[];
  payCodes: PayCodeComplete[];
  handleEditAction: (payCode: PayCodeComplete) => void;
}

export const PayCodeTable = ({ payCodes, handleEditAction, existingPayCodes }: PayCodeTableProps) => {
  const updatedPayCodes = payCodes.map((payCode: PayCodeComplete) => {
    if (!payCode.bankedHours) {
      return payCode;
    }

    return {
      ...payCode,
      bankedHours: {
        ...payCode.bankedHours,
        spendablePayCodeObject: payCode.bankedHours.spendablePayCode
          ? existingPayCodes.find((pc) => pc.id === payCode.bankedHours?.spendablePayCode)
          : null,
        convertibleToObject: payCode.bankedHours.convertibleTo
          ? existingPayCodes.find((pc) => pc.id === payCode.bankedHours?.convertibleTo)
          : null,
      },
    };
  });
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'payCodeType',
      label: 'PAY CODE TYPE',
      minWidth: 150,
      align: 'left',
    },
    {
      id: 'convertibleTo',
      label: 'CONVERTIBLE TO',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'extCode',
      label: 'EXT CODE',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'spendablePayCode',
      label: 'SPENDABLE PAY CODE',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'spendRatio',
      label: 'SPEND RATIO',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'convertRatio',
      label: 'CONVERT RATIO',
      minWidth: 100,
      align: 'left',
    },
    /* @NOTE: Hiding since possible confusion by users. */

    // {
    //   id: 'defaultAccrualAmount',
    //   label: 'DEFAULT ACCRUAL AMOUNT',
    //   minWidth: 100,
    //   align: 'center',
    // },
    // {
    //   id: 'maxAccrualLimit',
    //   label: 'MAX ACCRUAL LIMIT',
    //   minWidth: 100,
    //   align: 'center',
    // },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 50,
      align: 'center',
    },
  ];
  const data: TableProps['data'] = updatedPayCodes.map((payCode: PayCodeComplete) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{payCode.name}</Box>,
    code: (
      <Box display="inline-flex">
        <Badge label={payCode.code} isRound type="sm" />
      </Box>
    ),
    payCodeType: (
      <Box display="inline-flex">
        <Badge label={payCode.payCodeType} isRound type="sm" />
      </Box>
    ),

    convertibleTo: (
      <Box display="inline-flex">
        {payCode.bankedHours?.convertibleToObject ? (
          <Badge label={payCode.bankedHours?.convertibleToObject.code} isRound type="sm" />
        ) : (
          '-'
        )}
      </Box>
    ),

    extCode: <Box display="inline-flex">{payCode.extCode ? <Badge label={payCode.extCode} isRound type="sm" /> : '-'}</Box>,

    spendablePayCode: (
      <Box display="inline-flex">
        {payCode.bankedHours?.spendablePayCodeObject ? (
          <Badge label={payCode.bankedHours?.spendablePayCodeObject.code} isRound type="sm" />
        ) : (
          '-'
        )}
      </Box>
    ),
    spendRatio: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {payCode.bankedHours?.spendRatio && payCode.bankedHours?.spendablePayCode ? payCode.bankedHours?.spendRatio : '-'}
      </Box>
    ),
    convertRatio: (
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[900],
          typography: 'bodySRegular',
        })}
      >
        {payCode.bankedHours?.convertRatio && payCode.bankedHours?.convertibleTo ? payCode.bankedHours?.convertRatio : '-'}
      </Box>
    ),
    defaultAccrualAmount: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {payCode.timeOffAccrual?.isDefaultUnlimited ? 'INFINITE' : payCode.timeOffAccrual?.defaultAccrualAmount ?? '-'}
      </Box>
    ),
    maxAccrualLimit: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {payCode.timeOffAccrual?.isMaxUnlimited ? 'INFINITE' : payCode.timeOffAccrual?.maxAccrualLimit ?? '-'}
      </Box>
    ),

    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(payCode)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Pay Code"
          data-cy={`edit-pay-code-${makeTestIdentifier(payCode.name)}`}
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
