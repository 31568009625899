import { Box } from '@mui/material';
import React from 'react';
import { CustomTable, Edit02Icon16, TableProps, theme, XCloseIcon20 } from '@stationwise/component-module';
import { PayCode, TimeOffAccruals } from '@stationwise/share-types';

interface TimeOffAccrualsTableProps {
  existingPayCodes: PayCode[];
  accruals: TimeOffAccruals[];
  handleEditAction: (accrual: TimeOffAccruals) => void;
  handleDeleteAction: (accrual: TimeOffAccruals) => void;
}

export const TimeOffAccrualsTable = ({ accruals, handleEditAction, handleDeleteAction }: TimeOffAccrualsTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'payCodes',
      label: 'PAY CODES',
      minWidth: 100,
      align: 'center',
      tooltip: 'Pay codes that accrual bank belongs to',
    },
    {
      id: 'defaultAccrualAmount',
      label: 'DEFAULT ACCRUAL AMOUNT',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'maxAccrualLimit',
      label: 'MAX ACCRUAL LIMIT',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'actions',
      label: 'EDIT',
      minWidth: 50,
      align: 'center',
    },
    {
      id: 'delete',
      label: 'DELETE',
      minWidth: 50,
      align: 'center',
    },
  ];
  const data: TableProps['data'] = accruals.map((accrual: TimeOffAccruals) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{accrual.name}</Box>,
    payCodes:
      accrual.payCodes && accrual.payCodes.length > 0 ? (
        accrual.payCodes.map((payCode) => (
          <Box
            key={payCode.id}
            sx={[
              {
                backgroundColor: theme.palette.stationOrange[200],
                color: theme.palette.stationPurple[500],
                borderRadius: '10px',
                display: 'inline-flex',
                padding: '2px 6px',
                typography: 'tagline1',
                textWrap: 'nowrap',
              },
            ]}
          >
            {payCode.code.slice(0, 12)}
          </Box>
        ))
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>-</Box>
      ),
    defaultAccrualAmount: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {accrual.isDefaultUnlimited ? 'INFINITE' : accrual.defaultAccrualAmount ?? '-'}
      </Box>
    ),
    maxAccrualLimit: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {accrual.isMaxUnlimited ? 'INFINITE' : accrual.maxAccrualLimit ?? '-'}
      </Box>
    ),

    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(accrual)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit accrual bank"
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
    delete: (
      <Box>
        <Box
          onClick={() => handleDeleteAction(accrual)}
          sx={(theme) => ({
            color: theme.palette.stationRed[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationPurple[800],
            },
          })}
          title="Delete Bank"
          data-cy={`delete-bank-`}
        >
          <XCloseIcon20 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
