import { Alert, Box, InputAdornment, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, parse, set } from 'date-fns';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  GenericDrawerOrModal,
  useLoadedDepartmentInfoContext,
  SimpleTimePicker,
} from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { DynamicFieldValue, DynamicFieldsData, GetIncidentOverview, IncidentApparatus } from '@stationwise/share-types';
import { CustomIncidentFields } from '../Incident/CustomIncidentFields';
import { StaffingDetailsDrawer } from './StaffingDetailsDrawer';

const combineDateAndTime = (date: string, time: Date) => {
  const parsedDate = parse(date, 'MM/dd/yy', new Date());
  return set(parsedDate, {
    hours: time.getHours(),
    minutes: time.getMinutes(),
  });
};

interface IncidentFormDrawerProps {
  handleIncidentRequest: (responseMessage: string, error: boolean) => void;
  onClose: () => void;
  open: boolean;
  selectedDate: string;
  selectedIncident?: GetIncidentOverview;
  setSelectedIncident: (incident: GetIncidentOverview) => void;
  isCloseIncident: boolean;
  setIsCloseIncident: React.Dispatch<React.SetStateAction<boolean>>;
}

export const IncidentFormDrawer = ({
  handleIncidentRequest,
  open,
  onClose,
  selectedDate,
  selectedIncident,
  setSelectedIncident,
  isCloseIncident,
  setIsCloseIncident,
}: IncidentFormDrawerProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const incidentNumberPrefix = departmentInfoState.departmentInfo.settings.incidentNumberPrefix;
  const [note, setNote] = useState<string>(selectedIncident?.comments || '');
  const [incidentApparatuses, setIncidentApparatuses] = useState<IncidentApparatus[]>([]);
  const [startTime, setStartTime] = useState<Date>(
    selectedIncident?.startDatetime ? new Date(selectedIncident?.startDatetime) : new Date(),
  );
  const [endTime, setEndTime] = useState<Date | null>(null);
  const isActive = selectedIncident ? selectedIncident.isActive : true;
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;
    const fetchIncidentApparatus = async () => {
      if (!selectedIncident) return;
      try {
        const response = await client.get(`incident/incidents/${selectedIncident.id}/`);
        if (response.data && isMounted) {
          setIncidentApparatuses(response.data['incidentApparatuses']);
        }
      } catch (error) {
        captureException(error);
        if (isMounted) {
          setError('Failed to load incident apparatus data.');
        }
      }
    };
    fetchIncidentApparatus();
    return () => {
      isMounted = false;
    };
  }, [selectedIncident]);
  const latestTimeAvailable = useMemo(() => {
    if (incidentApparatuses.length > 0) {
      return incidentApparatuses.reduce((latest, apparatus) => {
        const timeAvailable = apparatus.endDatetime ? new Date(apparatus.endDatetime) : null;
        return timeAvailable && timeAvailable > latest ? timeAvailable : latest;
      }, new Date(0));
    }
    return null;
  }, [incidentApparatuses]);

  useEffect(() => {
    setEndTime(latestTimeAvailable);
  }, [latestTimeAvailable]);

  const startDate = useMemo(() => {
    return selectedIncident?.startDatetime
      ? new Date(selectedIncident.startDatetime)
      : parse(selectedDate, 'MM/dd/yy', new Date());
  }, [selectedIncident?.startDatetime, selectedDate]);

  const [incidentNumber, setIncidentNumber] = useState<string>(selectedIncident ? selectedIncident.incidentNumber : '');
  const [incidentExists, setIncidentExists] = useState<boolean>(false);

  const checkIncidentNumber = useMemo(
    () =>
      debounce(async (number: string) => {
        if (!number) {
          setIncidentExists(false);
          return;
        }

        try {
          const response = await client.get(`incident/incidents/check_incident_number/`, {
            params: { incidentNumber: incidentNumberPrefix ? incidentNumberPrefix + number : number },
          });
          setIncidentExists(response.data.exists);
        } catch (error) {
          captureException(error);
        }
      }, 1000),
    [incidentNumberPrefix, setIncidentExists],
  );

  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const fields = departmentContext.departmentInfo.customIncidentFields;
  const initialDynamicFields = useMemo(() => {
    return fields.reduce((acc, field) => {
      const existingValue = selectedIncident?.customData?.[field.name];
      if (field.fieldType === 'BOOLEAN') {
        acc[field.name] = existingValue || false;
      } else {
        acc[field.name] = existingValue || '';
      }
      return acc;
    }, {} as DynamicFieldsData);
  }, [fields, selectedIncident]);

  const [dynamicFieldsData, setDynamicFieldsData] = useState<DynamicFieldsData>(initialDynamicFields);
  const handleDynamicFieldChange = useCallback((name: string, value: DynamicFieldValue) => {
    setDynamicFieldsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const isFormValid = (): boolean => {
    if (!incidentNumber || incidentExists) return false;
    return fields.every(({ required, name }) => !required || Boolean(dynamicFieldsData[name]));
  };

  const disabled = !isFormValid() || (incidentExists && selectedIncident === undefined);

  const [staffingDetailsDrawer, setStaffingDetailsDrawer] = useState<boolean>(false);

  const fetchUrl = `/incident/incidents/${selectedIncident?.id}/`;
  const [isClosing, setIsClosing] = useState(false);
  const handleCloseIncident = useCallback(async () => {
    if (isClosing) return;
    let saveError = null;
    setIsClosing(true);
    try {
      const response = await client.patch<GetIncidentOverview>(fetchUrl, { isActive: false });
      setSelectedIncident(response.data);
      onClose();
    } catch (error) {
      saveError = axios.isAxiosError(error)
        ? error.response?.data?.message || error.message
        : 'An unexpected error occurred while closing the incident.';
      captureException(error);
    } finally {
      handleIncidentRequest(saveError ?? 'You have successfully closed the Incident.', !!saveError);
      setIsClosing(false);
      setIsCloseIncident(false);
    }
  }, [fetchUrl, setSelectedIncident, onClose, isClosing, handleIncidentRequest, setIsCloseIncident]);

  const handleSave = async () => {
    let error = false;
    let responseMessage = '';
    const data = {
      start_datetime: startDate,
      end_datetime: endTime,
      comments: note,
      custom_data: dynamicFieldsData,
      incident_number: incidentNumber,
      incident_apparatuses: incidentApparatuses,
      isActive: isActive,
    };

    if (!selectedIncident) {
      // HANDLE CREATE
      data.start_datetime = combineDateAndTime(selectedDate, startTime);
      try {
        await client.post(
          'incident/incidents/',
          incidentNumberPrefix
            ? {
                ...data,
                incident_number: incidentNumberPrefix + incidentNumber,
              }
            : data,
        );
        responseMessage = 'You have successfully created an Incident.';
      } catch {
        error = true;
        responseMessage = 'There was an error when creating your Incident. Please try again later.';
      }
    } else {
      // HANDLE UPDATE
      data.start_datetime = combineDateAndTime(format(startDate, 'MM/dd/yy'), startTime);
      try {
        await client.patch(`incident/incidents/${selectedIncident.id}/`, data);
        responseMessage = 'You have successfully updated the Incident.';
      } catch {
        error = true;
        responseMessage = 'There was an error when updating your Incident. Please try again later.';
      }
    }
    handleIncidentRequest(responseMessage, error);
  };

  const errorBanner = !!error && (
    <Alert severity="error" sx={(theme) => ({ mt: -1, mb: 2, backgroundColor: theme.palette.stationRed[500] })}>
      {error}
    </Alert>
  );

  const handleIncidentNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setIncidentNumber(newValue);
    checkIncidentNumber(newValue);
  };

  return (
    <Box>
      <GenericDrawerOrModal
        anchor="bottom"
        drawerOpen={open}
        handleOnClose={onClose}
        loading={false}
        showHeader
        headerTitle="Incident Form"
        disableFooter
        noBorderOnHeader={false}
        sxProps={{
          '.MuiDrawer-paper': {
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            px: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          {isCloseIncident && (
            <Alert severity="warning" sx={(theme) => ({ my: theme.spacing(1) })}>
              Please review the details and proceed to Save and Close this Incident
            </Alert>
          )}
          <Box>
            {errorBanner}
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyLMedium',
                mt: theme.spacing(1),
              })}
            >
              Start date & time
            </Box>

            <Box
              sx={(theme) => ({
                width: '100%',
                mt: theme.spacing(1),
              })}
            >
              <DatePicker
                value={startDate}
                disabled={true}
                sx={{ width: '48%' }}
                slotProps={{ textField: { placeholder: 'Choose date' } }}
              />
              <SimpleTimePicker
                setValue={(value: Date | null) => value && setStartTime(value)}
                sxProps={{ width: '48%', ml: '4%' }}
                usePreciseTime
                value={startTime}
                disabled={!isActive}
              />
            </Box>
          </Box>

          <Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyLMedium',
                mt: theme.spacing(1),
              })}
            >
              End date & time
            </Box>
            <Box
              sx={(theme) => ({
                width: '100%',
                mt: theme.spacing(1),
              })}
            >
              <DatePicker
                value={endTime}
                disabled={true}
                sx={{ width: '48%' }}
                slotProps={{ textField: { placeholder: 'Choose date' } }}
              />
              <SimpleTimePicker
                setValue={() => null}
                sxProps={{ width: '48%', ml: '4%' }}
                usePreciseTime
                value={endTime}
                disabled={true}
              />
            </Box>
          </Box>
          <Box typography={'bodyLMedium'}>
            <Box>
              <Box
                sx={(theme) => ({
                  mt: theme.spacing(2),
                  typography: 'bodyLMedium',
                  display: 'flex',
                })}
              >
                Incident Number
                <Box sx={(theme) => ({ ml: theme.spacing(1), typography: 'bodyLMedium' })}> (required)</Box>
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  width: '100%',
                  pt: theme.spacing(1),
                })}
              >
                <TextField
                  data-cy="incident-number-field"
                  disabled={!!selectedIncident}
                  defaultValue={incidentNumber ?? ''}
                  required={true}
                  fullWidth
                  slotProps={{
                    input: {
                      startAdornment: !selectedIncident ? (
                        <InputAdornment position="start">{incidentNumberPrefix ?? ''}</InputAdornment>
                      ) : null,
                    },
                  }}
                  onChange={handleIncidentNumberChange}
                />
              </Box>
              {incidentExists && selectedIncident === undefined && (
                <Alert severity="error" sx={(theme) => ({ mt: theme.spacing(2) })}>
                  {'This incident number already exists, please choose another number.'}
                </Alert>
              )}
            </Box>

            {fields.map((field, index) => (
              <CustomIncidentFields
                key={index}
                field={field}
                onChange={(value) => handleDynamicFieldChange(field.name, value)}
                defaultValue={selectedIncident ? dynamicFieldsData[field.name] : ''}
                disabled={!isActive}
              />
            ))}

            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyLMedium',
                mt: theme.spacing(2),
              })}
            >
              Comments
            </Box>
            <TextField
              value={note}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNote(event.target.value);
              }}
              sx={(theme) => ({
                width: '100%',
                mt: theme.spacing(1),
                mb: theme.spacing(2),
              })}
              multiline
              rows={3}
              disabled={!isActive}
            />
          </Box>

          <Box
            sx={(theme) => ({
              width: '100%',
              justifyContent: 'center',
              mb: theme.spacing(2),
            })}
          >
            <Button
              data-cy="next-step-button"
              variant="contained"
              size="large"
              disabled={disabled}
              onClick={() => setStaffingDetailsDrawer(true)}
              sx={{ width: '100%' }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </GenericDrawerOrModal>
      {staffingDetailsDrawer && (
        <StaffingDetailsDrawer
          open={staffingDetailsDrawer}
          onClose={() => setStaffingDetailsDrawer(false)}
          incidentStartDatetime={combineDateAndTime(format(startDate, 'MM/dd/yy'), startTime)}
          handleSave={isCloseIncident ? handleCloseIncident : handleSave}
          incidentApparatuses={incidentApparatuses}
          setIncidentApparatuses={setIncidentApparatuses}
          isActive={isActive}
          isCloseIncident={isCloseIncident}
          customData={dynamicFieldsData}
        />
      )}
    </Box>
  );
};
