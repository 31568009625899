import { DepartmentInfo, RosterPosition, ShiftPlanAssignmentEmployee } from '@stationwise/share-types';
import { getAllCertificationCodes } from '@stationwise/shift-summary-helper';

export const getDisplayedEmployeeCertifications = (
  departmentInfo: DepartmentInfo,
  position: RosterPosition | undefined,
  employee: Pick<ShiftPlanAssignmentEmployee, 'rank' | 'certifications'>,
) => {
  const departmentRank = departmentInfo.ranks.find((r) => r.id === employee.rank.id);
  const defaultCertCodes = new Set(departmentRank?.defaultCertifications.map((c) => c.code) || []);
  const requiredCertCodes = new Set(position?.certifications.map((c) => c.code) || []);
  return employee.certifications.filter((cert) => {
    const isRequired = Array.from(getAllCertificationCodes([cert])).some((code) => requiredCertCodes.has(code));
    const isDefault = defaultCertCodes.has(cert.code);
    return isRequired || !isDefault;
  });
};
