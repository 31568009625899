import { format, max } from 'date-fns';
import { RosterDuration, RosterShiftDuration } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';

export const formatDurationMinutes = (duration: RosterDuration) => {
  let minutes = differenceInUTCMinutes(duration.endDateTime, duration.startDateTime);
  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;
  return isNaN(minutes) ? 'Invalid' : `${`${hours}`.padStart(2, '0')}h ${`${minutes}`.padStart(2, '0')}m`;
};

export const formatShiftTime = (time: Date) => format(time, 'HH:mm');

export const formatShiftDuration = ({ startTime, endTime }: Pick<RosterShiftDuration, 'startTime' | 'endTime'>) => {
  return `${formatShiftTime(startTime)} - ${formatShiftTime(endTime)}`;
};

const getHourMinutes = (time: Date) => time.getHours() * 60 + time.getMinutes();

export const diffCycleMinutes = (endTime: Date | null, startTime: Date | null) => {
  if (!endTime || !startTime) {
    return 0;
  }
  // E.g. diffCycleMinutes(23:00, 07:00) = 16 * 60.
  // E.g. diffCycleMinutes(05:00, 07:00) = 22 * 60. 05:00 means 05:00 the next day.
  const minutes = getHourMinutes(endTime) - getHourMinutes(startTime);
  return (minutes + 24 * 60) % (24 * 60);
};

export const offsetMinutes = (relatedTime: Date | null, anchorTime: Date | null) => {
  if (!relatedTime || !anchorTime) {
    return 0;
  }
  // E.g. offsetMinutes(23:00, 07:00) = 16 * 60.
  // E.g. offsetMinutes(05:00, 07:00) = -2 * 60.
  const minutes = getHourMinutes(relatedTime) - getHourMinutes(anchorTime);
  return minutes % (24 * 60);
};

export const mergeDurations = <D extends RosterDuration>(inputDurations: D[], isMergeable = (_d1: D, _d2: D) => true): D[] => {
  const merged: D[] = [];
  const durations = [...inputDurations].sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime));
  durations.forEach((duration) => {
    const prevDuration = merged[merged.length - 1];
    if (prevDuration && prevDuration.endDateTime >= duration.startDateTime && isMergeable(prevDuration, duration)) {
      prevDuration.endDateTime = max([prevDuration.endDateTime, duration.endDateTime]);
    } else {
      merged.push({ ...duration });
    }
  });
  return merged;
};

export const cutDuration = (duration: RosterDuration, inputCuts: RosterDuration[]) => {
  let cuts = mergeDurations(inputCuts);
  cuts = cuts.filter((d) => d.startDateTime < duration.endDateTime && d.endDateTime > duration.startDateTime);
  if (!cuts.length) {
    return [{ startDateTime: duration.startDateTime, endDateTime: duration.endDateTime }];
  }

  const remains = cuts.map((cut, i) => ({
    startDateTime: i > 0 ? cuts[i - 1].endDateTime : duration.startDateTime,
    endDateTime: cut.startDateTime,
  }));
  remains.push({ startDateTime: cuts[cuts.length - 1].endDateTime, endDateTime: duration.endDateTime });
  return remains.filter((d) => d.endDateTime > d.startDateTime);
};
