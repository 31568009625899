/* eslint-disable no-restricted-imports */
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS } from 'date-fns/locale/en-US';
import { getAutoOpenTextFieldProps, useDateOrTimePicker } from './common';

type DatePickerProps<D extends Date> = MuiDatePickerProps<D>;

export const DatePicker = <D extends Date>({ sx, ...props }: DatePickerProps<D>) => {
  const { isDesktop, open, onOpen, onClose } = useDateOrTimePicker(props);

  return (
    <LocalizationProvider adapterLocale={enUS} dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        format="MMM dd, yyyy"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
        sx={[
          {
            '& .MuiInputBase-adornedStart .MuiInputBase-input': { pl: 0 },
            '& .MuiInputBase-adornedEnd .MuiInputBase-input': { pr: 0 },
          },
          ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
        ]}
        slotProps={{
          ...props.slotProps,
          textField: {
            ...(isDesktop && !props.disabled && getAutoOpenTextFieldProps(open, onOpen, onClose)),
            ...props.slotProps?.textField,
          },
        }}
      />
    </LocalizationProvider>
  );
};
