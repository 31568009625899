import { ButtonBase, ButtonBaseProps, Dialog, DialogProps } from '@mui/material';
import { XCloseIcon24 } from '@stationwise/component-module';
import { ShiftPlanAction } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanCloseDialogResetContextEffect } from './ShiftPlanCloseDialogResetContextEffect';

type ShiftPlanDialogXButtonProps = Omit<ButtonBaseProps<'button'>, 'children'>;

export const ShiftPlanDialogXButton = ({ sx, ...props }: ShiftPlanDialogXButtonProps) => (
  <ButtonBase
    {...props}
    sx={[
      (theme) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        p: 1.5,
        '& svg path': { stroke: theme.palette.stationGray[400] },
      }),
      ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
    ]}
  >
    <XCloseIcon24 />
  </ButtonBase>
);

interface ShiftPlanDialogProps extends Omit<DialogProps, 'open'> {
  action: ShiftPlanAction;
  open?: boolean;
}

export const ShiftPlanDialog = ({ action, children, ...props }: ShiftPlanDialogProps) => {
  const { selectedAction, cancelSelectedAction } = useShiftPlanContext();

  return (
    <Dialog open={action === selectedAction} onClose={cancelSelectedAction} {...props}>
      <ShiftPlanCloseDialogResetContextEffect />
      <ShiftPlanDialogXButton onClick={cancelSelectedAction} />
      {children}
    </Dialog>
  );
};
