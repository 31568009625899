import { Box, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { GenericDrawerOrModal, SearchInput } from '@stationwise/component-module';
import { PayCode, TimeOffLimit } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { getTimeOffIcon } from './TimeOffLimitSelect';

interface TimeOffTypeSelectorProps {
  handleOnClose: () => void;
  drawerOpen: boolean;
  options: TimeOffLimit[];
  setSelectedTimeOffLimit: (timeOffLimit: TimeOffLimit) => void;
  isAccrualsEnabled: boolean;
  payCodeOptions: PayCode[];
  setSelectedPayCode: Dispatch<SetStateAction<PayCode | undefined>>;
}

export const TimeOffTypeSelector = ({
  handleOnClose,
  drawerOpen,
  options,
  setSelectedTimeOffLimit,
  isAccrualsEnabled,
  payCodeOptions,
  setSelectedPayCode,
}: TimeOffTypeSelectorProps) => {
  const theme = useTheme();
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const [searchText, setSearchText] = useState('');
  const filteredOptions = payCodeOptions.filter(
    (option) =>
      option.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.code.toLowerCase().includes(searchText.toLowerCase()),
  );
  const onClose = () => {
    setSearchText('');
    handleOnClose();
  };
  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={onClose}
      loading={false}
      showHeader
      headerTitle="Time off type"
      disableFooter
      noBorderOnHeader={true}
      paddingBottom={iosWebViewPaddingBottom}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            pb: 0,
          })}
        >
          <SearchInput value={searchText} setValue={setSearchText} color={'white'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <Box
                data-cy={`time-off-type-${makeTestIdentifier(option.name)}`}
                key={option.code}
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                  p: theme.spacing(2),
                })}
                onClick={() => {
                  setSelectedTimeOffLimit(options.find((opt) => opt.payCodes.includes(option))!);
                  setSelectedPayCode(option);
                  onClose();
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.stationGray[400],
                      borderRadius: '32px',
                      p: theme.spacing(1),
                    })}
                  >
                    {getTimeOffIcon(options.find((opt) => opt.payCodes.includes(option))!, theme)}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      ml: theme.spacing(1.5),
                      typography: 'bodyLRegular',
                    })}
                  >
                    {option.name}
                  </Box>
                </Box>
                {isAccrualsEnabled && (
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                    {options.find((opt) => opt.payCodes.includes(option))!.isUnlimited
                      ? 'Unlimited'
                      : `${(options.find((opt) => opt.payCodes.includes(option))!.accruedTimeOff - options.find((opt) => opt.payCodes.includes(option))!.pending).toFixed(2)} hrs`}
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              sx={(theme) => ({
                height: theme.spacing(5),
                p: '10px 13px 10px',
                width: '100%',
                justifyContent: 'space-between',
                typography: 'bodyLRegular',
                color: theme.palette.stationGray[500],
              })}
            >
              No results found
            </Box>
          )}
        </Box>
      </Box>
    </GenericDrawerOrModal>
  );
};
