import { ReactComponent as InfoOutlinedSymbol } from '@material-symbols/svg-400/outlined/info.svg';
import { Box, Tooltip, IconButton } from '@mui/material';
import { RankBadge, CustomTable, Edit02Icon16, TableProps, SvgIcon } from '@stationwise/component-module';
import { CapabilityPermission } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface CapabilityPermissionTableProps {
  capabilityPermissions: CapabilityPermission[];
  handleEditAction: (capabilityPermission: CapabilityPermission) => void;
}

export const CapabilityPermissionTable = ({ capabilityPermissions, handleEditAction }: CapabilityPermissionTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'info',
      label: '',
      minWidth: 25,
      align: 'center',
    },
    {
      id: 'name',
      label: 'CAPABILITY',
      minWidth: 200,
      align: 'left',
    },
    {
      id: 'ranks',
      label: 'RANKS',
      minWidth: 150,
      align: 'left',
    },
    {
      id: 'employees',
      label: 'EMPLOYEES',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 50,
      align: 'center',
    },
  ];

  const data: TableProps['data'] = capabilityPermissions.map((capabilityPermission: CapabilityPermission) => ({
    info: (
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 25 }}>
        <Tooltip
          title={capabilityPermission.capabilityDescription}
          placement="top"
          arrow
          slotProps={{
            tooltip: {
              sx: {
                typography: 'bodyMRegular',
                bgcolor: 'rgba(0, 0, 0, 0.8)',
                padding: '8px 12px',
                maxWidth: '300px',
              },
            },
            arrow: {
              sx: {
                color: 'rgba(0, 0, 0, 0.8)',
              },
            },
          }}
        >
          <IconButton
            size="small"
            sx={{
              padding: 0.5,
              color: 'text.secondary',
            }}
          >
            <SvgIcon component={InfoOutlinedSymbol} sx={{ fontSize: 24 }} />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    name: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            typography: 'bodyLRegular',
          })}
        >
          {capabilityPermission.name}
        </Box>
      </Box>
    ),
    ranks: (
      <Box display="flex" gap={1} flexWrap="wrap">
        {capabilityPermission.ranks.length > 0
          ? capabilityPermission.ranks.map((rank) => <RankBadge key={rank.id} rank={rank} />)
          : '-'}
      </Box>
    ),
    employees: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
        {capabilityPermission.employees.length > 0 ? (
          <>
            {capabilityPermission.employees
              .slice(0, 3)
              .map((employee) => employee.name)
              .join(', ')}
            {capabilityPermission.employees.length > 3 && (
              <Tooltip
                title={
                  <Box sx={{ p: 1 }}>
                    {capabilityPermission.employees.slice(3).map((emp) => (
                      <Box
                        key={emp.id}
                        sx={{
                          py: 0.5,
                          '&:not(:last-child)': {
                            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                          },
                        }}
                      >
                        {emp.name}
                      </Box>
                    ))}
                  </Box>
                }
                arrow
                placement="top"
                slotProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'rgba(0, 0, 0, 0.8)',
                      '& .MuiTooltip-arrow': {
                        color: 'rgba(0, 0, 0, 0.8)',
                      },
                    },
                  },
                }}
              >
                <Box component="span" sx={{ color: 'text.secondary', cursor: 'help' }}>
                  {' '}
                  + {capabilityPermission.employees.length - 3} more
                </Box>
              </Tooltip>
            )}
          </>
        ) : (
          '-'
        )}
      </Box>
    ),
    actions: (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          data-cy={`edit-permission-${makeTestIdentifier(capabilityPermission.name)}`}
          onClick={() => handleEditAction(capabilityPermission)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Permission"
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));

  return <CustomTable columns={columns} data={data} />;
};
