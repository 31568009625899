import { Certification } from '../certification/models';
import { EmployeeMaybeConsecutiveWorkDuration } from '../employee/models';
import { Rank, Team, BasicFieldsBattalion, Station } from '../organization/models';
import { DetailCode, PayCode } from '../payroll/models';
import { ShiftSummaryEmployeeSplit } from '../shift/getShiftSummary';

export interface StaffingListItemEmployee {
  id: string;
  name: string;
  rank: Rank;
  certifications: Certification[];
  team: Team | null;
  battalion: BasicFieldsBattalion | null;
  station: Station;
  defaults: { regularAssignmentPayCode: Pick<PayCode, 'id'>; overtimeAssignmentPayCode: Pick<PayCode, 'id'> };
  detailCodes: DetailCode[];
  payCodes: PayCode[];
  splits: ShiftSummaryEmployeeSplit[];
  maybeConsecutiveWorkDurations: EmployeeMaybeConsecutiveWorkDuration[];
  status: string;
  timePreference?: string;
}

export enum StaffingListEmployeeStatus {
  AVAILABLE = 'AVAILABLE',
  ONLY_PARTIAL_SHIFTS = 'ONLY_PARTIAL_SHIFTS',
  ONLY_FULL_SHIFTS = 'ONLY_FULL_SHIFTS',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface StaffingListRelevantField {
  label: string;
  value: string;
  key: string;
}

export interface StaffingListItemLastOvertime {
  date?: string | null;
  hours?: number | null;
  staffedAt?: string | null;
}

export interface StaffingListItemAttributes {
  lastThreeOvertimes?: StaffingListItemLastOvertime[] | null;
  overtimeAccHours?: number | null;
  totalOvertimeMinutes?: number | null;
  availableForAllStations?: boolean | null;
  opportunityNumber?: number | null;
}

export interface StaffingListItem {
  order: number;
  employee: StaffingListItemEmployee;
  attributes: StaffingListItemAttributes;
  relevantFields: StaffingListRelevantField[];
}

export interface OvertimeIntervalStart {
  ago?: boolean;
  day: number;
  month?: number;
}

export interface SignUpableShiftDatesConfiguration {
  allowRegularShiftDates?: boolean;
  allowWithOtherStaffingLists?: boolean;
  allowSignupAfterCancel?: boolean;
}

export interface StaffingListConfigParams {
  overtimeAccHoursThreshold?: number;
  overtimeMinHoursThreshold?: number;
  shiftDateChoice?: 'NEXT' | 'PREVIOUS';
  overtimeIntervalStarts?: OvertimeIntervalStart[];
}

export interface DetailFieldsStaffingList {
  id: number;
  name: string;
  items: StaffingListItem[];
  isSignUp?: boolean;
  isOptOut?: boolean;
  filters?: string[];
  sorts?: string[];
  params?: StaffingListConfigParams;
  staffingListToIncludeIds?: number[];
  staffingListToExcludeIds?: number[];
  signUpableShiftDatesConfiguration?: SignUpableShiftDatesConfiguration;
  allowsTimePreference?: boolean;
}

export interface ListFieldsStaffingList extends Omit<DetailFieldsStaffingList, 'items'> {
  items?: StaffingListItem[];
}

export interface StaffingListExemption {
  id: number;
  employeeNames: string;
  employeeIds: number[];
  startDate: string;
  endDate: string;
  staffingListId: number;
}
