import { SxProps, Theme, TextField, Tooltip } from '@mui/material';
import { theme } from '@stationwise/component-module';

type AutoHireIntervalPickerProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  title?: string;
};

export const AutoHireIntervalPicker = ({ value, onChange, disabled, sx, title }: AutoHireIntervalPickerProps) => {
  return (
    <Tooltip
      title={title || ''}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '14px',
            padding: '8px 12px',
            maxWidth: '200px',
          },
        },
      }}
    >
      <TextField
        label="Round Duration"
        value={value}
        type="number"
        onChange={(e) => {
          const newValue = e.target.value;
          const numValue = parseInt(newValue);
          if (numValue > 120 || numValue < 1) {
            return;
          }
          onChange?.(newValue);
        }}
        disabled={disabled}
        placeholder="min"
        helperText="Max 120 minutes"
        inputProps={{
          min: 1,
          max: 120,
          maxLength: 3,
          pattern: '[0-9]*',
        }}
        sx={[
          {
            width: 120,
            '& .MuiFormHelperText-root': {
              marginTop: theme.spacing(1),
              width: '100%',
            },
          },
          ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
        ]}
      />
    </Tooltip>
  );
};
