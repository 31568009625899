import { Box, IconButton, Popover, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useId, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CheckIcon20,
  ChevronRightIcon20,
  LetterAvatar,
  XCloseIcon24,
  useAuthUserCapabilities,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  useLogout,
  useSwitchDepartmentMenuItems,
} from '@stationwise/component-module';

export const ProfileMenu = () => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const id = useId();
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const logout = useLogout();
  const switchDepartmentMenuItems = useSwitchDepartmentMenuItems();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isSwitchDepartmentOpen, setIsSwitchDepartmentOpen] = useState(false);
  const capabilities = useAuthUserCapabilities();
  const canEditEmployeeProfile = capabilities.EDIT_EMPLOYEE_PROFILE;

  const splitDepartmentName = (name: string) => {
    const [firstName, ...lastNames] = name.split(' ');
    return { firstName, lastName: lastNames.join(' ') };
  };

  const currentDepartmentNames = splitDepartmentName(departmentInfoState.departmentInfo.name);

  return (
    <>
      <Box
        data-cy="profile-menu"
        component="button"
        id={`${id}button`}
        aria-controls={anchorEl ? `${id}menu` : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{
          background: theme.palette.stationGray[100],
          border: `1px solid ${theme.palette.stationGray[200]}`,
          borderRadius: '9999px',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: { md: 'column', xs: 'row' },
          alignItems: 'center',
          gap: 1,
          p: 1,
        }}
      >
        <LetterAvatar firstName={authUserState.employee.firstName} color="purple" />
        <Box
          sx={{
            background: theme.palette.stationGray[200],
            alignSelf: 'stretch',
            height: { md: '1px', xs: 'auto' },
            width: { md: '100%', xs: '1px' },
          }}
        />
        <LetterAvatar {...currentDepartmentNames} color="cyan" />
      </Box>
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: isSm ? 'right' : -8, vertical: isSm ? -8 : 'bottom' }}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: '16px',
            color: theme.palette.stationGray[900],
            width: '330px',
          },
        }}
      >
        {!isSwitchDepartmentOpen && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2 }}>
              <Box sx={{ flex: 1 }} />
              <Button
                data-cy="profile-menu-logout"
                onClick={logout}
                color="inherit"
                sx={{ color: theme.palette.stationGray[700] }}
              >
                Sign out
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: theme.spacing(0, 2, 4, 2) }}>
              <LetterAvatar size="large" firstName={authUserState.employee.firstName} color="purple" />
              <Box sx={{ typography: 'bodyLSemibold', mt: 0.5, mb: 2 }}>
                {`${authUserState.employee.firstName} ${authUserState.employee.lastName}`}
              </Box>
              {canEditEmployeeProfile && (
                <Button component={Link} to={`/app/employees/${authUserState.employee.id}`} variant="outlined">
                  View or edit profile
                </Button>
              )}
            </Box>

            <Box sx={{ borderTop: `1px dashed ${theme.palette.stationGray[200]}`, p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LetterAvatar {...currentDepartmentNames} color="cyan" />
                <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{departmentInfoState.departmentInfo.name}</Box>
              </Box>
              {switchDepartmentMenuItems.length > 0 && (
                <Button onClick={() => setIsSwitchDepartmentOpen(true)} variant="outlined" sx={{ gap: 1, mt: 2, width: '100%' }}>
                  Change department
                  <ChevronRightIcon20 />
                </Button>
              )}
            </Box>
          </>
        )}
        {isSwitchDepartmentOpen && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: theme.spacing(1, 0.5, 1, 2) }}>
              <Box sx={{ typography: 'bodyMSemibold' }}>Change department</Box>
              <IconButton
                onClick={() => setIsSwitchDepartmentOpen(false)}
                aria-label="Close"
                sx={{ color: theme.palette.stationGray[400], p: 1 }}
              >
                <XCloseIcon24 />
              </IconButton>
            </Box>
            <Box
              sx={{
                '& > *': { borderBottom: `1px solid ${theme.palette.stationGray[100]}` },
                '& > * + *': { borderTop: `1px solid ${theme.palette.stationGray[100]}` },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: theme.spacing(1, 2) }}>
                <LetterAvatar {...currentDepartmentNames} color="cyan" />
                <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{departmentInfoState.departmentInfo.name}</Box>
                <CheckIcon20 />
              </Box>
              {switchDepartmentMenuItems.map((item) => (
                <Button
                  key={item.key}
                  onClick={item.onClick}
                  color="inherit"
                  sx={{
                    color: theme.palette.stationGray[900],
                    justifyContent: 'flex-start',
                    gap: 1,
                    p: theme.spacing(1, 2),
                    textAlign: 'left',
                    width: '100%',
                  }}
                >
                  <LetterAvatar {...splitDepartmentName(item.name)} color="cyan" />
                  <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{item.name}</Box>
                </Button>
              ))}
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};
