/* eslint-disable no-restricted-imports */
import { Box, Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';
import { ElementType } from 'react';

export type ButtonProps<E extends ElementType = 'button'> = MuiButtonProps<E> & {
  // This won't be necessary after we upgrade to MUI v6.4.0.
  loading?: boolean;
};

export const Button = <E extends ElementType>({ children, loading, sx, ...props }: ButtonProps<E>) => {
  const { size = 'medium' } = props;

  return (
    <MuiButton
      disableElevation
      {...props}
      disabled={props.disabled || loading}
      sx={[
        {
          color: loading ? 'transparent !important' : undefined,
          textTransform: 'none',
          ...(size === 'large' && { typography: 'buttonL', minHeight: '42px' }),
          ...(size === 'medium' && { typography: 'buttonM', minHeight: '36px' }),
          ...(size === 'small' && { typography: 'buttonS', minHeight: '30px' }),
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {loading && (
        <Box
          component="span"
          sx={(theme) => ({
            color: theme.palette.action.disabled,
            display: 'inline-flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '& ~ *': { opacity: 0 },
          })}
        >
          <CircularProgress color="inherit" size={16} />
        </Box>
      )}
      {children}
    </MuiButton>
  );
};
