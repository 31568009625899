import { Box } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { PayrollContent } from './PayrollContent';

export const PayrollMain = () => {
  useDocumentTitle('My Payroll');

  return (
    <AppPageLayout>
      <Box data-testid="payroll" sx={{ height: '100%', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
        <PayrollContent />
      </Box>
    </AppPageLayout>
  );
};
