import { Box, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { SvgIcon } from '@stationwise/component-module';
import { INavMenu } from '../../../app/AppPageLayout';
import { getIconData, MenuIcon } from './MenuIcon';

export const MoreMenu = ({ moreNavItems }: { moreNavItems: INavMenu[] }) => {
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  const moreIsActive = moreNavItems.some((navItem) => navItem.match.some((path) => matchPath(path, location.pathname)));

  return (
    <>
      <MenuIcon
        type="more"
        isActive={moreIsActive}
        onClick={(event: React.MouseEvent<HTMLElement>) => setMoreAnchorEl(event.currentTarget)}
      />
      <Popover
        anchorEl={moreAnchorEl}
        anchorPosition={{ top: 275, left: 64 }}
        anchorReference="anchorPosition"
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
        sx={{ boxShadow: 3 }}
      >
        <Box
          sx={{
            pt: 3,
            pb: 2,
            width: '320px',
          }}
        >
          <Box sx={{ px: 3, pb: 1, typography: 'bodyMSemibold' }}>More</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {moreNavItems
              .filter((navItem) => !navItem.mobileOnly)
              .map((navItem) => {
                const iconData = getIconData(navItem.type);
                const isActive = navItem.match.some((path) => matchPath(path, location.pathname));
                return (
                  <ListItemButton
                    key={`${navItem.type}-${navItem.path}`}
                    component={Link}
                    data-cy={iconData.title}
                    to={navItem.path}
                    sx={(theme) => ({
                      gap: 2,
                      background: isActive ? theme.palette.stationGray[100] : 'transparent',
                      px: 3,
                      py: 2,
                      '& svg': {
                        color: isActive ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
                        fill: isActive ? theme.palette.stationGray[900] : theme.palette.stationGray[400],
                      },
                      '&:hover': {
                        '& svg': {
                          color: theme.palette.stationGray[900],
                          fill: theme.palette.stationGray[900],
                        },
                      },
                    })}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <SvgIcon component={isActive ? iconData.ActiveComponent : iconData.Component} />
                    </ListItemIcon>
                    <ListItemText sx={{ m: 0 }}>{iconData.title}</ListItemText>
                  </ListItemButton>
                );
              })}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
