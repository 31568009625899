import { Box, useTheme } from '@mui/material';
import { format, getDaysInMonth, isSameDay } from 'date-fns';
import { ReactNode } from 'react';
import { asDepartmentDateTime, useLoadedDepartmentInfoContext } from '@stationwise/component-module';

interface MonthDaysProps {
  month: Date;
  renderOverlay?: (date: Date, isToday: boolean) => ReactNode;
  renderToday?: (content: ReactNode) => ReactNode;
}

export const MonthDays = (props: MonthDaysProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const today = asDepartmentDateTime(departmentInfoState, new Date());

  const dates = (() => {
    const dates: Date[] = [];
    while (dates.length < getDaysInMonth(props.month)) {
      dates.push(new Date(props.month.getFullYear(), props.month.getMonth(), dates.length + 1));
    }
    return dates;
  })();

  return dates.map((date) => {
    const isToday = isSameDay(date, today);

    let content: ReactNode = [date.getDate(), <br key="line-break" />, format(date, 'EEEEE')];
    if (isToday && props.renderToday) {
      content = props.renderToday(content);
    }

    return (
      <Box
        key={date.toISOString()}
        sx={{
          flex: 1,
          minWidth: `${Math.round((1 / dates.length) * 100 * 100) / 100}%`,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            color: theme.palette.text.secondary,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            typography: 'bodySMedium',
            lineHeight: '16px',
            textAlign: 'center',
          }}
        >
          {content}
        </Box>
        {props.renderOverlay?.(date, isToday)}
      </Box>
    );
  });
};
