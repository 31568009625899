import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { format, isSameDay, isValid, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  CalendarShiftCard,
  STATUS_TITLES,
  CALENDAR_EVENT_TYPES,
  ChevronLeftIcon24,
  XCloseIcon24,
  StatusBadge,
  PayPeriodDateInfo,
  DollarIcon16,
  useSelectedStaffingList,
  useFetchStaffingLists,
  Loader,
  ErrorPage,
  GenericDrawerOrModal,
} from '@stationwise/component-module';
import { PersonalStaffingListControls } from '../../PersonalStaffingLists/components/PersonalStaffingListControls';
import { PersonalStaffingListItems } from '../../PersonalStaffingLists/components/PersonalStaffingListItems';
import { PersonalStaffingListPosition } from '../../PersonalStaffingLists/components/PersonalStaffingListPosition';
import { ButtonAPT } from './ButtonAPT';
import { Overtime } from './Overtime';
import { OvertimeRequestCard } from './RequestOvertime/OvertimeRequestCard';
import { getStatus } from './RequestSharedComponents/StatusCard';

interface DrawerContentProps {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
  todayEvents: EventInput[];
  handleShiftOverviewOpen?: (event: React.MouseEvent<HTMLElement>, shift: EventInput | EventImpl) => void;
  selectedView: string;
  handleEmptyDayAdditionalPaidTimeClick?: (shift: EventInput | EventImpl) => void;
  payPeriodDates: PayPeriodDateInfo[];
  viewOnly?: boolean;
}

const getStatusBadge = (event: EventInput) => {
  if (!event['status']) {
    return null;
  }
  const status = getStatus(event['status']);
  return <StatusBadge status={status} />;
};

export const DrawerContent = ({
  handleEmptyDayAdditionalPaidTimeClick,
  selectedView,
  setDrawerOpen,
  selectedDate,
  todayEvents,
  handleShiftOverviewOpen,
  payPeriodDates,
  viewOnly = false,
}: DrawerContentProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const payPeriod = payPeriodDates.find((period) => isSameDay(parseISO(period.startDate), selectedDate));

  const formattedStartDate = payPeriod ? format(parseISO(payPeriod.startDate), 'MMM d') : '';
  const formattedEndDate = payPeriod ? format(parseISO(payPeriod.endDate), 'MMM d') : '';

  const staffingListsResponse = useFetchStaffingLists();
  const { isLoading, isError, staffingLists, selectedStaffingList, setSelectedStaffingList } = useSelectedStaffingList({
    selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
    staffingListsResponse,
    initialSelectedEmpty: true,
  });
  const [staffingListModalOpen, setStaffingListModalOpen] = useState(false);
  const [shouldScrollToMeItem, setShouldScrollToMeItem] = useState(false);

  useEffect(() => {
    if (selectedStaffingList) {
      setStaffingListModalOpen(true);
    }
  }, [selectedStaffingList]);

  useEffect(() => {
    setSelectedStaffingList(undefined);
  }, [selectedDate, setSelectedStaffingList]);

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        padding: '16px',
        position: 'relative',
        zIndex: theme.zIndex.appBar,
      })}
    >
      <Box
        sx={(theme) => ({
          position: viewOnly ? 'absolute' : 'fixed',
          right: 0,
          top: 0,
          height: '100%',
          width: { xs: '100%', md: '400px' },
          backgroundColor: theme.palette.common.white,
        })}
      >
        {(isMobile || viewOnly) && (
          <Box sx={{ pt: viewOnly ? 0 : 7, pb: 1, display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 2,
                px: viewOnly ? 0 : 2,
              }}
              onClick={() => setDrawerOpen(false)}
            >
              <ChevronLeftIcon24 />
              <Box sx={(theme) => ({ ml: theme.spacing(2), typography: 'bodyLSemibold' })}>
                {format(selectedDate, 'MMMM dd, yyyy')}
              </Box>
            </Box>
          </Box>
        )}

        {!isMobile && !viewOnly && (
          <Box sx={{ pt: 7, pb: 1, display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
              }}
            >
              <Box sx={{ typography: 'bodyLSemibold' }}>{format(selectedDate, 'MMMM dd, yyyy')}</Box>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  '& svg': {
                    color: theme.palette.stationGray[500],
                  },
                  cursor: 'pointer',
                })}
              >
                <XCloseIcon24 onClick={() => setDrawerOpen(false)} />
              </Box>
            </Box>
          </Box>
        )}
        {payPeriod && (
          <Box
            sx={(theme) => ({
              px: theme.spacing(2),
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                color: theme.palette.primary.main,
              },
              backgroundColor: theme.palette.stationLightBlue[100],
              mb: theme.spacing(2),
              height: '52px',
              width: '100%',
            })}
          >
            <DollarIcon16 />
            <Box sx={(theme) => ({ ml: theme.spacing(1), typography: 'buttonL' })}>
              {`New pay period start (${formattedStartDate} - ${formattedEndDate})`}
            </Box>
          </Box>
        )}
        {!viewOnly && (
          <Box sx={(theme) => ({ flexDirection: 'column', px: theme.spacing(2) })}>
            <PersonalStaffingListControls
              isLoading={isLoading}
              staffingLists={staffingLists}
              selectedStaffingList={selectedStaffingList}
              setSelectedStaffingList={setSelectedStaffingList}
              selectedDate={selectedDate}
              setSelectedDate={undefined}
            />
            <GenericDrawerOrModal
              anchor="bottom"
              drawerOpen={staffingListModalOpen}
              handleOnClose={() => {
                setSelectedStaffingList(undefined);
                setStaffingListModalOpen(false);
              }}
              loading={false}
              showHeader={true}
              headerTitle={selectedStaffingList?.name}
              disableFooter={true}
              noBorderOnHeader={true}
              sxProps={{
                '.MuiDrawer-paper': {
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                },
              }}
            >
              <Box sx={(theme) => ({ p: theme.spacing(2) })}>
                {isLoading && <Loader sx={{ mt: 6 }} />}
                {!isLoading && isError && <ErrorPage />}
                {!isLoading && !isError && (
                  <PersonalStaffingListPosition
                    selectedStaffingList={selectedStaffingList}
                    onClick={() => setShouldScrollToMeItem(true)}
                  />
                )}
                {!isError && (
                  <PersonalStaffingListItems
                    isLoading={isLoading}
                    selectedStaffingList={selectedStaffingList}
                    shouldScrollToMeItem={shouldScrollToMeItem}
                    setShouldScrollToMeItem={setShouldScrollToMeItem}
                  />
                )}
              </Box>
            </GenericDrawerOrModal>
          </Box>
        )}

        <Box>
          {todayEvents.map((shift) => {
            const isStaffingListMark = shift.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.STAFFING_LIST_MARK;

            if (isStaffingListMark && shift['status'] === STATUS_TITLES.CANCELLED) {
              return <OvertimeRequestCard key={shift.id} shift={shift} cancelled={true} />;
            }

            return (
              <CalendarShiftCard
                key={shift.id}
                shift={shift}
                handleClick={handleShiftOverviewOpen}
                statusBadge={getStatusBadge(shift)}
                selectedView={selectedView}
                showIcon={!viewOnly}
              >
                {shift.title && isStaffingListMark && shift.title?.includes('SIGN UP') && (
                  <Overtime key={shift.id} shift={shift} />
                )}
              </CalendarShiftCard>
            );
          })}
          <ButtonAPT
            todayEvents={todayEvents}
            handleEmptyDayAdditionalPaidTimeClick={handleEmptyDayAdditionalPaidTimeClick}
            selectedDate={selectedDate}
            viewOnly={viewOnly}
          />
          <Box sx={{ height: '300px' }} />
        </Box>
      </Box>
    </Box>
  );
};
