import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@stationwise/component-module';

interface CancelRequestProps {
  handleOnClose: Dispatch<SetStateAction<boolean>>;
  handleRequest: () => void;
  isOptOut?: boolean;
  isLoading: boolean;
}

export const CancelRequest = ({ handleOnClose, handleRequest, isOptOut, isLoading }: CancelRequestProps) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        p: theme.spacing(2),
        justifyContent: 'center',
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          mb: theme.spacing(2),
          mt: theme.spacing(1),
        })}
      >
        <Button variant="outlined" size="large" onClick={() => handleOnClose(false)} sx={{ width: '100%' }}>
          {isOptOut ? 'No, I want to keep the opt out' : 'No, I want to keep the request'}
        </Button>
      </Box>
      <Box sx={(theme) => ({ width: '100%', mb: theme.spacing(1) })}>
        <Button
          variant="contained"
          size="large"
          loading={isLoading}
          onClick={handleRequest}
          sx={{ width: '100%' }}
          data-cy="confirm-cancellation-button"
        >
          {isOptOut ? 'Yes, cancel the opt out' : 'Yes, cancel this request'}
        </Button>
      </Box>
    </Box>
  );
};
