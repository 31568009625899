import { Box, FormControl, InputAdornment, InputLabel, Modal, OutlinedInput, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { ClockIcon20, Button, ToggleButton, ToggleButtonGroup, theme, XIcon24 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { TradeHistory } from './DetailsModal';

interface AddEditModalProps {
  open: boolean;
  onClose: () => void;
  tradeHistoryItem: TradeHistory | null;
  selectedEmployeeId: string | undefined;
  tradeEmployeeId: number | undefined;
  selectedEmployeeName: string | undefined;
  tradeEmployeeName: string | undefined;
  onRefetch: () => void;
}

export const AddEditModal = ({
  open,
  onClose,
  tradeHistoryItem,
  selectedEmployeeId,
  tradeEmployeeId,
  selectedEmployeeName,
  tradeEmployeeName,
  onRefetch,
}: AddEditModalProps) => {
  const newShiftTradeHistory: TradeHistory = {
    id: -1,
    tradeRequest: null,
    requester: null,
    responder: null,
    tradeDuration: 0,
    note: '',
  };

  const [shiftTradeHistory, setShiftTradeHistory] = useState<TradeHistory>(
    tradeHistoryItem ? tradeHistoryItem : newShiftTradeHistory,
  );

  const [add, setAdd] = useState<boolean>(
    !tradeHistoryItem ? false : tradeHistoryItem.responder?.id === selectedEmployeeId ? true : false,
  );

  const closeModal = () => {
    setShiftTradeHistory(newShiftTradeHistory);
    onClose();
  };

  const handleTimeChange = (time: string | null) => {
    if (time) {
      setShiftTradeHistory((prevState) => ({ ...prevState, tradeDuration: Number(time) }));
    } else {
      setShiftTradeHistory((prevState) => ({ ...prevState, tradeDuration: 0 }));
    }
  };

  const handleSave = async () => {
    const data = {
      note: shiftTradeHistory.note,
      requester: add ? tradeEmployeeId : selectedEmployeeId,
      responder: add ? selectedEmployeeId : tradeEmployeeId,
      tradeDuration: shiftTradeHistory.tradeDuration,
    };

    if (tradeHistoryItem) {
      try {
        await client.put(`/request/shift-trade-history/${tradeHistoryItem?.id}/`, data);
        onRefetch();
        closeModal();
      } catch (error) {
        captureException(error);
      }
    } else {
      try {
        await client.post('/request/shift-trade-history/', data);
        onRefetch();
        closeModal();
      } catch (error) {
        captureException(error);
      }
    }
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      handleTimeChange(value);
    }
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            width: '350px',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '16px',
            }}
          >
            <Typography variant="bodyXLSemibold"> {tradeHistoryItem ? 'Edit' : 'Add'} Entry</Typography>
            <Box
              onClick={closeModal}
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.stationGray[600],
                },
              })}
            >
              <XIcon24 />
            </Box>
          </Box>
          <Typography variant="bodyMSemibold">{selectedEmployeeName}</Typography>
          <Box
            sx={(theme) => ({
              my: theme.spacing(2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: theme.spacing(2),
              '& .MuiStack-root': {
                padding: 0,
              },
              '& .MuiInputBase-root': {
                height: '50px',
              },
              '& .MuiFormControl-root': {
                mt: '8px',
              },
            })}
          >
            <ToggleButtonGroup
              sx={{ mt: '8px' }}
              value={add}
              exclusive
              onChange={(_, value) => setAdd(value)}
              aria-label="text alignment"
            >
              <ToggleButton value={false}>
                <Typography variant="bodySSemibold"> OWES</Typography>
              </ToggleButton>
              <ToggleButton value={true} data-cy="is-owed-button">
                <Typography variant="bodySSemibold"> IS OWED </Typography>
              </ToggleButton>
            </ToggleButtonGroup>

            <FormControl sx={{ mt: 1, width: '150px' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Duration (minutes)</InputLabel>
              <OutlinedInput
                data-cy="duration-input"
                id="outlined-adornment-password"
                type={'text'}
                value={shiftTradeHistory.tradeDuration}
                onChange={handleNumberChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      sx={(theme) => ({
                        '& svg': {
                          '& path': {
                            fill: theme.palette.stationGray[500],
                          },
                        },
                      })}
                    >
                      <ClockIcon20 />
                    </Box>
                  </InputAdornment>
                }
                label="Duration (minutes)"
              />
            </FormControl>
          </Box>
          <Typography variant="bodyMSemibold" sx={{ mb: theme.spacing(2) }}>
            {add ? 'by' : 'to'} {tradeEmployeeName}
          </Typography>
          <TextField
            data-cy="note-input"
            value={shiftTradeHistory.note}
            onChange={(e) => setShiftTradeHistory((prevState) => ({ ...prevState, note: e.target.value }))}
            sx={(theme) => ({
              width: '100%',
              mt: theme.spacing(1),
              mb: theme.spacing(2),
              '.MuiInputBase-root': {
                padding: 0,
              },
            })}
            multiline
            rows={4}
            label="Note"
          />
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button size="large" variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              data-cy="save-button"
              size="large"
              variant="contained"
              onClick={handleSave}
              disabled={shiftTradeHistory.tradeDuration === 0 || shiftTradeHistory.note === ''}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
