import { object, array, string, TypeOf, z } from 'zod';
import { dateRegexMonthDayYear } from './common.dto';

// Post overtime request DTO
export const postOvertimeRequestSchema = object({
  selected_dates: array(
    string().refine((str) => dateRegexMonthDayYear.test(str), {
      message: 'Invalid date format. Must be mm/dd/yyyy.',
    }),
  ).refine((arr) => arr.length > 0, {
    message: 'A date needs to be selected.',
  }),
  timePreference: z.string().optional(),
});
export type PostOvertimeRequestDTO = TypeOf<typeof postOvertimeRequestSchema>;
