/* eslint-disable no-restricted-imports */
import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps as MuiToggleButtonGroupProps } from '@mui/material';

export { ToggleButton } from '@mui/material';

export type ToggleButtonGroupProps = MuiToggleButtonGroupProps;

export const ToggleButtonGroup = ({ children, sx, ...props }: ToggleButtonGroupProps) => {
  const { size = 'medium' } = props;

  return (
    <MuiToggleButtonGroup
      {...props}
      sx={[
        {
          '& .MuiToggleButton-root': {
            textTransform: 'none',
            ...(size === 'large' && { typography: 'buttonL', minHeight: '42px' }),
            ...(size === 'medium' && { typography: 'buttonM', minHeight: '36px' }),
            ...(size === 'small' && { typography: 'buttonS', minHeight: '30px' }),
          },
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </MuiToggleButtonGroup>
  );
};
