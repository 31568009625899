import { Box, Divider, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { captureException } from '@sentry/react';
import { useState, useEffect } from 'react';
import { theme, Button, SnackbarService, useLoadedDepartmentInfoContext, RankBadge } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { HiringEngineSettingsInfo, Rank } from '@stationwise/share-types';
import { AutoHireIntervalPicker } from '../../HiringEngine/AutoHire/AutoHireIntervalPicker';
import { generateRankOrder } from '../../HiringEngine/AutoHire/utils';
import { HiringEngineCallOrders } from './HiringEngineCallOrders';
import { HiringEngineVacancyFillOrders } from './HiringEngineVacancyFillOrders';
export const HiringEngineDepartmentSettingsTab = () => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const [isSaving, setIsSaving] = useState(false);
  const [initalHiringEngineSettingsInfo, setInitalHiringEngineSettingsInfo] = useState<HiringEngineSettingsInfo | null>(null);
  const [hiringEngineSettingsInfo, setHiringEngineSettingsInfo] = useState<HiringEngineSettingsInfo | null>(null);
  const [selectedVacancyRank, setSelectedVacancyRank] = useState<Rank | null>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  useEffect(() => {
    const fetchDefaultSettings = async () => {
      try {
        const response = await client.get('auto-hire/default-settings/');
        response.data.autoHireDefaultRankProcessOrder.forEach((rankId: number) => {
          if (!response.data.hiringEngineDefaultRankCallOrders[rankId]) {
            response.data.hiringEngineDefaultRankCallOrders[rankId] = generateRankOrder(rankId, departmentInfo?.ranks || []);
          }
        });
        const settingsData = JSON.parse(JSON.stringify(response.data));
        const initialData = JSON.parse(JSON.stringify(response.data));
        setHiringEngineSettingsInfo(settingsData);
        setInitalHiringEngineSettingsInfo(initialData);
      } catch (error) {
        SnackbarService.notify({
          content: 'Failed to fetch auto hire settings',
          severity: 'error',
          duration: 5000,
        });
        captureException(error);
      }
    };
    fetchDefaultSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter]);

  useEffect(() => {
    if (!selectedVacancyRank && initalHiringEngineSettingsInfo) {
      setSelectedVacancyRank(
        departmentInfo?.ranks.find((rank) => rank.id === initalHiringEngineSettingsInfo.autoHireDefaultRankProcessOrder[0]) ||
          null,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVacancyRank, initalHiringEngineSettingsInfo]);

  const handleSaveSettings = async (intervalChanged = false) => {
    setIsSaving(true);
    try {
      if (intervalChanged) {
        await client.post('auto-hire/save-default-settings/', {
          notification_interval: hiringEngineSettingsInfo?.autoHireDefaultNotificationInterval,
        });
      } else {
        await client.post('auto-hire/save-default-settings/', {
          rank_process_orders: hiringEngineSettingsInfo?.autoHireDefaultRankProcessOrder || [],
          rank_call_orders: hiringEngineSettingsInfo?.hiringEngineDefaultRankCallOrders || {},
        });
      }
      SnackbarService.notify({
        content: intervalChanged ? 'Auto hire round duration saved successfully' : 'Hiring engine order saved successfully',
        severity: 'success',
        duration: 2000,
      });
    } catch (error) {
      SnackbarService.notify({
        content: 'Failed to save auto hire settings',
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
    } finally {
      setIsSaving(false);
      if (!intervalChanged) {
        setRefreshCounter(refreshCounter + 1);
      } else {
        if (initalHiringEngineSettingsInfo && hiringEngineSettingsInfo?.autoHireDefaultNotificationInterval) {
          setInitalHiringEngineSettingsInfo({
            ...initalHiringEngineSettingsInfo,
            autoHireDefaultNotificationInterval: hiringEngineSettingsInfo?.autoHireDefaultNotificationInterval,
          });
        }
      }
    }
  };

  const hasChanges = () => {
    if (initalHiringEngineSettingsInfo) {
      if (
        hiringEngineSettingsInfo?.autoHireDefaultRankProcessOrder.length !==
        initalHiringEngineSettingsInfo.autoHireDefaultRankProcessOrder.length
      )
        return true;
      if (
        hiringEngineSettingsInfo?.autoHireDefaultRankProcessOrder.some(
          (order, index) => order !== initalHiringEngineSettingsInfo.autoHireDefaultRankProcessOrder[index],
        )
      )
        return true;
      if (
        hiringEngineSettingsInfo?.hiringEngineDefaultRankCallOrders !==
        initalHiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders
      ) {
        const initialOrders = initalHiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders;
        const currentOrders = hiringEngineSettingsInfo?.hiringEngineDefaultRankCallOrders;
        return Object.keys(currentOrders).some((rankId) => {
          const initialRankOrders = initialOrders[rankId] || [];
          const currentRankOrders = currentOrders[rankId] || [];
          const hasDifferences = currentRankOrders.some((order, index) => {
            const initialOrder = initialRankOrders[index];
            return !initialOrder || order.rankId !== initialOrder.rankId || order.isSelected !== initialOrder.isSelected;
          });
          return hasDifferences;
        });
      }
    }
    return false;
  };

  return (
    <Box sx={{ height: '100%', p: theme.spacing(3) }}>
      {hiringEngineSettingsInfo && (
        <Box>
          <Alert
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: '6px',
              border: `1px solid ${theme.palette.info.main}`,
              mb: theme.spacing(3),
            }}
          >
            These are global hiring engine settings. Changes will only affect future auto hire processes and will not impact any
            currently active ones.
          </Alert>

          <Box sx={{ maxWidth: '100%', mt: '43px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="buttonL">Round Duration</Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={() => handleSaveSettings(true)}
                  disabled={
                    isSaving ||
                    hiringEngineSettingsInfo.autoHireDefaultNotificationInterval ===
                      initalHiringEngineSettingsInfo?.autoHireDefaultNotificationInterval
                  }
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Divider sx={{ mt: '21px', mb: '25px' }} />

            <Box sx={{ display: 'flex', gap: theme.spacing(3), justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <AutoHireIntervalPicker
                  value={hiringEngineSettingsInfo.autoHireDefaultNotificationInterval?.toString()}
                  onChange={(newValue) => {
                    if (newValue) {
                      const totalMinutes = parseInt(newValue);
                      setHiringEngineSettingsInfo({
                        ...hiringEngineSettingsInfo,
                        autoHireDefaultNotificationInterval: totalMinutes,
                      });
                    }
                  }}
                  sx={{
                    width: '153px',
                    '& .MuiInputBase-root': {
                      backgroundColor: theme.palette.common.white,
                      borderRadius: '8px',
                    },
                  }}
                />
              </Box>

              <Alert
                severity="info"
                variant="standard"
                sx={{
                  borderRadius: '6px',
                  border: `1px solid ${theme.palette.info.main}`,
                  mb: theme.spacing(3),
                }}
              >
                Auto hire sends notifications to rank groups in the order shown on the left. Each round starts after the specified
                interval.
              </Alert>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: theme.spacing(3) }}>
              <Typography variant="buttonL">Hiring Engine Order</Typography>
              <Button
                variant="contained"
                onClick={() => handleSaveSettings(false)}
                disabled={isSaving || !hasChanges()}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Divider sx={{ mt: '21px', mb: '25px' }} />

          <Box
            sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: theme.spacing(8) }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
                Vacancy fill order
              </Typography>
              <Divider />
              <Typography
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  typography: 'bodySRegular',
                  mb: theme.spacing(2),
                  mt: theme.spacing(2),
                  fontStyle: 'italic',
                })}
              >
                Drag and drop to reorder the ranks. Auto hire will notify ranks in this order.
              </Typography>

              <HiringEngineVacancyFillOrders
                clickable={true}
                selectedVacancyRank={selectedVacancyRank}
                setSelectedVacancyRank={setSelectedVacancyRank}
                hiringEngineSettingsInfo={hiringEngineSettingsInfo}
                setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
              />
            </Box>

            <Box>
              <Typography
                variant="h6"
                sx={{ mb: theme.spacing(2), display: 'flex', alignItems: 'center', flexDirection: 'row', gap: theme.spacing(1) }}
              >
                Call order: {selectedVacancyRank && <RankBadge rank={selectedVacancyRank} />} {selectedVacancyRank?.name}
              </Typography>
              <Divider />
              <Typography
                sx={(theme) => ({
                  color: theme.palette.stationGray[600],
                  typography: 'bodySRegular',
                  mb: theme.spacing(2),
                  mt: theme.spacing(2),
                  fontStyle: 'italic',
                })}
              >
                Hiring Engine will notify your selected rank groups in the order they are listed below.
              </Typography>

              {hiringEngineSettingsInfo.hiringEngineDefaultRankCallOrders && selectedVacancyRank && (
                <HiringEngineCallOrders
                  hiringEngineSettingsInfo={hiringEngineSettingsInfo}
                  selectedVacancyRank={selectedVacancyRank}
                  setHiringEngineSettingsInfo={setHiringEngineSettingsInfo}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
