import { Box, BoxProps, useTheme } from '@mui/material';

type BorderedBoxesProps = BoxProps<'div'>;

export const BorderedBoxes = ({ sx, children, ...props }: BorderedBoxesProps) => {
  const theme = useTheme();

  return (
    <Box
      {...props}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          border: `1px solid ${theme.palette.divider}`,
          '& > div': { display: 'flex', alignItems: 'center', height: '100%' },
          '& > div + div': { borderLeft: `1px solid ${theme.palette.divider}` },
          '& + div': { borderTop: 0 },
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
