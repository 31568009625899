import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { OrangeCircleWarningIcon20 } from '@stationwise/component-module';
import { Waitlist } from '@stationwise/share-types';

interface WaitlistWarningProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  waitlist: Waitlist | null;
  alreadyOnWaitlist?: boolean;
}

export const WaitlistWarning = ({ setOpen, waitlist, alreadyOnWaitlist }: WaitlistWarningProps) => {
  const handleClick = () => {
    setOpen(true);
  };
  if (!waitlist) return;
  if (waitlist.approvedCount + 1 <= waitlist.limit) return;

  return (
    <Box
      data-cy="waitlist-warning"
      sx={(theme) => ({
        display: 'flex',
        borderRadius: 2,
        p: theme.spacing(1.5),
        border: `1px solid ${theme.palette.stationOrange[200]}`,
        backgroundColor: theme.palette.stationYellow[100],
        mt: theme.spacing(1),
      })}
    >
      <Box sx={{ display: 'flex' }}>
        <Box>
          <OrangeCircleWarningIcon20 />
        </Box>
        <Box
          sx={(theme) => ({
            ml: theme.spacing(1),
            color: theme.palette.stationYellow[700],
            typography: 'bodyMRegular',
            display: 'inline',
          })}
        >
          Limit reached for number of users taking time off on this day. You{' '}
          {alreadyOnWaitlist ? 'are on the' : 'will be added to the'}
          <Box
            data-cy="show-waitlist-button"
            component="span"
            sx={(theme) => ({
              color: theme.palette.stationPurple[600],
            })}
            onClick={() => handleClick()}
          >
            <Box
              component="span"
              sx={(theme) => ({
                ml: theme.spacing(0.5),
                typography: 'bodyMRegular',
              })}
            >
              waitlist
            </Box>
          </Box>
          .
        </Box>
      </Box>
    </Box>
  );
};
