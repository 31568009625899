import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { DatePicker } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface PersonalStaffingListControlsProps {
  isLoading: boolean;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList: ListFieldsStaffingList | undefined;
  setSelectedStaffingList: Dispatch<SetStateAction<ListFieldsStaffingList | undefined>>;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>> | undefined;
}

export const PersonalStaffingListControls = (props: PersonalStaffingListControlsProps) => {
  const { setSelectedStaffingList, setSelectedDate } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 1,
        mb: 2,
        '@media (max-width: 500px)': { flexDirection: 'column' },
      }}
    >
      {setSelectedDate && (
        <DatePicker
          disabled={!props.staffingLists.length}
          value={props.selectedDate}
          onChange={(value) => setSelectedDate(value)}
          sx={{ width: 180 }}
        />
      )}
      <FormControl fullWidth>
        <Select
          data-cy="staffing-list-select"
          value={props.selectedStaffingList?.id || ''}
          displayEmpty
          renderValue={(value) => (value ? props.staffingLists.find((s1) => s1.id === value)?.name : 'My staffing lists')}
          onChange={(e) => {
            const selectedList = props.staffingLists.find((sl) => sl.id === e.target.value);
            setSelectedStaffingList(selectedList);
          }}
        >
          {props.staffingLists.map((sl) => (
            <MenuItem key={sl.id} value={sl.id} data-cy={`${makeTestIdentifier(sl.name)}-option`}>
              {sl.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
