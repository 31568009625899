import { Box, Tooltip, useTheme } from '@mui/material';
import { StaffingListItem, StaffingListEmployeeStatus } from '@stationwise/share-types';
import { differenceInUTCMinutes, makeTestIdentifier } from '@stationwise/share-utils';
import {
  cutDuration,
  formatShiftDuration,
  getOvertimeCardErrorMessage,
  makeOvertimeEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { getTooltipProps } from '../../../../utils/utils';
import { HireBackCard } from '../../../HireBackCard';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

interface HireBackOperatorCardProps {
  item: StaffingListItem;
  isDragOverlayCard?: boolean;
}

export const HireBackOperatorCard = ({ item, isDragOverlayCard = false }: HireBackOperatorCardProps) => {
  const theme = useTheme();
  const { staffingListsState, selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const splits = staffingListsState.employeeSplits.get(item.employee.id) || [];
  const totalDuration = splits.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
  const errorMessage = getOvertimeCardErrorMessage(shiftSummaryHelper, item);
  const hasErrorMessageStyle = !!errorMessage && !isDragOverlayCard;

  const isUnavailable = totalDuration >= 24 * 60 || item.employee.status === StaffingListEmployeeStatus.UNAVAILABLE;

  const getPartiallyAvailableInfo = () => {
    const availableDurations = cutDuration(
      { startDateTime: shiftSummaryHelper.shiftDuration.startTime, endDateTime: shiftSummaryHelper.shiftDuration.endTime },
      splits,
    );

    const availableDurationTexts =
      totalDuration > 0
        ? availableDurations.map((d) => {
            return formatShiftDuration({ startTime: d.startDateTime, endTime: d.endDateTime });
          })
        : '';
    return (
      <Box component="span" sx={{ whiteSpace: 'pre', typography: 'bodySRegular' }}>
        {availableDurationTexts ? ['Only partial shifts:', ...availableDurationTexts].join('\n') : 'Only partial shifts'}
      </Box>
    );
  };

  const getStatusInfo = () => {
    if (
      (totalDuration > 0 && totalDuration < 24 * 60) ||
      item.employee.status === StaffingListEmployeeStatus.ONLY_PARTIAL_SHIFTS
    ) {
      return getPartiallyAvailableInfo();
    } else if (isUnavailable || item.employee.status === StaffingListEmployeeStatus.ONLY_FULL_SHIFTS) {
      return (
        <Box component="span" sx={{ typography: 'bodySRegular', whiteSpace: 'pre' }}>
          {isUnavailable ? 'Unavailable' : 'Only full shifts'}
        </Box>
      );
    }
    return;
  };

  const card = (
    <Box
      data-cy={`ot-card-${makeTestIdentifier(item.employee.name)}`}
      sx={[
        {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: '12px',
          border: `1px solid ${theme.palette.stationGray[300]}`,
          opacity: hasErrorMessageStyle || isUnavailable ? 0.5 : undefined,
          position: 'relative',
        },
        getEmployeeCardStyles({
          theme,
          employeeActiveId: makeOvertimeEmployeeActiveId(`${item.employee.id}`),
          selectedEmptyPositionState,
        }),
      ]}
    >
      <HireBackCard
        type="default"
        item={item}
        statusInfo={getStatusInfo()}
        cardSxProps={{ border: 0, '&:hover': { border: 0 } }}
      />
    </Box>
  );

  if (!hasErrorMessageStyle) {
    return card;
  }

  return (
    <Tooltip arrow title={errorMessage} placement="bottom" slotProps={getTooltipProps()}>
      {card}
    </Tooltip>
  );
};
