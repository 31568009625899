import { RosterEmployee, RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, getPositionEmployeeSortKey, makeTemporaryPosition, sortTemporaryPositions } from './board';
import { mergeDurations } from './datetime';
import { getEmployeeSplitReferenceKey } from './getEmployeeSplits';
import { checkIsShift, checkIsEvent, checkIsAdministration, setEmployeeActiveId } from './id';
import { removeEmployeeAvailability } from './removeEmployeeAvailability';
import { IShiftSummaryHelper } from './types';
import { createUnassignedEmployee } from './unassigned';

export const splitEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employeeId: string,
  splits: RosterEditableEmployeeSplit[],
) => {
  let newShiftSummaryHelper = removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId,
    startTime: shiftSummaryHelper.shiftDuration.startTime,
    endTime: shiftSummaryHelper.shiftDuration.endTime,
    checkIsPositionExcluded: (p) => !checkIsShift(p) && !checkIsEvent(p) && !checkIsAdministration(p),
  });

  const newShiftSummarySplits = mergeCreatedOffSplits(splits).map((split) => ({
    startDateTime: split.startDateTime,
    endDateTime: split.endDateTime,
    minStartDateTime: split.minStartDateTime,
    maxEndDateTime: split.maxEndDateTime,
    reference: split.reference,
  }));
  newShiftSummaryHelper.employeeSplits = new Map(newShiftSummaryHelper.employeeSplits);
  newShiftSummaryHelper.employeeSplits.set(employeeId, newShiftSummarySplits);

  splits.forEach((split) => {
    if (split.reference.type !== 'ASSIGNMENT' || !split.employee) {
      return;
    }

    const { apparatus, position } = split.reference;
    const newEmployee = setEmployeeActiveId({
      ...split.employee,
      startDateTime: split.startDateTime,
      endDateTime: split.endDateTime,
    });
    if (!apparatus) {
      newShiftSummaryHelper = createUnassignedEmployee(newShiftSummaryHelper, newEmployee);
      return;
    }

    if (![...newShiftSummaryHelper.allStationCards.values()].some((s) => s.apparatuses.some((a) => a.id === `${apparatus.id}`))) {
      return;
    }

    const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, `${apparatus.id}`);
    const newPosition = newApparatus.positions.find((p) => p.id === `${position?.id}`);
    newShiftSummaryHelper.allStationCards = newAllStationCards;
    if (newPosition) {
      newPosition.employees.push(newEmployee);
      newPosition.employees.sort(getPositionEmployeeSortKey);
    } else {
      const newTemporaryPosition = { ...makeTemporaryPosition(newApparatus, newEmployee), employees: [newEmployee] };
      newApparatus.positions = sortTemporaryPositions([...newApparatus.positions, newTemporaryPosition]);
    }
  });

  return newShiftSummaryHelper;
};

export const mergeCreatedOffSplits = (splits: RosterEditableEmployeeSplit[]) => {
  return mergeDurations(splits, (s1, s2) => {
    return !!(
      getEmployeeSplitReferenceKey(s1) === getEmployeeSplitReferenceKey(s2) &&
      s2.reference.type !== 'ASSIGNMENT' &&
      s2.reference.id <= 0
    );
  });
};

export const compareEmployeeSplits = (
  initialEmployeeSplits: Map<string, RosterEditableEmployeeSplit[]>,
  currentEmployeeSplits: Map<string, RosterEditableEmployeeSplit[]>,
  employee: RosterEmployee,
) => {
  if (initialEmployeeSplits === currentEmployeeSplits) {
    return true;
  }
  if (checkIsAdministration(employee)) {
    const [initialKey, currentKey] = [initialEmployeeSplits, currentEmployeeSplits].map((employeeSplits) => {
      const splits = mergeDurations(employeeSplits.get(employee.id) || [], (s1, s2) => {
        return getEmployeeSplitReferenceKey(s1) === getEmployeeSplitReferenceKey(s2);
      });
      return splits.map((s) => `${s.startDateTime}|${s.endDateTime}|${getEmployeeSplitReferenceKey(s)}`).join('|');
    });
    return initialKey === currentKey;
  }
  return false;
};
