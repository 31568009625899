import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { SelectButton, SnackbarService } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ListFieldsStaffingList, StaffingListExemption } from '@stationwise/share-types';
import { ExemptionsContent } from './ExemptionsContent';

interface ExemptionsProps {
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList?: ListFieldsStaffingList;
  selectedDate: Date;
  forceRefetchStaffingList: () => void;
}
export const Exemptions = ({ staffingLists, selectedStaffingList, selectedDate, forceRefetchStaffingList }: ExemptionsProps) => {
  const [fetchFlag, setFetchFlag] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [exemptions, setExemptions] = useState<StaffingListExemption[]>([]);

  const forceRefetchExemptions = () => {
    setFetchFlag((prevFlag) => !prevFlag);
  };

  useEffect(() => {
    const fetchExemptions = async (selectedDate: string) => {
      try {
        const response = await client.get('staffing-list/exemptions/', {
          params: {
            date: selectedDate,
            staffingListId: selectedStaffingList?.id,
          },
        });
        setExemptions(response.data);
      } catch (err) {
        captureException(err);
        SnackbarService.notify({
          content: 'Unexpected error. Try again later.',
          severity: 'error',
          showCloseButton: true,
          duration: 5000,
        });
      }
    };

    if (isValid(selectedDate) && selectedStaffingList) {
      fetchExemptions(format(selectedDate, 'yyyy-MM-dd'));
    }
  }, [fetchFlag, setExemptions, selectedDate, selectedStaffingList]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <SelectButton data-cy="exemptions-button" onClick={handleClick} caret={open}>
        {`Exemptions (${exemptions.length})`}
      </SelectButton>
      <ExemptionsContent
        anchorEl={anchorEl}
        handleClose={handleClose}
        exemptions={exemptions}
        forceRefetchExemptions={forceRefetchExemptions}
        staffingLists={staffingLists}
        selectedStaffingList={selectedStaffingList}
        selectedDate={selectedDate}
        forceRefetchStaffingList={forceRefetchStaffingList}
      />
    </Box>
  );
};
