import { ReactComponent as ArrowForwardIosSymbol } from '@material-symbols/svg-400/outlined/arrow_forward_ios.svg';
import { Box, IconButton } from '@mui/material';
import { format, subDays } from 'date-fns';
import { SelectButton } from '../Button';
import { SvgIcon } from '../SvgIcon';

interface DateChangerProps {
  currentDate: Date;
  addDays: (newValue: number) => void;
  toggleMiniCalendar?: () => void;
  disableForward?: boolean;
}

export const DateChanger = ({ currentDate, addDays, toggleMiniCalendar, disableForward }: DateChangerProps) => {
  const disableBackward = subDays(currentDate, 1).getFullYear() < new Date().getFullYear();

  const handleAddDay = () => {
    if (!disableForward) {
      addDays(1);
    }
  };

  const handleSubtractDay = () => {
    if (!disableBackward) {
      addDays(-1);
    }
  };

  return (
    <Box sx={{ display: 'inline-flex', position: 'relative' }}>
      <SelectButton data-cy="date-changer" onClick={toggleMiniCalendar} sx={{ px: 5, typography: 'bodySMedium' }}>
        {format(currentDate, 'dd LLL yyyy EE')}
      </SelectButton>
      <IconButton
        data-cy="previous-day-button"
        disabled={disableBackward}
        onClick={handleSubtractDay}
        sx={(theme) => ({ position: 'absolute', top: theme.spacing(0.5), left: theme.spacing(0.75) })}
      >
        <SvgIcon component={ArrowForwardIosSymbol} sx={{ fontSize: '15px', transform: 'rotate(180deg)' }} />
      </IconButton>
      <IconButton
        data-cy="next-day-button"
        disabled={disableForward}
        onClick={handleAddDay}
        sx={(theme) => ({ position: 'absolute', top: theme.spacing(0.5), right: theme.spacing(0.75) })}
      >
        <SvgIcon component={ArrowForwardIosSymbol} sx={{ fontSize: '15px' }} />
      </IconButton>
    </Box>
  );
};
