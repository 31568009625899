import { ReactComponent as ChevronRightSymbol } from '@material-symbols/svg-400/outlined/chevron_right.svg';
import { ReactComponent as SearchSymbol } from '@material-symbols/svg-400/outlined/search.svg';
import { Box, InputAdornment, Popover, TextField, useTheme } from '@mui/material';
import { startOfMonth } from 'date-fns';
import { useDeferredValue, useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  SvgIcon,
  asDepartmentDateTime,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { ShiftPlanAction, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useShiftPlanCandidates } from '../../../hooks/useShiftPlanCandidates';
import { MonthDays, MonthSelector } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';
import { useShiftPlanContext } from '../../ShiftPlanContext';
import { ShiftPlanDialog, ShiftPlanCloseDialogResetContextEffect } from '../../ShiftPlanDialog';
import { CandidateInfo } from './CandidateInfo';
import { PersonRow } from './PersonRow';
import { PositionRow } from './PositionRow';
import { RemoveAssignmentForm } from './RemoveAssignmentForm';
import { UpsertAssignmentForm } from './UpsertAssignmentForm';
import { renderMonthDayOverlay, renderToday } from './renderDay';

export const PersonnelPanel = () => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { assignments, selectedPersonnelStruct, isSaving, setSelectedPersonnelStruct, setSelectedAction } = useShiftPlanContext();
  const [searchValue, setSearchValue] = useState('');
  const deferredSearchValue = useDeferredValue(searchValue);
  const [selectedMonth, setSelectedMonth] = useState(startOfMonth(asDepartmentDateTime(departmentInfoState, new Date())));
  const deferredSelectedMonth = useDeferredValue(selectedMonth);
  const [selectedPerson, setSelectedPerson] = useState<ShiftPlanCandidate | ShiftPlanAssignment | null>(null);
  const [selectedPersonTeam, setSelectedPersonTeam] = useState<ShiftPlanAssignment['employee']['team'] | null>(null);
  const [selectedPersonPopoverAnchorEl, setSelectedPersonPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const candidates = useShiftPlanCandidates({
    positionId: selectedPersonnelStruct.position?.id || null,
    searchValue: deferredSearchValue,
  });

  useEffect(() => {
    const { apparatus } = selectedPersonnelStruct;
    const apparatusEl = document.querySelector(`[data-cy="apparatus-${makeTestIdentifier(apparatus?.name || '')}"]`);
    apparatusEl?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPersonRowProps = (person: ShiftPlanCandidate | ShiftPlanAssignment) => ({
    person,
    position: selectedPersonnelStruct.position,
    month: deferredSelectedMonth,
    setSelectedAction,
    setSelectedPerson,
    setSelectedPersonTeam,
    setSelectedPersonPopoverAnchorEl,
  });

  return (
    <Box
      className={selectedMonth !== deferredSelectedMonth ? 'SWShiftPlan-switching-month' : ''}
      sx={{ flex: 1, minWidth: '1px', overflowX: 'auto' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', minWidth: '1150px', pt: 1.5, pl: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 1.5 }}>
          {selectedPersonnelStruct.station?.stationName}
          <SvgIcon component={ChevronRightSymbol} sx={{ fontSize: '18px' }} />
          {selectedPersonnelStruct.apparatus?.name}
        </Box>
        <TextField
          fullWidth={true}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder="Search all personnel"
          type="search"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: -0.5, pointerEvents: 'none' }}>
                  <SvgIcon component={SearchSymbol} sx={{ color: theme.palette.action.active }} />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
          }}
        />
        <InfiniteScroll
          onLoadMore={candidates.loadMore}
          loader={candidates.hasNextPage && <Loader sx={{ my: 2 }} />}
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderTopLeftRadius: `${theme.shape.borderRadius}px`,
            flex: 1,
            overflowX: 'hidden',
            overflowY: 'auto',
            mt: 1.5,
          }}
        >
          <Box sx={{ background: theme.palette.common.white, position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 1 }}>
            <BorderedBoxes sx={{ borderTop: 0, borderLeft: 0 }}>
              <Box sx={{ flex: 1, px: 1.5, typography: 'bodySMedium' }}>Personnel</Box>
              <Box sx={{ justifyContent: 'center', px: 1.5, width: '700px', minWidth: '60%' }}>
                <MonthSelector value={selectedMonth} onChange={(newValue) => setSelectedMonth(newValue)} />
              </Box>
            </BorderedBoxes>
            <BorderedBoxes sx={{ borderLeft: 0 }}>
              <Box sx={{ width: '39px' }} />
              <Box sx={{ width: '40px' }} />
              <Box sx={{ flex: 7, minWidth: '240px', px: 1.5, typography: 'bodySMedium' }}>Name</Box>
              <Box sx={{ flex: 3, minWidth: '1px', px: 1.5, typography: 'bodySMedium' }}>Pattern</Box>
              <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
                <MonthDays month={selectedMonth} renderOverlay={renderMonthDayOverlay} renderToday={renderToday} />
              </BorderedBoxes>
            </BorderedBoxes>
          </Box>
          <BorderedBoxes sx={{ borderLeft: 0, borderTop: 0, borderBottom: 0, height: '32px' }}>
            <Box sx={{ px: 1.5, typography: 'bodySMedium' }}>{selectedPersonnelStruct.apparatus?.name}</Box>
          </BorderedBoxes>
          {selectedPersonnelStruct.apparatus?.positions.map((position, positionIndex, positions) => {
            const isSelected = selectedPersonnelStruct.position === position;

            let positionAssignments: ShiftPlanAssignment[] = [];
            if (isSelected) {
              positionAssignments = assignments.filter((a) => `${a.reference.position?.id}` === position.id);
            }

            return (
              <Box
                key={position.id}
                sx={{
                  '& > div': { borderLeft: 0 },
                  '& > div:last-of-type': { borderBottom: 0 },
                  '& > .SWShiftPlanPersonnelPanel-position-row': {
                    background: isSelected ? theme.palette.stationGray[300] : theme.palette.stationGray[200],
                    borderTop: `1px dashed ${theme.palette.stationGray[500]}`,
                    ...(!!(isSelected && positionAssignments.length > 0) && {
                      background: theme.palette.common.white,
                      ...(positionIndex === 0 && { borderTop: `1px solid ${theme.palette.divider}` }),
                    }),
                  },
                  ...(positionIndex === positions.length - 1 && {
                    '& > div:last-of-type': { borderBottom: `1px solid ${theme.palette.divider}` },
                    '& > .SWShiftPlanPersonnelPanel-position-row:last-of-type': {
                      borderBottom: `1px dashed ${theme.palette.stationGray[500]}`,
                    },
                  }),
                }}
              >
                <PositionRow
                  position={position}
                  isSelected={isSelected}
                  resetCandidates={candidates.resetData}
                  setSelectedPersonnelStruct={setSelectedPersonnelStruct}
                />
                {positionAssignments.map((assignment) => {
                  return <PersonRow key={assignment.id} {...getPersonRowProps(assignment)} position={position} />;
                })}
              </Box>
            );
          })}
          <BorderedBoxes sx={{ borderLeft: 0, borderTop: 0, height: '32px' }}>
            <Box sx={{ px: 1.5, typography: 'bodySMedium' }}>Eligible personnel</Box>
          </BorderedBoxes>
          {!candidates.isLoading && candidates.data.length === 0 && (
            <Box sx={{ my: 2, textAlign: 'center', typography: 'bodySMedium' }}>No eligible personnel</Box>
          )}
          <Box sx={{ '& > div': { borderLeft: 0 }, '& > div:first-of-type': { borderTop: 0 } }}>
            {candidates.data.map((candidate) => {
              if (candidate.assignments.length === 0) {
                return <PersonRow key={candidate.id} {...getPersonRowProps(candidate)} />;
              }

              const teamIds = new Set<number>();
              return candidate.assignments.map((assignment) => {
                const { team } = assignment.employee;
                if (teamIds.has(team.id)) {
                  return null;
                }

                teamIds.add(team.id);
                return <PersonRow key={`${candidate.id}|${team.id}`} {...getPersonRowProps(candidate)} team={team} />;
              });
            })}
          </Box>
        </InfiniteScroll>
        <Popover
          open={!!selectedPersonPopoverAnchorEl}
          onClose={() => !isSaving && setSelectedPersonPopoverAnchorEl(null)}
          anchorEl={selectedPersonPopoverAnchorEl}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: -4 }}
        >
          <ShiftPlanCloseDialogResetContextEffect />
          <UpsertAssignmentForm
            person={selectedPerson}
            team={selectedPersonTeam}
            setSelectedPersonPopoverAnchorEl={setSelectedPersonPopoverAnchorEl}
            setCandidateAssignments={candidates.setCandidateAssignments}
          />
          <CandidateInfo person={selectedPerson} team={selectedPersonTeam} />
        </Popover>
        <ShiftPlanDialog action={ShiftPlanAction.REMOVE_ASSIGNMENT}>
          <RemoveAssignmentForm person={selectedPerson} setCandidateAssignments={candidates.setCandidateAssignments} />
        </ShiftPlanDialog>
      </Box>
    </Box>
  );
};
