import { format, max, parseISO, setMinutes } from 'date-fns';
import { ShiftPlanAssignment } from '@stationwise/share-types';
import { compareEndDates, differenceInUTCMinutes } from '@stationwise/share-utils';

export const parseTeamPattern = (patternString: string) => {
  const pattern: boolean[] = [];
  if (!patternString) {
    return pattern;
  }

  patternString.split(',').forEach((part) => {
    const partMatch = part
      .trim()
      .toLowerCase()
      .match(/^(\d+)\s*(on|off)$/);
    if (partMatch) {
      let repeat = Number(partMatch[1]);
      while (repeat > 0) {
        pattern.push(partMatch[2] === 'on');
        repeat -= 1;
      }
    }
  });

  return pattern;
};

export const getTeamPatternDates = (assignment: ShiftPlanAssignment, fromDateString: string, toDateString: string) => {
  const pattern = parseTeamPattern(assignment.employee.team.pattern);
  const patternStartDate = parseISO(assignment.employee.team.patternStartDate);
  const { activationDate, deactivationDate } = assignment;
  const fromDate = parseISO(fromDateString);
  const toDate = parseISO(toDateString);

  const patternDates = new Set<string>();

  let patternIndex = 0;
  if (patternStartDate < fromDate) {
    patternIndex = (differenceInUTCMinutes(fromDate, patternStartDate) / (24 * 60)) % pattern.length;
  }

  let date = max([patternStartDate, fromDate]);
  while (date <= toDate) {
    if (date >= activationDate && compareEndDates(date, deactivationDate) <= 0 && pattern[patternIndex]) {
      patternDates.add(format(date, 'yyyy-MM-dd'));
    }

    patternIndex = (patternIndex + 1) % pattern.length;
    date = setMinutes(date, date.getMinutes() + 24 * 60);
  }

  return patternDates;
};

export const prettifyTeamPatternString = (patternString: string) => {
  // E.g. "2on,4off" -> "2 on, 4 off"
  const parts = patternString.split(',').map((part) => {
    return part
      .trim()
      .toLowerCase()
      .replace(/^(\d+)\s*(on|off)$/, (_match, $1, $2) => `${$1} ${$2}`);
  });
  return parts.join(', ');
};
