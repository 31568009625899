import { ReactComponent as CheckSymbol } from '@material-symbols/svg-400/outlined/check.svg';
import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { SvgIcon, getTeamColors } from '@stationwise/component-module';
import { ShiftPlanAssignment } from '@stationwise/share-types';
import { getTeamPatternDates } from '../../helpers/parseTeamPattern';
import { MonthDays } from './MonthDays';

interface TeamPatternDaysProps {
  assignments: ShiftPlanAssignment[];
  month: Date;
}

export const TeamPatternDays = (props: TeamPatternDaysProps) => {
  const theme = useTheme();
  const { background } = getTeamColors({ theme, color: props.assignments[0]?.employee.team.color });

  const patternDates = new Set(
    props.assignments.flatMap((assignment) => {
      const startOfMonthString = format(new Date(props.month.getFullYear(), props.month.getMonth(), 1), 'yyyy-MM-dd');
      const endOfMonthString = format(new Date(props.month.getFullYear(), props.month.getMonth() + 1, 0), 'yyyy-MM-dd');
      return Array.from(getTeamPatternDates(assignment, startOfMonthString, endOfMonthString));
    }),
  );

  const renderOverlay = (date: Date) => {
    const isTeamDay = patternDates.has(format(date, 'yyyy-MM-dd'));

    return (
      <Box
        sx={{
          background: isTeamDay ? background : theme.palette.common.white,
          color: theme.palette.common.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          '.SWShiftPlan-switching-month &': { background: theme.palette.common.white },
        }}
      >
        {isTeamDay && <SvgIcon component={CheckSymbol} sx={{ fontSize: '16px' }} />}
      </Box>
    );
  };

  return <MonthDays month={props.month} renderOverlay={renderOverlay} />;
};
