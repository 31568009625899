import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { MouseEvent, useId, useState } from 'react';
import { RosterEmployee } from '@stationwise/share-types';
import { ChevronDownIcon16 } from '../../../../assets';
import { PreviewButton, ViewProfileButton } from '../../../EmployeePreview';
import { useRosterContext } from '../RosterContext';

interface AdministrationActionsProps {
  employee: RosterEmployee;
}

export const AdministrationActions = ({ employee }: AdministrationActionsProps) => {
  const { splitShiftOrTimeOffState } = useRosterContext();

  const theme = useTheme();

  const id = useId();
  const [manageAnchorEl, setManageAnchorEl] = useState<HTMLElement | null>(null);

  const openManageMenu = (event: MouseEvent<HTMLButtonElement>) => setManageAnchorEl(event.currentTarget);
  const closeManageMenu = () => setManageAnchorEl(null);

  const openSplitShiftOrTimeOffModal = () => {
    splitShiftOrTimeOffState.setIsDialogOpen(true);
    closeManageMenu();
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          variant="contained"
          id={`${id}manage-button`}
          aria-controls={manageAnchorEl ? `${id}manage-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={manageAnchorEl ? 'true' : undefined}
          onClick={openManageMenu}
          sx={{
            gap: 0.5,
            '& svg': { minWidth: '16px' },
            '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
          }}
        >
          <Box component="span" sx={{ ml: 0.25 }}>
            Manage
          </Box>
          <ChevronDownIcon16 />
        </PreviewButton>
        <Menu
          id={`${id}manage-menu`}
          anchorEl={manageAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
          open={!!manageAnchorEl}
          onClose={closeManageMenu}
          MenuListProps={{ 'aria-labelledby': `${id}manage-button` }}
          sx={{
            '& .MuiMenu-paper': {
              backgroundColor: theme.palette.stationGray[700],
              border: `1px solid ${theme.palette.stationGray[600]}`,
              borderRadius: '12px',
            },
            '& .MuiMenu-list': {
              p: theme.spacing(1.5),
            },
            '& .MuiMenuItem-root': {
              backgroundColor: theme.palette.stationGray[700],
              borderRadius: '8px',
              color: theme.palette.common.white,
              p: theme.spacing(1, 1.5),
              typography: 'bodySRegular',
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: theme.palette.stationGray[800],
            },
          }}
        >
          <MenuItem
            component="a"
            href={`/app/employees/${employee.id}?edit-staffing=true`}
            target="_blank"
            onClick={closeManageMenu}
          >
            Edit work schedule
          </MenuItem>
          <MenuItem onClick={openSplitShiftOrTimeOffModal}>Time off</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
