import { useMediaQuery } from '@mui/material';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '@mui/x-date-pickers';
import { KeyboardEvent, MouseEvent, useState } from 'react';

interface DateOrTimePickerProps {
  desktopModeMediaQuery?: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const useDateOrTimePicker = (props: DateOrTimePickerProps) => {
  const isDesktop = useMediaQuery(props.desktopModeMediaQuery ?? DEFAULT_DESKTOP_MODE_MEDIA_QUERY, { defaultMatches: true });

  const [_open, _setOpen] = useState(props.open ?? false);
  const open = props.open ?? _open;
  const onOpen = props.onOpen ?? (() => _setOpen(true));
  const onClose = props.onClose ?? (() => _setOpen(false));

  return { isDesktop, open, onOpen, onClose };
};

export const getAutoOpenTextFieldProps = (open: boolean, onOpen: () => void, onClose: () => void) => {
  return {
    className: open ? 'SWDateOrTimePicker-open' : 'SWDateOrTimePicker-closed',
    onClick: (event: MouseEvent) => open && event.stopPropagation(),
    onPointerDown: (event: MouseEvent) => {
      if (!open && !document.querySelector('.SWDateOrTimePicker-open') && !(event.target as Element).closest('button')) {
        onOpen();
      }
    },
    onKeyDown: (event: KeyboardEvent) => {
      if (open && event.key === 'Escape') {
        event.stopPropagation();
        onClose();
      }
    },
  };
};
