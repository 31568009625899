import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Box, Pagination, Menu, MenuItem } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useDeferredValue, useEffect, useRef, useState } from 'react';
import { SearchAndFilter, LottieLogo, Button, useAuthUserCapabilities } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { Employee, Rank, WorkGroupPreview } from '@stationwise/share-types';
import { capitalize, camelCaseToTitle } from '@stationwise/share-utils';
import { AddEmployeeDefaults } from './AddEmployeeDefaults';
import { AddEmployeeDetails } from './AddEmployeeDetails';
import { AddEmployeeModal } from './AddEmployeeModal';
import { BulkUploadModal } from './BulkUploadModal';
import { PromoteModal } from './PromoteModal';
import { TeamSettingsTable } from './TeamSettingsTable';
import { TradeLedgerDrawer } from './TradeLedgerDrawer';
import { WorkGroupModal } from './WorkGroups/WorkGroupModal';

interface FilterOptions {
  [key: string]: string[];
}

interface MyTeamMainProps {
  workGroupsView: WorkGroupPreview[];
  updateFunc: React.Dispatch<React.SetStateAction<number>>;
}

export type AddEmployeeProps = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  hireDate: Date;
  type: string;
  rank: string;
  driversLicenseNumber: string;
  driversLicenseClasses: string[];
  arrivedEarlyPayCode: string;
  heldOverPayCode: string;
  overtimeAssignmentPayCode: string;
  regularAssignmentPayCode: string;
  straightPayHours: number;
};

const ActionButton = ({ onClick, dataCy, children }: { onClick: () => void; dataCy?: string; children: React.ReactNode }) => (
  <Box
    data-cy={`action-button-${dataCy}`}
    sx={(theme) => ({
      alignItems: 'center',
      display: 'flex',
      padding: '6px 8px',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: theme.palette.stationGray[800],
      color: theme.palette.stationGray[50],
      borderRadius: '8px',
      typography: 'bodySMedium',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.stationGray[700],
      },
    })}
    onClick={onClick}
  >
    {children}
  </Box>
);

const Divider = () => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.palette.stationGray[600],
      width: '1px',
      height: '100%',
      alignSelf: 'stretch',
      paddingY: '16px',
      paddingX: '1px',
    })}
  />
);

export const MyTeamMain = ({ workGroupsView, updateFunc }: MyTeamMainProps) => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [addEmployeeOpen, setaddEmployeeOpen] = useState(false);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [defaultsOpen, setDefaultsOpen] = useState(false);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedRanks, setSelectedRanks] = useState<Rank[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [uploadType, setUploadType] = useState<'accruals' | 'time-off' | 'custom-fields'>('accruals');

  const capabilities = useAuthUserCapabilities();
  const canAddEmployee = capabilities.ADD_NEW_EMPLOYEE;
  const canManageWorkGroups = capabilities.MANAGE_WORK_GROUPS;
  const canManageShiftTradeLedger = capabilities.MANAGE_SHIFT_TRADE_LEDGER;
  const canPromoteEmployees = capabilities.PROMOTE_EMPLOYEE;
  const canEditAccruals = capabilities.EDIT_ACCRUALS;
  const canUploadTimeOff = capabilities.UPLOAD_TIME_OFF;
  const canEditEmployeeProfile = capabilities.EDIT_EMPLOYEE_PROFILE;
  const hideCheckbox = !canManageWorkGroups && !canManageShiftTradeLedger && !canPromoteEmployees;

  const initialState = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    hireDate: new Date(),
    type: 'FULL_TIME',
    rank: '',
    driversLicenseNumber: '',
    driversLicenseClasses: [],
    arrivedEarlyPayCode: '',
    heldOverPayCode: '',
    overtimeAssignmentPayCode: '',
    regularAssignmentPayCode: '',
    straightPayHours: 0,
  };

  const [newEmployee, setNewEmployee] = useState<AddEmployeeProps>(initialState);
  const [showTradeLedger, setShowTradeLedger] = useState(false);

  const openTradeLedger = () => {
    setShowTradeLedger(true);
  };
  const addEmployeeInfo = (updatedEmployee: Partial<AddEmployeeProps>) => {
    setNewEmployee((prevState) => ({ ...prevState, ...updatedEmployee }));
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('employee/team/filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const params = {
          page: page,
          ...(deferredSearchInput && { search: deferredSearchInput }),
          ...filterParams,
        };
        const response = await client.get('employee/team/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        if (response.data.results && Array.isArray(response.data.results)) {
          setEmployees(response.data.results.filter((employee: Employee) => employee.name != null));
          setTotalPages(Math.ceil(response.data.count / 100));
        }
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [page, deferredSearchInput, selectedFilters, refresh]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setSelectedEmployeeIds([]);
    setSelectedRanks([]);
  };

  const handleFilterChange = (filterName: string, value: string) => {
    setPage(1);
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };
  const handleSearchChange = (value: string) => {
    setPage(1);
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };

  const getActionButtons = () => {
    const buttons = [];

    if (canManageWorkGroups) {
      buttons.push({
        onClick: () => setShowModal(true),
        label: 'Add to work group',
        dataCy: 'add-to-work-group-button',
      });
    }

    if (canPromoteEmployees && selectedRanks.length === 1) {
      buttons.push({
        onClick: () => setShowPromoteModal(true),
        label: 'Promote selected user',
        dataCy: 'promote-employee-button',
      });
    }

    if (canManageShiftTradeLedger && selectedEmployeeIds.length === 1) {
      buttons.push({
        'data-cy': 'show-trade-ledger-button',
        onClick: openTradeLedger,
        label: 'Show trade ledger',
        dataCy: 'show-trade-ledger-button',
      });
    }

    return buttons;
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccrualsUpload = () => {
    setUploadType('accruals');
    setBulkUploadOpen(true);
    handleMenuClose();
  };

  const handleVacationsUpload = () => {
    setUploadType('time-off');
    setBulkUploadOpen(true);
    handleMenuClose();
  };

  const handleRefresh = () => {
    if (setRefresh) {
      setRefresh(!refresh);
    }
  };

  const handleFieldUpload = () => {
    setUploadType('custom-fields');
    setBulkUploadOpen(true);
    handleMenuClose();
  };
  return (
    <Box>
      <Box
        sx={(theme) => ({
          boxShadow: 'none',
          gap: 3,
          margin: theme.spacing(1),
        })}
      >
        {showTradeLedger && selectedEmployeeIds.length > 0 && (
          <TradeLedgerDrawer
            isOpen={showTradeLedger}
            onClose={() => setShowTradeLedger(false)}
            employees={employees}
            employeeId={selectedEmployeeIds[0]}
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <SearchAndFilter
            searchText={searchInput}
            setSearchText={handleSearchChange}
            filters={filters}
            clearAllFilters={clearAllFilters}
            searchPlaceholder="Search for people in your team"
          />
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            {canEditAccruals || canUploadTimeOff ? (
              <>
                <Button data-cy="bulk-csv-upload-button" size="small" variant="outlined" onClick={handleMenuClick}>
                  Bulk CSV upload
                  <ExpandMoreIcon sx={{ fontSize: '16px', ml: 0.5 }} />
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                  {canEditAccruals && <MenuItem onClick={handleAccrualsUpload}>Upload Accruals .CSV</MenuItem>}
                  {canUploadTimeOff && (
                    <MenuItem data-cy="upload-time-off-csv-button" onClick={handleVacationsUpload}>
                      Upload Time-Off .CSV
                    </MenuItem>
                  )}
                  {canEditEmployeeProfile && (
                    <MenuItem onClick={handleFieldUpload}>Upload Miscellaneous Information .CSV</MenuItem>
                  )}
                </Menu>
              </>
            ) : undefined}
            {canAddEmployee && (
              <Button data-cy="add-new-employee-button" size="small" variant="outlined" onClick={() => setaddEmployeeOpen(true)}>
                + Add a new employee
              </Button>
            )}
          </Box>
        </Box>
        {!isLoading && (
          <TeamSettingsTable
            selectedEmployeeIds={selectedEmployeeIds}
            setSelectedEmployeeIds={setSelectedEmployeeIds}
            employees={employees}
            hideCheckbox={hideCheckbox}
            setSelectedRanks={setSelectedRanks}
          />
        )}
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {totalPages > 1 && !isLoading && <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ my: 2 }} />}
      </Box>
      <AddEmployeeModal
        addEmployeeOpen={addEmployeeOpen}
        setaddEmployeeOpen={setaddEmployeeOpen}
        setDetailsOpen={setDetailsOpen}
        employeeInfo={newEmployee}
        addEmployeeInfo={addEmployeeInfo}
        initialState={initialState}
      />
      <AddEmployeeDetails
        detailsOpen={detailsOpen}
        setDetailsOpen={setDetailsOpen}
        setDefaultsOpen={setDefaultsOpen}
        employeeInfo={newEmployee}
        addEmployeeInfo={addEmployeeInfo}
        initialState={initialState}
        setAddEmployeeOpen={setaddEmployeeOpen}
      />
      <AddEmployeeDefaults
        defaultsOpen={defaultsOpen}
        setDefaultsOpen={setDefaultsOpen}
        employeeInfo={newEmployee}
        addEmployeeInfo={addEmployeeInfo}
        initialState={initialState}
        setDetailsOpen={setDetailsOpen}
      />
      {bulkUploadOpen && canEditAccruals && <BulkUploadModal setBulkUploadOpen={setBulkUploadOpen} uploadType={uploadType} />}
      {selectedEmployeeIds.length > 0 && getActionButtons().length > 0 && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: theme.spacing(4),
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'inline-flex',
            padding: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            borderRadius: '12px',
            backgroundColor: theme.palette.stationGray[800],
          })}
        >
          {getActionButtons().map((button, index) => (
            <React.Fragment key={button.label}>
              <ActionButton onClick={button.onClick} dataCy={button.dataCy}>
                {button.label}
              </ActionButton>
              {index < getActionButtons().length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      )}
      {showModal && (
        <WorkGroupModal
          title={`Add ${selectedEmployeeIds.length} people to work group`}
          workGroupsView={workGroupsView}
          selectedEmployeeIds={selectedEmployeeIds}
          setSelectedEmployeeIds={setSelectedEmployeeIds}
          showModal={showModal}
          setShowModal={setShowModal}
          updateFunc={updateFunc}
        />
      )}{' '}
      {showPromoteModal && (
        <PromoteModal
          showModal={showPromoteModal}
          setShowModal={setShowPromoteModal}
          selectedRanks={selectedRanks}
          selectedEmployeeIds={selectedEmployeeIds}
          setSelectedEmployeeIds={setSelectedEmployeeIds}
          setSelectedRanks={setSelectedRanks}
          employees={employees}
          handleRefresh={handleRefresh}
        />
      )}
    </Box>
  );
};
