import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useId, useMemo } from 'react';
import { DepartmentInfo, RosterEditableEmployeeSplit, TimeOffLimit } from '@stationwise/share-types';
import { updateTimeOffSplitReason } from './actions';
import { getTimeOffUnsavedMinutes } from './getSplitStatuses';
import { TimeOffReasonOption, SplitProps } from './types';

const DEFAULT_OPTION: TimeOffReasonOption = { text: 'Select a reason', payCode: '', limit: null, isUnlimited: true };

export const getTimeOffReasonOptions = (
  departmentInfo: DepartmentInfo,
  accruals: TimeOffLimit[] | null,
  initialSplits: RosterEditableEmployeeSplit[],
) => {
  const options = new Map([[0, DEFAULT_OPTION]]);

  const payCodes = accruals ? accruals.flatMap((limit) => limit.payCodes) : [];

  if (departmentInfo.timeOffTypes) {
    departmentInfo.timeOffTypes.forEach((timeOffType) => {
      options.set(timeOffType.id, {
        text: timeOffType.name,
        payCode: timeOffType.code,
        limit: null,
        isUnlimited: true,
      });
    });
  }

  if (accruals) {
    const timeOffUnsavedMinutes = getTimeOffUnsavedMinutes({ splits: initialSplits });
    payCodes.forEach((payCode) => {
      const relatedLimit = accruals.find((opt) => opt.payCodes.includes(payCode));
      const unsaved = (timeOffUnsavedMinutes.get(payCode.id) || 0) / 60;
      options.set(payCode.id, {
        text: payCode.name,
        payCode: payCode.code,
        limit: departmentInfo.settings.accrualLogicEnabled
          ? relatedLimit!.accruedTimeOff - relatedLimit!.pending - unsaved
          : null,
        isUnlimited: departmentInfo.settings.accrualLogicEnabled ? relatedLimit!.isUnlimited : true,
      });
    });
  }

  return options;
};

type ReasonProps = SplitProps;

export const Reason = (props: ReasonProps) => {
  const { split, timeOffReasonOptions } = props;

  const id = useId();

  const { longestPayCode, shouldDisplayNotAvailableCode } = useMemo(() => {
    let longestPayCode = 'N/A';
    let shouldDisplayNotAvailableCode = false;
    for (const option of timeOffReasonOptions.values()) {
      if (option.payCode.length > longestPayCode.length) {
        longestPayCode = option.payCode;
      }

      if (option !== DEFAULT_OPTION && option.payCode !== option.text) {
        shouldDisplayNotAvailableCode = true;
      }
    }

    return { longestPayCode, shouldDisplayNotAvailableCode };
  }, [timeOffReasonOptions]);

  const checkShouldDisplayPayCode = (option: TimeOffReasonOption) => {
    return option === DEFAULT_OPTION ? shouldDisplayNotAvailableCode : option.payCode !== option.text;
  };

  return (
    <FormControl sx={{ mt: 2, width: '100%' }}>
      <InputLabel id={`${id}label`} shrink={true}>
        Reason
      </InputLabel>
      <Select
        labelId={`${id}label`}
        id={`${id}select`}
        label="Reason"
        displayEmpty={true}
        value={split.reference.type === 'TIME_OFF_REQUEST' ? `${split.reference.payCodeId}` : ''}
        onChange={(event) => updateTimeOffSplitReason(props, Number(event.target.value) || 0)}
        renderValue={(key) => {
          const option = timeOffReasonOptions.get(Number(key) || 0) || DEFAULT_OPTION;
          return (
            <Box
              data-cy="split-reason-select"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {checkShouldDisplayPayCode(option) && (
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySMedium' })}>
                    {option.payCode}
                  </Box>
                )}
                <Box sx={{ typography: 'bodyMRegular' }}>{option.text}</Box>
              </Box>
              {option.limit !== null && (
                <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                  {option.isUnlimited ? 'Unlimited' : `${option.limit.toFixed(2)} h`}
                </Box>
              )}
            </Box>
          );
        }}
        sx={{ width: '100%' }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: -8, horizontal: 'left' },
        }}
      >
        {[...timeOffReasonOptions.entries()].map(([key, option]) => (
          <MenuItem key={key} value={key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {checkShouldDisplayPayCode(option) && (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: theme.palette.stationGray[500],
                    typography: 'bodySMedium',
                    whiteSpace: 'nowrap',
                  })}
                >
                  <Box>{option === DEFAULT_OPTION ? 'N/A' : option.payCode}</Box>
                  <Box
                    sx={{
                      userSelect: 'none',
                      visibility: 'hidden',
                      height: 0,
                      overflow: 'hidden',
                    }}
                  >
                    {longestPayCode}
                  </Box>
                </Box>
              )}
              <Box sx={{ typography: 'bodyMRegular' }}>{option.text}</Box>
            </Box>
            {option.limit !== null && (
              <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                {option.isUnlimited ? 'Unlimited' : `${option.limit.toFixed(2)} h`}
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
