import { ReactComponent as FullscreenSymbol } from '@material-symbols/svg-400/outlined/fullscreen.svg';
import { Box, IconButton } from '@mui/material';
import { SvgIcon } from '@stationwise/component-module';
import { useShiftPlanContext } from '../ShiftPlanContext';

export const RosterEditorHeader = () => {
  const { selectedPersonnelStruct, setSelectedPersonnelStruct } = useShiftPlanContext();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pt: 1.5, px: 2 }}>
      <Box sx={{ typography: 'bodyXLMedium' }}>Your roster</Box>
      <Box sx={{ flex: 1 }} />
      {selectedPersonnelStruct.position && (
        <IconButton onClick={() => setSelectedPersonnelStruct({})}>
          <SvgIcon component={FullscreenSymbol} />
        </IconButton>
      )}
    </Box>
  );
};
