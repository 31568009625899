import { format } from 'date-fns';
import { useMemo } from 'react';
import { RosterEmployeeOffPayloads, ListFieldsStaffingList } from '@stationwise/share-types';
import { IShiftSummaryHelper, getStaffingListItemEmployeeSplits } from '@stationwise/shift-summary-helper';
import { useSelectedStaffingList } from '../../../hooks/useSelectedStaffingList';

interface UseRosterStaffingListsInput {
  staffingListsResponse?: { data: ListFieldsStaffingList[] | null; isError: boolean };
  shiftSummaryHelper: IShiftSummaryHelper;
  employeeOffPayloads: RosterEmployeeOffPayloads;
}

export const useRosterStaffingLists = ({
  staffingListsResponse,
  shiftSummaryHelper,
  employeeOffPayloads,
}: UseRosterStaffingListsInput) => {
  const selectedStaffingListState = useSelectedStaffingList({
    selectedDate: format(shiftSummaryHelper.shiftDuration.startTime, 'MM/dd/yyyy'),
    staffingListsResponse,
  });
  const regularShiftsDisabledStaffingLists = staffingListsResponse?.data?.filter(
    (sl) => !sl.signUpableShiftDatesConfiguration?.allowRegularShiftDates,
  );
  const { selectedStaffingList } = selectedStaffingListState;

  const employeeSplits = useMemo(() => {
    return getStaffingListItemEmployeeSplits({
      ...employeeOffPayloads,
      shiftSummaryHelper,
      staffingListItems: selectedStaffingList?.items || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(employeeOffPayloads), shiftSummaryHelper, selectedStaffingList]);

  const availableStaffingListItems = selectedStaffingList?.items || [];

  return {
    ...selectedStaffingListState,
    employeeSplits,
    availableStaffingListItems,
    regularShiftsDisabledStaffingLists,
  };
};
