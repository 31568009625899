import { Box } from '@mui/material';
import { useState } from 'react';
import { RankBadge, CertBadge } from '@stationwise/component-module';
import { Rank, Certification, CertificationActAsRank, ShiftMark } from '@stationwise/share-types';
import { EditQualifications } from './Edit/EditQualifications';
import { InfoCard, InfoCardField } from './InfoCard';

type QualificationsSectionProps = {
  rank: Rank;
  certifications: Certification[];
  certificationActAsRank: CertificationActAsRank | null;
  driversLicenseNumber: string | null;
  driversLicenseClasses: string[];
  shiftMark: ShiftMark[] | null;
};

const certGridRenderer = (certs: Pick<Certification, 'code' | 'color'>[]) => {
  const rows = [];
  for (let i = 0; i < certs.length; i += 3) {
    rows.push(certs.slice(i, i + 3));
  }

  return (
    <Box>
      {rows.map((row, index) => (
        <Box
          key={`${index}-row`}
          display="flex"
          sx={(theme) => ({
            gap: theme.spacing(1),
            alignItems: 'center',
          })}
        >
          {row.map((cert, index) => (
            <CertBadge key={`${index}-${cert.code}`} cert={cert} />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export const QualificationsSection = (props: QualificationsSectionProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldDefs: InfoCardField[][] = [
    [
      {
        label: 'Rank',
        field: {
          value: props.rank.name,
          type: 'badge',
          extra: {
            custom: <RankBadge rank={props.rank} rankField="name" sx={{ alignSelf: 'flex-start' }} />,
          },
        },
      },
      {
        label: 'Certification',
        field: {
          type: 'custom',
          extra: {
            custom: props.certifications.length === 0 ? <Box>None</Box> : certGridRenderer(props.certifications),
          },
        },
      },
    ],
  ];

  if (props.driversLicenseNumber && props.driversLicenseClasses?.length > 0) {
    const driversLicenseSection: InfoCardField[] = [
      {
        label: 'Drivers License',
        field: {
          type: 'custom',
          extra: {
            custom: (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(1),
                  alignItems: 'center',
                  typography: 'bodyMRegular',
                })}
              >
                {props.driversLicenseNumber}
              </Box>
            ),
          },
        },
      },
      {
        label: 'Drivers License Class',
        field: {
          type: 'custom',
          extra: {
            custom: (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(1),
                  flexWrap: 'wrap',
                })}
              >
                {props.driversLicenseClasses.map((licenseClass, index) => (
                  <Box
                    display="flex"
                    key={`${index}-license-class`}
                    sx={(theme) => ({
                      gap: theme.spacing(1),
                      alignItems: 'center',
                    })}
                  >
                    <Box sx={{ typography: 'bodyMRegular' }}>{licenseClass}</Box>
                    {index !== props.driversLicenseClasses.length - 1 && (
                      <Box
                        sx={(theme) => ({
                          color: theme.palette.stationGray[300],
                          typography: 'bodySMedium',
                        })}
                      >
                        |
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            ),
          },
        },
      },
    ];

    const shiftMarkSection: InfoCardField[] = [
      {
        label: 'Shift Marks',
        field: {
          type: 'custom',
          extra: {
            custom: (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  gap: theme.spacing(1),
                  flexWrap: 'wrap',
                })}
              >
                {props.shiftMark && props.shiftMark.length > 0 ? (
                  props.shiftMark.map((mark, index) => (
                    <Box
                      display="flex"
                      key={`${index}-shift-mark`}
                      sx={(theme) => ({
                        gap: theme.spacing(1),
                        alignItems: 'center',
                      })}
                    >
                      <Box sx={{ typography: 'bodyMRegular' }}>{mark.name}</Box>
                      {index !== props.shiftMark!.length - 1 && (
                        <Box
                          sx={(theme) => ({
                            color: theme.palette.stationGray[300],
                            typography: 'bodySMedium',
                          })}
                        >
                          |
                        </Box>
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={() => ({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      typography: 'bodyMRegular',
                    })}
                  >
                    {'-'}
                  </Box>
                )}
              </Box>
            ),
          },
        },
      },
    ];

    fieldDefs.push(driversLicenseSection);
    if (props.shiftMark) {
      fieldDefs.push(shiftMarkSection);
    }
  }

  const handleEditModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <EditQualifications
        showModal={showModal}
        setShowModal={setShowModal}
        certifications={props.certifications}
        driversLicenseNumber={props.driversLicenseNumber}
        driversLicenseClasses={props.driversLicenseClasses}
        rank={props.rank.name}
        shiftMark={props.shiftMark}
      />
      <Box
        width="100%"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: 2,
          border: `1px solid ${theme.palette.stationGray[200]}`,
          p: theme.spacing(4, 3, 3, 3),
          gap: theme.spacing(3),
        })}
      >
        <InfoCard fieldDefs={fieldDefs} title="Qualifications" handleEditModal={handleEditModal} />
      </Box>
    </>
  );
};
