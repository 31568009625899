import { Box } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

const TodayLine = () => {
  const ref = useRef<HTMLElement>(null);
  const windowHeightRef = useRef(0);
  const [height, setHeight] = useState('0vh');

  useEffect(() => {
    const onSetHeight = () => {
      const todayEl = ref.current?.parentElement;
      if (todayEl) {
        const todayRect = todayEl.getBoundingClientRect();
        setHeight(`calc(100vh - ${Math.floor(todayRect.bottom)}px)`);
      }
    };

    const setWindowHeightRef = () => {
      const windowHeight = Math.floor(window.innerHeight);
      if (windowHeight !== windowHeightRef.current) {
        windowHeightRef.current = windowHeight;
        onSetHeight();
      }
    };

    onSetHeight();

    document.addEventListener('resize', setWindowHeightRef);
    return () => document.removeEventListener('resize', setWindowHeightRef);
  }, []);

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        background: theme.palette.stationGray[900],
        position: 'absolute',
        top: '100%',
        left: '50%',
        width: '2px',
        height,
        transform: 'translateX(-50%)',
      })}
    />
  );
};

export const renderToday = (content: ReactNode) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.stationGray[900],
        color: theme.palette.common.white,
        borderRadius: '9999px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        position: 'relative',
        px: 0.25,
      })}
    >
      {content}
    </Box>
  );
};

export const renderMonthDayOverlay = (_date: Date, isToday: boolean) => {
  return isToday ? <TodayLine /> : null;
};
