import { Box } from '@mui/material';
import { addDays, differenceInDays, format, parseISO } from 'date-fns';
import { useState, useEffect, useMemo, FunctionComponent, SVGProps } from 'react';
import {
  Button,
  GridIcon20,
  Server01Icon20,
  theme,
  useLoadedDepartmentInfoContext,
  OrangeCircleWarningIcon20,
  CircleTickCIcon20,
  PayPeriodPicker,
  getDepartmentFeatureFlagValue,
  useFetchPersonalPayroll,
  ErrorPage,
  LottieLogoPageLoader,
  useFetchPayPeriods,
  useLoadedAuthUserContext,
} from '@stationwise/component-module';
import { PayrollCode, SignedState } from '@stationwise/share-types';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { useCompTimeRequest } from '../hooks/useCompTimeRequest';
import { PayrollCards } from './PayrollCards';
import { PayrollCompTimeAccrualModal } from './PayrollCompTimeAccrualModal';
import { PayrollTable } from './PayrollTable';
import { PayrollValidation } from './PayrollValidation';
import { PayrollValidationModal } from './PayrollValidationModal';
import { AvailableCompTime } from './utils';

const PAYROLL_VIEWS = {
  TABLE: 'table',
  CARD: 'card',
};

export const PayrollContent = () => {
  const [activeView, setActiveView] = useState(PAYROLL_VIEWS.CARD);
  const [openCompTimeModal, setOpenCompTimeModal] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [availableCompTimes, setAvailableCompTimes] = useState<AvailableCompTime[]>();
  const { loading, error, handleRequestCompTime } = useCompTimeRequest(setAvailableCompTimes);
  const [payrollCode, setPayrollCode] = useState<PayrollCode | null>(null);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const { state: authUserContext } = useLoadedAuthUserContext();
  const userName = authUserContext.employee.firstName + ' ' + authUserContext.employee.lastName;
  const [showPayrollValidation, setShowPayrollValidation] = useState<boolean>(false);
  const [showPayrollValidationModal, setShowPayrollValidationModal] = useState(false);
  const [leftIcon, setLeftIcon] = useState<FunctionComponent<SVGProps<SVGSVGElement>>>(() => OrangeCircleWarningIcon20);
  const [payrollValidationMessage, setPayrollValidationMessage] = useState('');
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState<number>(0);
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const employeeId = authUserContext.employee.id;

  const {
    selectedPayPeriod,
    setSelectedPayPeriod,
    selectedPeriodIndex,
    setSelectedPeriodIndex,
    isLoading: isPayrollLoading,
    isError,
    payroll,
    startDate,
    endDate,
    refetchCounter,
    setRefetchCounter,
    currentPayPeriods,
  } = useFetchPersonalPayroll();

  const {
    payPeriods,
    isLoaded: arePayPeriodsLoaded,
    currentYear,
    setCurrentYear,
  } = useFetchPayPeriods({ currentPayPeriod: payroll?.currentPayPeriod, setCurrentPayPeriodIndex: setCurrentPeriodIndex });
  const payrollValidationEnabled = getDepartmentFeatureFlagValue(departmentContext, 'PAYROLL_VALIDATION_ENABLED', true);
  useEffect(() => {
    if (!payroll) return;
    if (payrollValidationEnabled && (payroll.signedState === SignedState.SIGNED || payroll.isSignable)) {
      setShowPayrollValidation(true);
      if (payroll.signedState === SignedState.SIGNED) {
        const formattedDate = format(parseISO(payroll.signedAt), "MMM d, yyyy 'at' HH:mm");
        setPayrollValidationMessage('Signed ' + formattedDate);
        setLeftIcon(() => CircleTickCIcon20);
      } else {
        setPayrollValidationMessage("It's time to sign your time card.");
        setLeftIcon(() => OrangeCircleWarningIcon20);
      }
    } else {
      setShowPayrollValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payroll]);

  // Listen for REVIEW type Pusher events to refresh payroll data
  // Triggers refresh on global updates or when current employee's data changes
  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll) {
        setRefetchCounter((c) => c + 1);
      } else if (data.message === PUSHER_UPDATE_MESSAGE) {
        data.employeeIdList.forEach((id) => {
          if (id.toString() === employeeId.toString()) {
            setRefetchCounter((c) => c + 1);
          }
        });
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.REVIEW];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, employeeId, refetchCounter, setRefetchCounter]);

  const days = useMemo(() => {
    if (!startDate || !endDate) return [];
    const daysBetween = differenceInDays(endDate, startDate);
    const days = [];
    for (let i = 0; i <= daysBetween; i++) {
      days.push(addDays(parseISO(startDate.toISOString()), i));
    }
    return days;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PayrollErrorPage = () => (
    <ErrorPage title="No Payroll Details?" subtitle="We are currently calculating your payroll details." />
  );

  if (!arePayPeriodsLoaded || isPayrollLoading || !startDate) {
    return <LottieLogoPageLoader />;
  } else if (isError) {
    return <ErrorPage />;
  } else if (payroll === null || !currentPayPeriods || !currentPayPeriods.length) {
    return <PayrollErrorPage />;
  }

  const openPayrollValidationModal = () => {
    if (payroll.isSigned) return;
    setShowPayrollValidationModal(true);
  };

  const handleReset = () => {
    setSelectedPayPeriod({
      startDate: payroll.currentPayPeriod.startDate,
      endDate: payroll.currentPayPeriod.endDate,
      duration: payroll.currentPayPeriod.duration,
      payPeriodType: payroll.currentPayPeriod.payPeriodType,
      id: payroll.currentPayPeriod.id,
    });
    setSelectedPeriodIndex(currentPeriodIndex);
    setCurrentYear(new Date(parseISO(payroll.currentPayPeriod.startDate)).getFullYear());
  };

  return (
    <>
      <Box
        sx={{
          paddingX: theme.spacing(2),
          marginBottom: theme.spacing(2),
          borderRadius: '0px 0px 12px 12px',
          border: `0px, 0px, 1px, 0px, ${theme.palette.stationGray[100]}`,
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(2.5),
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              typography: 'bodyXXLSemibold',
            })}
          >
            My Payroll
          </Box>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            sx={(theme) => ({
              display: selectedPayPeriod?.startDate === payroll.currentPayPeriod.startDate ? 'none' : 'auto',
              color: theme.palette.stationGray[700],
            })}
            onClick={handleReset}
          >
            {'Back to current period'}
          </Button>
        </Box>
        <Box sx={{ marginTop: theme.spacing(3) }}>
          <PayPeriodPicker
            selectedPayPeriod={selectedPayPeriod}
            setSelectedPayPeriod={setSelectedPayPeriod}
            currentPayPeriod={payroll.currentPayPeriod}
            selectedPeriodIndex={selectedPeriodIndex}
            setSelectedPeriodIndex={setSelectedPeriodIndex}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            payPeriods={payPeriods}
            isLoaded={arePayPeriodsLoaded}
          />
        </Box>
        {showPayrollValidation && (
          <PayrollValidation onClick={openPayrollValidationModal} leftIcon={leftIcon} message={payrollValidationMessage} />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: theme.spacing(1.5),
          px: theme.spacing(2),
        })}
      >
        <Box sx={{ typography: 'bodyLSemibold' }}>Time Card</Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
          })}
        >
          <Box
            onClick={() => {
              activeView === PAYROLL_VIEWS.TABLE ? setActiveView(PAYROLL_VIEWS.CARD) : setActiveView(PAYROLL_VIEWS.TABLE);
            }}
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              p: '5px',
              borderRadius: theme.spacing(5),
              cursor: 'pointer',
            })}
          >
            <Box
              data-cy="time-card-table-view"
              sx={(theme) => ({
                backgroundColor: activeView === PAYROLL_VIEWS.TABLE ? theme.palette.stationGray[900] : theme.palette.common.white,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  stroke: activeView === PAYROLL_VIEWS.TABLE ? theme.palette.common.white : theme.palette.stationGray[900],
                },
              })}
            >
              <GridIcon20 />
            </Box>
            <Box
              data-cy="time-card-card-view"
              sx={(theme) => ({
                backgroundColor: activeView === PAYROLL_VIEWS.CARD ? theme.palette.stationGray[900] : theme.palette.common.white,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  stroke: activeView === PAYROLL_VIEWS.CARD ? theme.palette.common.white : theme.palette.stationGray[900],
                },
              })}
            >
              <Server01Icon20 />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          px: theme.spacing(2),
        })}
      >
        {activeView === PAYROLL_VIEWS.TABLE ? (
          <PayrollTable days={days} payroll={payroll} />
        ) : (
          <PayrollCards
            days={days}
            payroll={payroll}
            setOpenCompTimeModal={setOpenCompTimeModal}
            handleRequestCompTime={handleRequestCompTime}
            setPayrollCode={setPayrollCode}
          />
        )}
        {availableCompTimes && (
          <PayrollCompTimeAccrualModal
            setOpenCompTimeModal={setOpenCompTimeModal}
            openCompTimeModal={openCompTimeModal}
            setOpenResponseModal={setOpenResponseModal}
            openResponseModal={openResponseModal}
            availableCompTimes={availableCompTimes}
            payrollCode={payrollCode}
            loading={loading}
            requestCompTimeError={error}
            setRefetchCounter={setRefetchCounter}
            refetchCounter={refetchCounter}
          />
        )}
      </Box>
      {showPayrollValidationModal && (
        <PayrollValidationModal
          showPayrollValidationModal={showPayrollValidationModal}
          setShowPayrollValidationModal={setShowPayrollValidationModal}
          departmentName={departmentContext.departmentInfo.name}
          userName={userName}
          days={days}
          payroll={payroll}
          startDate={selectedPayPeriod?.startDate}
          endDate={selectedPayPeriod?.endDate}
          payPeriodId={selectedPayPeriod?.id}
          setRefetchCounter={setRefetchCounter}
          refetchCounter={refetchCounter}
        />
      )}
    </>
  );
};
