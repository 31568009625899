import { max, min } from 'date-fns';
import { RosterDataSource } from '@stationwise/share-types';
import {
  getDepartmentPayCodes,
  getPlannedAdministrationPosition,
  makeAssignmentSplit,
  setEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { SplitProps } from '../types';
import { setSplits } from './general';

export const cancelTimeOffSplit = (props: SplitProps) => {
  const { departmentInfo } = props.shiftSummaryHelper;
  if (props.split.reference.type === 'TIME_OFF_REQUEST') {
    const timeOffId = props.split.reference.id;
    const newSplits = [...props.splits];
    if (props.split.backup.reference.type === 'ASSIGNMENT' && timeOffId <= 0) {
      const newAssignmentSplit = { ...props.split, reference: props.split.backup.reference };
      newSplits[props.index] = { ...newAssignmentSplit, backup: newAssignmentSplit };
    } else if (!props.isAdministration && !props.isPlannedEmployee) {
      newSplits.splice(props.index, 1);
    } else {
      const plannedAdministrationPosition = getPlannedAdministrationPosition(props.shiftSummaryHelper, props.employee);

      let startDateTime = props.split.startDateTime;
      let endDateTime = props.split.endDateTime;
      if (props.isAdministration && plannedAdministrationPosition.position) {
        startDateTime = max([startDateTime, new Date(plannedAdministrationPosition.position.nonShiftEmployee.startDateTime)]);
        endDateTime = min([endDateTime, new Date(plannedAdministrationPosition.position.nonShiftEmployee.endDateTime)]);
      }

      const newEmployee = setEmployeeActiveId({
        ...props.employee,
        dataSource: props.isAdministration ? RosterDataSource.ADMINISTRATION : RosterDataSource.FLOATER,
        startDateTime,
        endDateTime,
        payCodes: getDepartmentPayCodes(departmentInfo, [props.employee.defaults.regularAssignmentPayCode]),
        detailCodes: [],
        trade: null,
      });
      const newEmployeeSplit = makeAssignmentSplit(
        props.shiftSummaryHelper,
        props.isAdministration ? plannedAdministrationPosition.station : null,
        props.isAdministration ? plannedAdministrationPosition.apparatus : null,
        props.isAdministration ? plannedAdministrationPosition.position : null,
        newEmployee,
      );
      if (newEmployeeSplit.startDateTime <= newEmployeeSplit.endDateTime) {
        newSplits[props.index] = { ...newEmployeeSplit, backup: newEmployeeSplit };
      } else {
        // Cancel non-shift time off for strike team assignment outside non-shift duration by removing the split.
        newSplits.splice(props.index, 1);
      }
    }

    setSplits(props, newSplits);
    if (timeOffId > 0) {
      props.setCancelTimeOffPayloads((p) => [...p, { timeOffId }]);
    }
  }
};

export const createTimeOffSplit = (props: SplitProps) => {
  const position = props.split.backup.reference.type === 'ASSIGNMENT' ? props.split.backup.reference.position : null;
  const newSplits = [...props.splits];
  newSplits[props.index] = {
    ...props.split,
    reference: {
      type: 'TIME_OFF_REQUEST',
      id: 0,
      payCodeId: 0,
      positionId: Number(position?.id) || null,
    },
  };
  setSplits(props, newSplits);
};

export const updateTimeOffSplitReason = (props: SplitProps, payCodeId: number) => {
  if (props.split.reference.type === 'TIME_OFF_REQUEST') {
    const newSplits = [...props.splits];
    newSplits[props.index] = {
      ...props.split,
      reference: { ...props.split.reference, payCodeId },
    };
    setSplits(props, newSplits);
  }
};
