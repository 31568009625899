import { captureException } from '@sentry/react';
import { parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, AxiosResponse, isAxiosError } from '@stationwise/share-api';
import {
  ShiftPlanAPIData,
  ShiftPlanChipType,
  ShiftPlanAction,
  ShiftPlanStruct,
  SaveDraftShiftPlanResponse,
} from '@stationwise/share-types';
import { makeDateInterval } from '@stationwise/share-utils';
import { initializeAssignments, getAssignmentGroups } from '../helpers/readAssignments';
import { initializeStations } from '../helpers/readStations';

interface UseShiftPlanProps {
  data: ShiftPlanAPIData;
  forceRefetch: (battalionId: string) => void;
}

export const useShiftPlan = (props: UseShiftPlanProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const shiftDuration = makeDateInterval(parseISO(props.data.date), departmentInfoState.departmentInfo.shiftStart, 24);
  const [initialStations] = useState(() => initializeStations(props.data.stations));
  const [stations, setStations] = useState(initialStations);
  const [initialAssignments, setInitialAssignments] = useState(() => {
    return initializeAssignments(departmentInfoState.departmentInfo, props.data.assignments);
  });
  const [assignments, setAssignments] = useState(initialAssignments);
  const [selectedChipType, setSelectedChipType] = useState(ShiftPlanChipType.ROSTER_EDITOR);
  const [selectedAction, setSelectedAction] = useState<ShiftPlanAction | null>(null);
  const [selectedStruct, setSelectedStruct] = useState<ShiftPlanStruct>({});
  const [selectedPersonnelStruct, setSelectedPersonnelStruct] = useState<ShiftPlanStruct>({});
  const [isSaving, setIsSaving] = useState(false);

  const userHasMadeChanges = stations !== initialStations || assignments !== initialAssignments;

  const assignmentGroups = useMemo(() => getAssignmentGroups(assignments), [assignments]);

  const resetValues = () => {
    setStations(initialStations);
    setAssignments(initialAssignments);
  };

  const cancelSelectedAction = () => !isSaving && setSelectedAction(null);

  const saveDraft = async (
    payload: unknown,
    onSuccess?: (data: AxiosResponse<SaveDraftShiftPlanResponse>) => void,
    onError?: (error: unknown) => unknown,
  ) => {
    try {
      setIsSaving(true);
      const response = await client.post('/shift/shift-plan/', payload);
      // Expect the caller to setIsSaving(false). We often want to keep it open while a dialog is animating away.
      onSuccess?.(response);
      if (response.data.assignments) {
        const newAssignments = initializeAssignments(departmentInfoState.departmentInfo, response.data.assignments);
        setInitialAssignments(newAssignments);
        setAssignments(newAssignments);
      }
    } catch (error) {
      !isAxiosError(error) && captureException(error);
      if (!onError || onError(error)) {
        setIsSaving(false);

        let message = 'There was an issue saving your changes. Please try again.';
        if (isAxiosError(error)) {
          message = error.response?.data?.nonFieldErrors?.[0] || message;
        }

        SnackbarService.notify({ content: message, severity: 'error', duration: 5000 });
      }
    }
  };

  return {
    shiftDuration,
    battalion: props.data.battalion,
    stations,
    setStations,
    setInitialAssignments,
    assignments,
    setAssignments,
    selectedChipType,
    setSelectedChipType,
    selectedAction,
    setSelectedAction,
    cancelSelectedAction,
    selectedStruct,
    setSelectedStruct,
    selectedPersonnelStruct,
    setSelectedPersonnelStruct,
    userHasMadeChanges,
    assignmentGroups,
    forceRefetch: props.forceRefetch,
    isSaving,
    setIsSaving,
    saveDraft,
    resetValues,
  };
};

export type UseShiftPlanReturnType = ReturnType<typeof useShiftPlan>;
