import { Dialog, DialogTitle, DialogContent, DialogActions, Box, CircularProgress, Checkbox } from '@mui/material';
import { captureException } from '@sentry/react';
import debounce from 'lodash/debounce';
import { useEffect, useState, useMemo } from 'react';
import { SearchInput, Select, Button, useLoadedDepartmentInfoContext, RankBadge } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ManagerStatusEmployee, Rank } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface AddEmployeeModalProps {
  open: boolean;
  onClose: () => void;
  existingEmployees: ManagerStatusEmployee[];
  onSave: (employees: ManagerStatusEmployee[]) => void;
  selectedRanks: Rank[];
}

export const AddEmployeeModal = ({ open, onClose, existingEmployees, onSave, selectedRanks }: AddEmployeeModalProps) => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRank, setSelectedRank] = useState<string>('');
  const [employees, setEmployees] = useState<ManagerStatusEmployee[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedNewEmployees, setSelectedNewEmployees] = useState<ManagerStatusEmployee[]>([]);

  const fetchEmployees = useMemo(
    () =>
      debounce(async (search: string) => {
        setLoading(true);
        try {
          const params = new URLSearchParams();
          if (search) params.append('search', search);
          const response = await client.get('/employee/employees/', { params });
          setEmployees(response.data);
        } catch (error) {
          captureException(error);
        } finally {
          setLoading(false);
        }
      }, 300),
    [setLoading, setEmployees],
  );

  useEffect(() => {
    fetchEmployees(searchTerm);
  }, [searchTerm, fetchEmployees]);

  const filteredEmployees = useMemo(() => {
    return employees.filter((employee: ManagerStatusEmployee) => {
      const hasSelectedRank = selectedRanks.some((rank) => rank.id === employee.rank.id);
      if (hasSelectedRank) return false;

      if (selectedRank && employee.rank?.name !== selectedRank) {
        return false;
      }

      const isExisting = existingEmployees?.some((e) => e.id === employee.id);
      return !isExisting;
    });
  }, [employees, selectedRank, existingEmployees, selectedRanks]);

  useEffect(() => {
    if (open) {
      setSelectedRank('');
      setSelectedNewEmployees([]);
      setSearchTerm('');
    }
  }, [open]);

  const handleEmployeeSelect = (employee: ManagerStatusEmployee) => {
    setSelectedNewEmployees((prev) => {
      const isAlreadySelected = prev.some((e) => e.id === employee.id);
      if (isAlreadySelected) {
        return prev.filter((e) => e.id !== employee.id);
      }
      return [...prev, employee];
    });
  };

  const handleSave = () => {
    const combinedEmployees = [...existingEmployees, ...selectedNewEmployees];
    onSave(combinedEmployees);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setSelectedRank('');
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Employees</DialogTitle>
      <DialogContent sx={{ height: '600px' }}>
        <Box sx={{ mt: 2, display: 'flex', gap: 2, mb: 3 }}>
          <Box sx={{ width: '300px' }}>
            <SearchInput value={searchTerm} setValue={setSearchTerm} placeHolder="Search by name or email" />
          </Box>
          <Select
            selected={selectedRank}
            onChange={setSelectedRank}
            items={[
              { value: '', label: 'All Ranks' },
              ...departmentInfo.ranks
                .filter((rank) => !selectedRanks.some((selectedRank) => selectedRank.id === rank.id))
                .map((rank: Rank) => ({
                  value: rank.name,
                  label: rank.name,
                })),
            ]}
            placeholder="All Ranks"
          />
        </Box>

        <Box
          sx={{
            height: 'calc(100% - 100px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.2)',
              borderRadius: '4px',
            },
          }}
        >
          {/* Show selected employees at the top */}
          {selectedNewEmployees.length > 0 && (
            <>
              <Box sx={{ typography: 'subtitle2', color: 'text.secondary', px: 2, py: 1 }}>Selected Employees</Box>
              {selectedNewEmployees.map((employee) => (
                <Box
                  key={employee.id}
                  onClick={() => handleEmployeeSelect(employee)}
                  sx={{
                    py: 2,
                    px: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <RankBadge rank={employee.rank} />
                    <Box>
                      <Box sx={{ typography: 'subtitle1' }}>{employee.name}</Box>
                      <Box sx={{ typography: 'body2', color: 'text.secondary' }}>{employee.rank.name}</Box>
                    </Box>
                  </Box>
                  <Checkbox
                    checked={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEmployeeSelect(employee);
                    }}
                    sx={{ ml: 2 }}
                  />
                </Box>
              ))}
              <Box sx={{ borderBottom: '2px solid', borderColor: 'divider', my: 2 }} />
            </>
          )}

          {/* Show available employees */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <>
              <Box sx={{ typography: 'subtitle2', color: 'text.secondary', px: 2, py: 1 }}>Available Employees</Box>
              {filteredEmployees
                .filter(
                  (employee) =>
                    !existingEmployees?.some((e) => e.id === employee.id) &&
                    !selectedNewEmployees.some((e) => e.id === employee.id),
                )
                .map((employee) => (
                  <Box
                    data-cy={`add-employee-${makeTestIdentifier(employee.name)}`}
                    key={employee.id}
                    onClick={() => handleEmployeeSelect(employee)}
                    sx={{
                      py: 2,
                      px: 2,
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <RankBadge rank={employee.rank} />
                      <Box>
                        <Box sx={{ typography: 'subtitle1' }}>{employee.name}</Box>
                        <Box sx={{ typography: 'body2', color: 'text.secondary' }}>{employee.rank.name}</Box>
                      </Box>
                    </Box>
                    <Checkbox
                      checked={false}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEmployeeSelect(employee);
                      }}
                      sx={{ ml: 2 }}
                    />
                  </Box>
                ))}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2, gap: 2, justifyContent: 'center' }}>
        <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={onClose}>
          Cancel
        </Button>
        <Button data-cy="add-selected-button" variant="contained" size="large" sx={{ width: '216px' }} onClick={handleSave}>
          Add Selected
        </Button>
      </DialogActions>
    </Dialog>
  );
};
