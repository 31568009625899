import { ReactComponent as AddCircleSymbol } from '@material-symbols/svg-400/outlined/add_circle.svg';
import { ReactComponent as DoNotDisturbOnSymbol } from '@material-symbols/svg-400/outlined/do_not_disturb_on.svg';
import { ReactComponent as EditSymbol } from '@material-symbols/svg-400/outlined/edit.svg';
import { ReactComponent as InfoSymbol } from '@material-symbols/svg-400/outlined/info.svg';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import {
  RankBadge,
  CardCertBadges,
  TeamBadge,
  SvgIcon,
  getTooltipProps,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { RosterPosition, ShiftPlanAction, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { getDisplayedEmployeeCertifications } from '../../../helpers/readCertifications';
import { TeamPatternDays } from '../../Calendar';
import { BorderedBoxes } from '../../Grid';

interface PersonRowProps {
  person: ShiftPlanCandidate | ShiftPlanAssignment;
  team?: ShiftPlanAssignment['employee']['team'];
  position?: RosterPosition;
  month: Date;
  setSelectedAction: Dispatch<SetStateAction<ShiftPlanAction | null>>;
  setSelectedPerson: Dispatch<SetStateAction<ShiftPlanCandidate | ShiftPlanAssignment | null>>;
  setSelectedPersonTeam: Dispatch<SetStateAction<ShiftPlanAssignment['employee']['team'] | null>>;
  setSelectedPersonPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

export const PersonRow = memo(({ person, ...props }: PersonRowProps) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const candidate = 'employee' in person ? null : (person as ShiftPlanCandidate);
  const assignment = 'employee' in person ? (person as ShiftPlanAssignment) : null;
  const employee = 'employee' in person ? person.employee : person;
  const team = assignment?.employee.team || props.team;
  const certifications = getDisplayedEmployeeCertifications(departmentInfoState.departmentInfo, props.position, employee);

  const teamAssignments = (() => {
    if (assignment) {
      return [assignment];
    } else if (candidate && team) {
      return candidate.assignments.filter((a) => a.employee.team.id === team.id);
    } else {
      return [];
    }
  })();

  let button1 = (
    <IconButton
      sx={{ width: '39px', height: '39px', p: 0 }}
      disabled={assignment?.wasPublished}
      onClick={(event) => {
        props.setSelectedAction(candidate ? ShiftPlanAction.ADD_ASSIGNMENT : ShiftPlanAction.REMOVE_ASSIGNMENT);
        props.setSelectedPerson(person);
        props.setSelectedPersonTeam(team || null);
        candidate && props.setSelectedPersonPopoverAnchorEl(event.currentTarget);
      }}
    >
      <SvgIcon component={candidate ? AddCircleSymbol : DoNotDisturbOnSymbol} />
    </IconButton>
  );
  if (assignment?.wasPublished) {
    button1 = (
      <Tooltip
        arrow
        title="Published assignments cannot be removed, only deactivated"
        placement="bottom"
        slotProps={getTooltipProps()}
      >
        <Box component="span" sx={{ display: 'inline-flex' }}>
          {button1}
        </Box>
      </Tooltip>
    );
  }

  const button2 = (
    <IconButton
      sx={{ width: '39px', height: '39px', p: 0 }}
      onClick={(event) => {
        props.setSelectedAction(candidate ? ShiftPlanAction.VIEW_CANDIDATE : ShiftPlanAction.EDIT_ASSIGNMENT);
        props.setSelectedPerson(person);
        props.setSelectedPersonTeam(team || null);
        props.setSelectedPersonPopoverAnchorEl(event.currentTarget);
      }}
    >
      <SvgIcon component={candidate ? InfoSymbol : EditSymbol} />
    </IconButton>
  );

  return (
    <BorderedBoxes sx={{ height: assignment && certifications.length > 0 ? '64px' : '40px' }}>
      <Box sx={{ width: '39px' }}>{button1}</Box>
      <Box sx={{ width: '40px' }}>{button2}</Box>
      <Box sx={{ flex: 7, minWidth: '240px', px: 1.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <RankBadge rank={employee.rank} />
            <Box sx={{ typography: 'bodySMedium', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {shortenEmployeeName(employee.name)}
            </Box>
            <Box sx={{ flex: 1, minWidth: '1px', display: 'flex', justifyContent: 'flex-end' }}>
              {candidate && <CardCertBadges certs={certifications} />}
              {assignment && (
                <Box sx={{ color: theme.palette.text.secondary, typography: 'bodyXSRegular' }}>
                  {formatShiftDuration({ startTime: assignment.startDateTime, endTime: assignment.endDateTime })}
                </Box>
              )}
            </Box>
          </Box>
          {assignment && certifications.length > 0 && (
            <Box sx={{ display: 'flex' }}>
              <CardCertBadges certs={certifications} />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ flex: 3, minWidth: '1px', px: 1.5 }}>
        {team && <TeamBadge team={team} />}
        {!team && <Box sx={{ typography: 'bodySMedium' }}>Unassigned</Box>}
      </Box>
      <BorderedBoxes sx={{ border: 0, width: '700px', minWidth: '60%' }}>
        <TeamPatternDays assignments={teamAssignments} month={props.month} />
      </BorderedBoxes>
    </BorderedBoxes>
  );
});

PersonRow.displayName = 'Memo(PersonRow)';
