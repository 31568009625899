import { Autocomplete, Box, Dialog, FormControl, FormHelperText, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useState } from 'react';
import { Button, SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { PayCode, PayCodeComplete, BankedHours, TimeOffAccruals } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface PayCodeModalProps {
  payCodes: string[];
  onSaveSuccess: () => void;
  selectedPayCode?: PayCodeComplete;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
  existingPayCodes: string[];
  extendedPayCodes: PayCode[];
}

const bankedHoursInput: BankedHours = {
  spendablePayCode: null,
  spendRatio: 1.0,
  convertibleTo: null,
  convertRatio: 1.5,
};
const timeOffAccrualsInput: TimeOffAccruals = {
  name: '',
  defaultAccrualAmount: 1e308,
  maxAccrualLimit: 1e308,
  isDefaultUnlimited: true,
  isMaxUnlimited: true,
};

const newPayCode: PayCodeComplete = {
  id: -1,
  name: '',
  code: '',
  payCodeType: '',
  extCode: '',
  bankedHours: bankedHoursInput,
  timeOffAccrual: timeOffAccrualsInput,
};

export const PayCodeModal = ({
  existingPayCodes,
  payCodes,
  onSaveSuccess,
  selectedPayCode,
  extendedPayCodes,
  setShowModal,
  showModal,
}: PayCodeModalProps) => {
  const { dispatch } = useLoadedDepartmentInfoContext();

  const title = selectedPayCode ? 'Edit Pay Code Details' : 'Create New Pay Code';
  const [payCode, setPayCode] = useState<PayCodeComplete>(selectedPayCode ? selectedPayCode : newPayCode);
  const [error, setError] = useState({ duplicateCode: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hiddenParts = false;
  const disabled =
    !payCode.name ||
    !payCode.code ||
    !payCode.payCodeType ||
    (payCode.payCodeType === 'ACCRUALS_OR_BANKED_HOURS' && !payCode.bankedHours?.spendRatio) ||
    Object.values(error).some((e) => e) ||
    isSubmitting;

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setPayCode(newPayCode);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    if (selectedPayCode) {
      //  UPDATE
      try {
        const response = await client.patch(`/payroll/pay-code/${payCode.id}/`, {
          ...payCode,
          bankedHoursInput: payCode.bankedHours,
          timeOffAccrualInput: payCode.timeOffAccrual,
        });
        SnackbarService.notify({
          content: `Successfully updated pay code: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save pay code: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    } else {
      // CREATE
      try {
        const response = await client.post('/payroll/pay-code/', {
          ...payCode,
          bankedHoursInput: payCode.bankedHours,
          timeOffAccrualInput: payCode.timeOffAccrual,
        });
        SnackbarService.notify({
          content: `Successfully added pay code: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save new pay code: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };
  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
            '.MuiFormControl-root': {
              mb: 4,
              '.MuiInputLabel-root': {
                top: '-5px',
              },
            },
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              mb: 4,
            }}
          >
            {title}
          </Box>

          <TextField
            label="Name"
            onChange={(event) => {
              setPayCode({ ...payCode, name: event.currentTarget.value });
            }}
            required
            value={payCode.name}
            data-cy="name"
          />

          <TextField
            error={error.duplicateCode}
            label="Code"
            helperText={error.duplicateCode ? 'This code already exists.' : ''}
            onChange={(event) => {
              setPayCode({ ...payCode, code: event.currentTarget.value });
              if (existingPayCodes.map((code) => code.toUpperCase()).includes(event.currentTarget.value.toUpperCase())) {
                setError({ ...error, duplicateCode: true });
              } else if (error.duplicateCode) {
                setError({ ...error, duplicateCode: false });
              }
            }}
            required
            value={payCode.code}
            data-cy="code"
          />
          <TextField
            label="Ext Code"
            onChange={(event) => {
              setPayCode({ ...payCode, extCode: event.currentTarget.value });
            }}
            value={payCode.extCode}
            data-cy="ext-code"
          />
          {payCodes?.length > 0 && (
            <FormControl>
              <Autocomplete
                value={payCode.payCodeType}
                options={payCodes}
                onChange={(_event, value) =>
                  setPayCode({
                    ...payCode,
                    payCodeType: value ?? '',
                  })
                }
                data-cy="pay-code-type"
                renderOption={(itemProps, option) => (
                  <li {...itemProps} data-cy={`pay-code-type-option-${makeTestIdentifier(option)}`}>
                    {option}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Pay Code Type" placeholder={'Select Pay Code Type'} />}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>Please select related pay code type</FormHelperText>
            </FormControl>
          )}
          {hiddenParts && payCode.payCodeType === 'TIME_OFF' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '.MuiFormControl-root': {
                  mb: 0,
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payCode.timeOffAccrual?.isMaxUnlimited}
                    onChange={(event) => {
                      setPayCode({
                        ...payCode,
                        timeOffAccrual: {
                          ...payCode.timeOffAccrual,
                          isMaxUnlimited: event.target.checked,
                          maxAccrualLimit: event.target.checked ? 1e308 : payCode.timeOffAccrual?.maxAccrualLimit,
                        },
                      });
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: (theme) => theme.palette.common.black,
                      },
                    }}
                  />
                }
                label="Unlimited max accrual"
                sx={{ mb: 4 }} // Remove top margin
              />
              {!payCode.timeOffAccrual?.isMaxUnlimited && (
                <TextField
                  label="Max Accrual Limit"
                  helperText="Maximum amount that can be reached per employee for this pay code"
                  onChange={(event) => {
                    setPayCode({
                      ...payCode,
                      timeOffAccrual: {
                        ...payCode.timeOffAccrual,
                        maxAccrualLimit: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                      },
                    });
                  }}
                  type="number"
                  value={payCode.timeOffAccrual?.maxAccrualLimit === null ? undefined : payCode.timeOffAccrual?.maxAccrualLimit}
                  data-cy="max-accrual-limit"
                />
              )}
            </Box>
          )}

          {existingPayCodes?.length > 0 && payCode.payCodeType === 'OVERTIME' && (
            <FormControl>
              <Autocomplete
                value={
                  payCode.bankedHours?.convertibleToObject
                    ? payCode.bankedHours?.convertibleToObject.code
                    : payCode.bankedHours?.convertibleTo
                      ? extendedPayCodes.find((code) => code.id === payCode.bankedHours?.convertibleTo)?.code
                      : ''
                }
                options={existingPayCodes}
                onChange={(_event, value) => {
                  const foundPayCode = extendedPayCodes.find((exPayCode) => exPayCode.code === value);
                  setPayCode({
                    ...payCode,
                    bankedHours: {
                      ...payCode.bankedHours,
                      convertibleTo: foundPayCode ? Number(foundPayCode.id) : null,
                      convertibleToObject: foundPayCode ? foundPayCode : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Convertible to Pay Code" placeholder={'Select Convertible to Pay Code'} />
                )}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>Please select related convertible to pay code</FormHelperText>
            </FormControl>
          )}

          {existingPayCodes?.length > 0 && payCode.payCodeType === 'ACCRUALS_OR_BANKED_HOURS' && (
            <FormControl>
              <Autocomplete
                value={
                  payCode.bankedHours?.spendablePayCodeObject
                    ? payCode.bankedHours?.spendablePayCodeObject.code
                    : payCode.bankedHours?.spendablePayCode
                      ? extendedPayCodes.find((code) => code.id === payCode.bankedHours?.spendablePayCode)?.code
                      : ''
                }
                options={existingPayCodes}
                onChange={(_event, value) => {
                  const foundPayCode = extendedPayCodes.find((exPayCode) => exPayCode.code === value);
                  setPayCode({
                    ...payCode,
                    bankedHours: {
                      ...payCode.bankedHours,
                      spendablePayCode: foundPayCode ? Number(foundPayCode.id) : null,
                      spendablePayCodeObject: foundPayCode ? foundPayCode : null,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Spendable Pay Code" placeholder={'Select Spendable Pay Code'} />
                )}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                    '.MuiInputLabel-root:not(.Mui-focused)': {
                      top: 0,
                    },
                  },
                }}
              />
              <FormHelperText>Please select related spendable pay code</FormHelperText>
            </FormControl>
          )}
          {payCode.payCodeType === 'ACCRUALS_OR_BANKED_HOURS' && (
            <TextField
              label="Spent Ratio"
              helperText="The ratio of the spendable pay code to the current pay code"
              onChange={(event) => {
                setPayCode({
                  ...payCode,
                  bankedHours: {
                    ...payCode.bankedHours,
                    spendRatio: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                  },
                });
              }}
              type="number"
              value={payCode.bankedHours?.spendRatio}
              slotProps={{ htmlInput: { step: '0.25', min: 0 } }}
            />
          )}
          {payCode.payCodeType === 'OVERTIME' && (
            <TextField
              label="Convert Ratio"
              helperText="The ratio of conversion from the current pay code to the spendable pay code"
              onChange={(event) => {
                setPayCode({
                  ...payCode,
                  bankedHours: {
                    ...payCode.bankedHours,
                    convertRatio: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                  },
                });
              }}
              slotProps={{ htmlInput: { step: '0.25', min: 0 } }}
              type="number"
              value={payCode.bankedHours?.convertRatio}
            />
          )}

          {hiddenParts && payCode.payCodeType === 'TIME_OFF' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '.MuiFormControl-root': {
                  mb: 0,
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payCode.timeOffAccrual?.isDefaultUnlimited}
                    onChange={(event) => {
                      setPayCode({
                        ...payCode,
                        timeOffAccrual: {
                          ...payCode.timeOffAccrual,
                          isDefaultUnlimited: event.target.checked,
                          defaultAccrualAmount: event.target.checked ? 1e308 : payCode.timeOffAccrual?.defaultAccrualAmount,
                        },
                      });
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: (theme) => theme.palette.common.black,
                      },
                    }}
                  />
                }
                label="Unlimited default accrual"
                sx={{ mb: 4 }}
              />
              {!payCode.timeOffAccrual?.isDefaultUnlimited && (
                <TextField
                  label="Default Accrual Amount"
                  helperText="Default Accrual Amount per employee for this pay code"
                  onChange={(event) => {
                    setPayCode({
                      ...payCode,
                      timeOffAccrual: {
                        ...payCode.timeOffAccrual,
                        defaultAccrualAmount: Number(Number(event.currentTarget.value) < 0 ? 0 : event.currentTarget.value),
                      },
                    });
                  }}
                  type="number"
                  value={
                    payCode.timeOffAccrual?.defaultAccrualAmount === null
                      ? undefined
                      : payCode.timeOffAccrual?.defaultAccrualAmount
                  }
                />
              )}
            </Box>
          )}

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
              disabled={disabled}
              loading={isSubmitting}
              data-cy="save-pay-code-button"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
