import { Box, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import { Button, useDepartmentInfoContext } from '@stationwise/component-module';
import { Manager, MANAGER_NAMES } from '@stationwise/share-types';
import { ManagerAutocomplete } from './ManagerAutoComplete';
import { usePatchRequest } from './PatchRequestProvider';
interface EditManagerInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  managerOne: Manager;
  managerTwo: Manager;
  managerThree: Manager;
}

export const EditManagerInfo = ({ showModal, setShowModal, managerOne, managerTwo, managerThree }: EditManagerInfoProps) => {
  const [selectedManagerOne, setSelectedManagerOne] = useState(managerOne);
  const [selectedManagerTwo, setSelectedManagerTwo] = useState(managerTwo);
  const [selectedManagerThree, setSelectedManagerThree] = useState(managerThree);

  const { state: department } = useDepartmentInfoContext();

  const { handlePatchRequest } = usePatchRequest();

  const managerOptions = department.departmentInfo?.managers ?? [];

  const handleSave = () => {
    const data = {
      managerOne: selectedManagerOne?.id?.toString() ?? '',
      managerTwo: selectedManagerTwo?.id?.toString() ?? '',
      managerThree: selectedManagerThree?.id?.toString() ?? '',
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedManagerOne(managerOne);
    setSelectedManagerTwo(managerTwo);
    setSelectedManagerThree(managerThree);
  };

  const isDisabled = useMemo(() => {
    const isChangedManagerOne = (selectedManagerOne?.name ?? '') === (managerOne?.name ?? '');
    const isChangedManagerTwo = (selectedManagerTwo?.name ?? '') === (managerTwo?.name ?? '');
    const isChangedManagerThree = (selectedManagerThree?.name ?? '') === (managerThree?.name ?? '');

    return isChangedManagerOne && isChangedManagerTwo && isChangedManagerThree;
  }, [selectedManagerOne, selectedManagerTwo, selectedManagerThree, managerOne, managerTwo, managerThree]);

  return (
    <Dialog open={showModal} onClose={handleClose} data-cy="edit-managers-modal">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Managers
          </Box>

          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_ONE}
            manager={selectedManagerOne}
            setManager={setSelectedManagerOne}
            options={managerOptions}
          />
          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_TWO}
            manager={selectedManagerTwo}
            setManager={setSelectedManagerTwo}
            options={managerOptions}
          />
          <ManagerAutocomplete
            title={MANAGER_NAMES.MANAGER_THREE}
            manager={selectedManagerThree}
            setManager={setSelectedManagerThree}
            options={managerOptions}
          />

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant="contained" size="large" sx={{ width: '216px' }} onClick={() => handleSave()} disabled={isDisabled}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
