import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { Button, DialogXButton } from '@stationwise/component-module';
import { TimePreferenceChoice } from '@stationwise/share-types';

interface TimePreferenceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (selectedOption?: string) => void;
}
export const TimePreferenceDialog = ({ isOpen, onClose, onSubmit }: TimePreferenceDialogProps) => {
  const options: { value: string; label: string }[] = [
    { value: TimePreferenceChoice.ANY, label: 'No preference' },
    { value: TimePreferenceChoice.ONLY_FULL, label: 'Only full shifts' },
    { value: TimePreferenceChoice.ONLY_PARTIAL, label: 'Only partial shifts' },
  ];

  const [selectedOption, setSelectedOption] = useState<string>(options[0].value);

  const onSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ typography: 'bodyXLSemibold', textAlign: 'center' }}>Select your time preference</Box>
          <DialogXButton onClose={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box>
          {options.map((option) => (
            <Box key={option.value}>
              <FormControlLabel
                control={<Checkbox checked={selectedOption === option.value} onChange={() => onSelectOption(option.value)} />}
                label={option.label}
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSubmit(selectedOption)} variant="contained" size="large" sx={{ width: '100%' }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
