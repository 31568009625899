import { Dispatch, SetStateAction } from 'react';
import {
  Certification,
  DetailCode,
  PayCodeComplete,
  RankComplete,
  CapabilityPermission,
  TimeOffAccruals,
} from '@stationwise/share-types';

export interface FilterOptions {
  [key: string]: string[];
}

export enum ElementTypes {
  RANK = 'Rank',
  CERTIFICATION = 'Certification',
  PAY_CODE = 'Pay Code',
  DETAIL_CODE = 'Detail Code',
  CAPABILITY_PERMISSION = 'Permission',
  TIME_OFF_ACCRUAL = 'Time Off Accrual',
}

export type DepartmentSettingsTabProps =
  | RankSettingsTabProps
  | CertificationSettingsTabProps
  | DetailCodeSettingsTabProps
  | PayCodeSettingsTabProps
  | CapabilityPermissionSettingsTabProps
  | TimeOffAccrualsSettingsTabProps;

export interface DepartmentSettingsTabCommonProps {
  createUpdateUrl: string;
  isFilterLowerCase?: boolean;
  exportUrl?: string;
  hasFilters?: boolean;
}

export interface RankSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.RANK;
  elements: RankComplete[];
  setElements: Dispatch<SetStateAction<RankComplete[]>>;
  selectedElement: RankComplete | undefined;
  setSelectedElement: Dispatch<SetStateAction<RankComplete | undefined>>;
}

export interface DetailCodeSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.DETAIL_CODE;
  elements: DetailCode[];
  setElements: Dispatch<SetStateAction<DetailCode[]>>;
  selectedElement: DetailCode | undefined;
  setSelectedElement: Dispatch<SetStateAction<DetailCode | undefined>>;
}

export interface PayCodeSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.PAY_CODE;
  elements: PayCodeComplete[];
  setElements: Dispatch<SetStateAction<PayCodeComplete[]>>;
  selectedElement: PayCodeComplete | undefined;
  setSelectedElement: Dispatch<SetStateAction<PayCodeComplete | undefined>>;
}

export interface CertificationSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.CERTIFICATION;
  elements: Certification[];
  setElements: Dispatch<SetStateAction<Certification[]>>;
  selectedElement: Certification | undefined;
  setSelectedElement: Dispatch<SetStateAction<Certification | undefined>>;
}

export interface CapabilityPermissionSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.CAPABILITY_PERMISSION;
  elements: CapabilityPermission[];
  setElements: Dispatch<SetStateAction<CapabilityPermission[]>>;
  selectedElement: CapabilityPermission | undefined;
  setSelectedElement: Dispatch<SetStateAction<CapabilityPermission | undefined>>;
}

export interface TimeOffAccrualsSettingsTabProps extends DepartmentSettingsTabCommonProps {
  elementType: ElementTypes.TIME_OFF_ACCRUAL;
  elements: TimeOffAccruals[];
  setElements: Dispatch<SetStateAction<TimeOffAccruals[]>>;
  selectedElement: TimeOffAccruals | undefined;
  setSelectedElement: Dispatch<SetStateAction<TimeOffAccruals | undefined>>;
}
