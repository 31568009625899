import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { Button, NoShifts } from '@stationwise/component-module';

interface ButtonAPTProps {
  handleEmptyDayAdditionalPaidTimeClick?: (shift: EventInput | EventImpl) => void;
  selectedDate: Date;
  todayEvents: EventInput[];
  viewOnly?: boolean;
}

export const ButtonAPT = ({
  todayEvents,
  viewOnly = false,
  handleEmptyDayAdditionalPaidTimeClick,
  selectedDate,
}: ButtonAPTProps) => {
  const isRegular = todayEvents.some((event) => event.title === 'REGULAR');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        transform: 'translate(0, 100%)',
      }}
    >
      {todayEvents.length === 0 && (
        <>
          <NoShifts />
          <Box sx={(theme) => ({ my: theme.spacing(2), typography: 'h6' })}>No shifts or events on this day</Box>
        </>
      )}

      {!isRegular && !viewOnly && !(selectedDate > new Date()) && (
        <Button
          data-cy="apt-request-button"
          variant="contained"
          onClick={() =>
            handleEmptyDayAdditionalPaidTimeClick &&
            handleEmptyDayAdditionalPaidTimeClick({
              start: selectedDate,
              end: selectedDate,
            })
          }
        >
          Request additional paid time
        </Button>
      )}
    </Box>
  );
};
