/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, TextField, Typography, Autocomplete } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isValid, parseISO } from 'date-fns';
import { useState, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Modal,
  SimpleTimePicker,
  SnackbarService,
  theme,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';
import { getTotalMinutes } from './utils';

interface AddTimeEntryModalProps {
  addTimeEntryModalOpen: boolean;
  setAddTimeEntryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPayPeriod: PayPeriod | null;
  data: any;
  employeePayroll: EmployeePayroll;
  forceRefetch: () => void;
  payCodes: { code: string; name: string }[];
}

export const AddTimeEntryModal = ({
  addTimeEntryModalOpen,
  setAddTimeEntryModalOpen,
  selectedPayPeriod,
  data,
  employeePayroll,
  forceRefetch,
  payCodes,
}: AddTimeEntryModalProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [hours, minutes] = departmentInfoState.departmentInfo.shiftStart.split(':').map(Number);
  const [endDate, setEndDate] = useState<Date | null>(() =>
    selectedPayPeriod?.endDate ? parseISO(selectedPayPeriod.endDate) : null,
  );
  const [startTime, setStartTime] = useState<Date | null>(new Date(new Date().setHours(hours, minutes)));
  const [endTime, setEndTime] = useState<Date | null>(new Date(new Date().setHours(hours, minutes)));
  const [payCode, setPayCode] = useState<string | null>(null);
  const [note, setNote] = useState<string>('');
  const [duration, setDuration] = useState<number | null>(null);

  const currentPayPeriods = departmentInfoState.departmentInfo.currentPayPeriods;
  const isInCurrentPayPeriod = currentPayPeriods.map((payPeriod) => payPeriod.id).includes(employeePayroll.payPeriod.id);

  const isDuplicate = (startDate: Date | null, payCode: string | null) => {
    if (
      startDate &&
      isValid(startDate) &&
      payCode &&
      data.some(
        (entry: { [x: string]: string | null; date: string }) =>
          entry['pay code'] === payCode && entry.date === format(startDate, 'yyyy-MM-dd'),
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submit = async () => {
    try {
      if (!startDate || !isValid(startDate)) {
        throw new Error('Start date is required');
      }

      const params = {
        action: 'add_entry',
        date: format(startDate, 'yyyy-MM-dd'),
        duration: duration,
        payCode: payCode,
        note: note,
        startTime: !startTime ? null : getTotalMinutes(startTime),
      };
      const response = await client.post(`payroll/${employeePayroll?.id}/entry-action/`, params);

      if (response.status === 200) {
        SnackbarService.notify({
          content: 'Successfully added new time entry',
          severity: 'success',
          duration: 5000,
        });
        forceRefetch();
      } else {
        SnackbarService.notify({
          content: 'Failed to add new time entry',
          severity: 'error',
          duration: 10000,
        });
        console.error('Failed to save changes', response);
      }
      onCloseModal();
    } catch (error) {
      captureException(error);
      console.error('An error occurred while adding new time entry', error);
    }
  };

  const onCloseModal = () => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(new Date(new Date().setHours(hours, minutes)));
    setEndTime(new Date(new Date().setHours(hours, minutes)));
    setPayCode(payCodes[0].code);
    setNote('');
    setDuration(null);
    setAddTimeEntryModalOpen(false);
  };

  useEffect(() => {
    if (startDate && endDate && startTime && endTime) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(startTime.getHours());
      startDateTime.setMinutes(startTime.getMinutes());

      const endDateTime = new Date(endDate);
      endDateTime.setHours(endTime.getHours());
      endDateTime.setMinutes(endTime.getMinutes());

      const durationInMs = endDateTime.getTime() - startDateTime.getTime();
      const durationInHours = durationInMs / (1000 * 60 * 60);
      setDuration(isNaN(durationInHours) ? null : parseFloat(durationInHours.toFixed(2)));
    } else {
      setDuration(null);
    }
  }, [startDate, endDate, startTime, endTime]);

  const isDateWithinPayPeriod = (date: Date, payPeriod: { startDate: string; endDate: string }): boolean =>
    date >= parseISO(payPeriod.startDate) && date <= parseISO(payPeriod.endDate);

  const areAllFieldsFilled = startDate && endDate && startTime && endTime && duration && duration > 0 && payCode;

  const isWithinSelectedPayPeriod = selectedPayPeriod && startDate ? isDateWithinPayPeriod(startDate, selectedPayPeriod) : true;

  const isSubmitDisabled =
    !areAllFieldsFilled || !isWithinSelectedPayPeriod || (isDuplicate(startDate, payCode) && !isInCurrentPayPeriod);

  return (
    <Modal open={addTimeEntryModalOpen} setOpen={onCloseModal} hideCloseIcon={false}>
      <Box
        sx={{
          borderRadius: '16px',
          width: '428px',
        }}
      >
        <Typography sx={{ typography: 'bodyXLSemibold' }}>Add new time entry</Typography>
        <Box>
          <Box sx={(theme) => ({ mt: theme.spacing(2.5) })}>
            <Typography sx={{ typography: 'bodySMedium', marginTop: theme.spacing(3) }}>Start</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(2),
                marginTop: theme.spacing(0.5),
              }}
            >
              <DatePicker
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                minDate={selectedPayPeriod?.startDate ? parseISO(selectedPayPeriod?.startDate) : undefined}
                maxDate={selectedPayPeriod?.endDate ? parseISO(selectedPayPeriod?.endDate) : undefined}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(145, 173, 1, 1)',
                      boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                    },
                  },
                  flex: 3,
                }}
                slotProps={{ textField: { placeholder: 'Choose date' } }}
              />
              <SimpleTimePicker setValue={setStartTime} value={startTime} sxProps={{ flex: 2 }} />
            </Box>
          </Box>
          <Typography sx={{ typography: 'bodySMedium', marginTop: theme.spacing(3) }}>End</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(2),
              marginTop: theme.spacing(0.5),
            }}
          >
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              minDate={startDate || undefined}
              disabled={!startDate}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(145, 173, 1, 1)',
                    boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                  },
                },
                flex: 3,
              }}
              slotProps={{ textField: { placeholder: 'Choose date' } }}
            />
            <SimpleTimePicker setValue={setEndTime} value={endTime} sxProps={{ flex: 2 }} disabled={!startDate || !startTime} />
          </Box>
        </Box>
        {duration !== null && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.spacing(2),
              mt: theme.spacing(3),
              backgroundColor: theme.palette.stationGray[100],
              borderRadius: '8px',
            }}
          >
            <Typography sx={{ typography: 'bodyMRegular', color: theme.palette.stationGray[500] }}>Duration</Typography>
            <Typography sx={{ typography: 'bodyMSemibold', color: theme.palette.stationGray[900] }}>
              {duration.toFixed(2)} hrs
            </Typography>
          </Box>
        )}
        <Typography sx={{ typography: 'bodySMedium', marginTop: theme.spacing(3) }}>Pay Code</Typography>
        <Autocomplete
          options={payCodes}
          value={payCodes.find((option) => option.code === payCode) || null}
          getOptionLabel={(option) => (option.code === option.name ? option.code : `${option.code} - ${option.name}`)}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          onChange={(_, newValue) => setPayCode(newValue ? newValue.code : null)}
          renderOption={(itemProps, option) => (
            <li {...itemProps} key={itemProps.key}>
              {option.code === option.name ? option.code : `${option.code} - ${option.name}`}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Select Pay Code"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            );
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(145, 173, 1, 1)',
                boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
              },
            },
          }}
        />
        <Typography sx={{ typography: 'bodySMedium', marginTop: theme.spacing(3) }}>Note (optional)</Typography>
        <TextField
          multiline
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{
            width: '100%',
            marginTop: theme.spacing(0.5),
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(145, 173, 1, 1)',
                boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
              },
            },
          }}
          rows={3}
          inputProps={{ maxLength: 80 }}
        />
        {isDuplicate(startDate, payCode) && (
          <Typography
            sx={{ color: theme.palette.error.main, typography: 'bodyS', marginTop: theme.spacing(1), alignSelf: 'center' }}
          >
            {'There is already an entry for that day. Please edit the existing entry instead.'}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(1.5),
            gap: theme.spacing(2),
            width: '100%',
            paddingY: theme.spacing(3),
          }}
        >
          <Button onClick={onCloseModal} variant="outlined" size="large" sx={{ width: '100%' }}>
            {'Cancel'}
          </Button>
          <Button onClick={submit} variant="contained" size="large" sx={{ width: '100%' }} disabled={isSubmitDisabled}>
            {'Submit'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
