import { format, parseISO } from 'date-fns';
import { DepartmentInfo, ShiftPlanAssignmentAPIData, ShiftPlanAssignment } from '@stationwise/share-types';
import { getDepartmentPayCodes } from '@stationwise/shift-summary-helper';

export const initializeAssignments = (departmentInfo: DepartmentInfo, assignments: ShiftPlanAssignmentAPIData[]) => {
  return assignments.map((assignment) => {
    return {
      ...assignment,
      activationDate: parseISO(assignment.activationDate),
      deactivationDate: assignment.deactivationDate ? parseISO(assignment.deactivationDate) : null,
      startDateTime: new Date(assignment.startDateTime),
      endDateTime: new Date(assignment.endDateTime),
      payCodes: getDepartmentPayCodes(departmentInfo, assignment.payCodes),
    };
  });
};

export const getAssignmentGroups = (assignments: ShiftPlanAssignment[]) => {
  const positionAssignments = new Map<string, ShiftPlanAssignment[]>();
  const excessAssignments = new Map<string, ShiftPlanAssignment[]>();
  const floaterAssignments: ShiftPlanAssignment[] = [];
  assignments.forEach((assignment) => {
    const { apparatus, position } = assignment.reference;
    if (position) {
      const group = positionAssignments.get(`${position.id}`) || [];
      group.push(assignment);
      positionAssignments.set(`${position.id}`, group);
    } else if (apparatus) {
      const group = excessAssignments.get(`${apparatus.id}`) || [];
      group.push(assignment);
      excessAssignments.set(`${apparatus.id}`, group);
    } else {
      floaterAssignments.push(assignment);
    }
  });
  return { positionAssignments, excessAssignments, floaterAssignments };
};

export const formatAssignment = (assignment: ShiftPlanAssignment) => {
  const locationTexts: string[] = [];
  locationTexts.push(assignment.reference.apparatus?.name || 'Floater');
  if (assignment.reference.position) {
    locationTexts.push(assignment.reference.position.rank?.code || 'No rank');
  } else if (assignment.reference.apparatus) {
    locationTexts.push('Excess capacity');
  }

  return {
    employee: assignment.employee.name,
    team: assignment.employee.team.name,
    activationDate: format(assignment.activationDate, 'MM/dd/yyyy').replace('01/01/0001', 'Indefinite'),
    deactivationDate: assignment.deactivationDate ? format(assignment.deactivationDate, 'MM/dd/yyyy') : 'Indefinite',
    location: locationTexts.join(', '),
    startTime: format(assignment.startDateTime, 'HH:mm'),
    endTime: format(assignment.endDateTime, 'HH:mm'),
    payCodes: assignment.payCodes.map((pc) => pc.code).join(', ') || 'N/A',
    detailCodes: assignment.detailCodes.map((dc) => dc.code).join(', ') || 'N/A',
  };
};
