import { Certification } from '../certification';
import { BasicEmployee, EmployeeShiftDetails } from '../employee';
import { CertificationActAsRank, Rank } from '../organization';
import { PayPeriod } from './getPersonalPayroll';

// Every department has to have these detail codes.
export const DETAIL_CODE_ACTING_OUT_OF_RANK = 'ACT';
export const PAY_CODE_TYPE_OT = 'OVERTIME';

export interface PayCode {
  id: number;
  name: string;
  code: string;
  payCodeType: string;
  maxAccrualLimit: number | null;
  convertibleTo?: number | null;
  extCode?: string;
  tags: string[];
  timeOffAccrual?: string;
}

export interface PayCodeComplete {
  id: number;
  name: string;
  code: string;
  payCodeType: string;
  extCode?: string;
  bankedHours?: BankedHours;
  timeOffAccrual?: TimeOffAccruals;
}

export interface BankedHours {
  id?: number | null;
  spendRatio?: number;
  convertibleTo?: number | null;
  convertRatio?: number;
  spendablePayCode?: number | null;
  spendablePayCodeObject?: PayCodeComplete | null | PayCode;
  convertibleToObject?: PayCodeComplete | null | PayCode;
}

export interface TimeOffAccruals {
  id?: number | null;
  defaultAccrualAmount?: number;
  maxAccrualLimit?: number;
  isDefaultUnlimited?: boolean;
  isMaxUnlimited?: boolean;
  name?: string;
  payCodes?: PayCode[];
  payCodesInput?: number[];
}

export interface DetailCode {
  id: number;
  name: string;
  code: string;
  payCodeType: string;
}

export interface Employee {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  rank: Rank;
  name: string;
  payrollId: string;
  certifications?: Certification[];
  plannedShiftDetails: EmployeeShiftDetails[];
  station?: string;
  apparatus?: string;
  certificationActAsRank?: CertificationActAsRank;
  isActive: boolean;
}

export interface NavigationEmployee {
  payrollId: number;
  name: string;
}

export interface EmployeePayrollNavigation {
  nextEmployee: NavigationEmployee | null;
  prevEmployee: NavigationEmployee | null;
}

export interface EmployeePayroll {
  id: number;
  employee: Employee;
  payPeriod: PayPeriod;
  employeePayrollNavigation?: EmployeePayrollNavigation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailedPayroll: any;
  employeeSigned: boolean;
  signedState: string;
  approvalState: EmployeePayrollApprovalState;
  approverRoles: string[];
  payrollColumns: string[];
  additionalColumns: string[];
  firstColumnsNames: string[];
}

export interface EmployeePayrollApprovalState {
  managerOne: ManagerApprovalState;
  managerTwo: ManagerApprovalState;
  managerThree?: ManagerApprovalState;
}

export interface ManagerApprovalState {
  approved: boolean;
  approver: BasicEmployee;
}

export interface ActingPayCodeMapping {
  id: number;
  actingPayCodeId: number;
  basePayCodeId: number;
  actingRankId: number | null;
  isStacking: boolean;
}
