import { RosterStation, RosterPosition, RosterEmployee, TimePreferenceChoice } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { getVacancies, getBoardEmployees } from '../board';
import { checkIsShift } from '../id';
import { IShiftSummaryHelper, RequirementCount } from '../types';
import { getApparatusCertifications, getStationCertifications, getMissingCertifications } from './certification';
import { mergeConsecutiveWorkDurations } from './overtime';
import { checkIsActingAsRank, getActingAsRankCounts, getApparatusRanks } from './rank';

export const getTotalStaffed = (station: RosterStation) => {
  const count = { total: 0, amount: 0 };

  let employeeIds = new Set<string>();
  station.apparatuses.forEach((apparatus) => {
    apparatus.positions.forEach((position) => {
      position.employees.forEach((employee) => employeeIds.add(employee.id));
      count.total += position.isTemporary ? 0 : 1;
    });
  });
  count.amount = employeeIds.size;
  return count;
};

export const stationHasMinRequirements = (station: RosterStation) => {
  const checkCount = ({ total, amount }: RequirementCount) => amount >= total;

  return (
    checkCount(getTotalStaffed(station)) &&
    Array.from(getStationCertifications(station).values()).every(checkCount) &&
    station.apparatuses.every((apparatus) => Array.from(getApparatusRanks(apparatus).values()).every(checkCount)) &&
    station.apparatuses.every((apparatus) => Array.from(getApparatusCertifications(apparatus).values()).every(checkCount)) &&
    station.apparatuses.every((apparatus) => apparatus.positions.flatMap((position) => getVacancies(position)).length === 0)
  );
};

export const getPositionRequirementErrorMessages = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
) => {
  const messages: string[] = [];

  const missingCertifications = getMissingCertifications(position.certifications, employee.certifications);
  if (missingCertifications.length > 0) {
    const codes = missingCertifications.map((cert) => cert.code).join(', ');
    const message = `This person is missing ${codes} certification and is unable to perform this role. Please, staff a qualified person`;
    messages.push(message);
  }

  const { departmentInfo } = shiftSummaryHelper;
  const hasMaxActorsRule = !!(departmentInfo.settings.maxActorsRule.byRank || departmentInfo.settings.maxActorsRule.byTotal);
  if (
    hasMaxActorsRule &&
    departmentInfo.settings.actingRolesEnabled &&
    checkIsShift(employee) &&
    checkIsActingAsRank(shiftSummaryHelper, position, employee)
  ) {
    const { totalCount, rankCounts } = getActingAsRankCounts(shiftSummaryHelper);
    const rankCount = rankCounts.get(position.rank.code) ?? 0;
    const maxRankCount = departmentInfo.settings.maxActorsRule.byRank?.[position.rank.code];
    if (typeof maxRankCount === 'number' && rankCount > maxRankCount) {
      let message = `At most ${maxRankCount} people are allowed to act as ${position.rank.code} rank`;
      if (maxRankCount === 1) {
        message = `At most 1 person is allowed to act as ${position.rank.code} rank`;
      }
      messages.push(message);
    }

    const maxTotalCount = departmentInfo.settings.maxActorsRule.byTotal;
    if (typeof maxTotalCount === 'number' && totalCount > maxTotalCount) {
      let message = `At most ${maxTotalCount} people in total are allowed to act as another rank`;
      if (maxTotalCount === 1) {
        message = 'At most 1 person in total is allowed to act as another rank';
      }
      messages.push(message);
    }
  }

  return messages;
};

export const getEmployeeTimePreferenceError = (
  shiftSummaryHelper: IShiftSummaryHelper,
  position: RosterPosition | null,
  employee: RosterEmployee,
) => {
  const messages: string[] = [];
  const positionVacancies = position ? getVacancies(position) : [];
  let canOverride = true;

  const partialVacanciesExist = positionVacancies.some(
    (vacancy) => differenceInUTCMinutes(vacancy.endDateTime, vacancy.startDateTime) < shiftSummaryHelper.shiftDuration.hours * 60,
  );
  if (employee.timePreference === TimePreferenceChoice.ONLY_PARTIAL && !partialVacanciesExist) {
    messages.push('Only partial shifts are enabled for this employee.');
    canOverride = false;
  } else if (employee.timePreference === TimePreferenceChoice.ONLY_FULL && partialVacanciesExist) {
    messages.push('Only full shifts are enabled for this employee.');
    canOverride = false;
  }

  return { messages, canOverride };
};

export const getOvertimeRequirementErrorMessages = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const messages: string[] = [];

  const { departmentInfo, shiftDuration } = shiftSummaryHelper;
  if (typeof departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const consecutiveWorkDurations = mergeConsecutiveWorkDurations(
      employee.maybeConsecutiveWorkDurations || [],
      getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === employee.id),
      [],
      shiftDuration,
      departmentInfo.settings.maxConsecutiveHoursRule.breakHours ?? 0,
    );
    const consecutiveWorkHours = Math.max(...consecutiveWorkDurations.map((d) => d.hours));
    if (consecutiveWorkHours > departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours) {
      const message = `This person is working ${consecutiveWorkHours} consecutive hours, which exceeds the maximum ${departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours} hours`;
      messages.push(message);
    }
  }

  return messages;
};
