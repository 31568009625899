import {
  RosterDataSource,
  RosterEmployeeOffPayloads,
  RosterEmployeeSplit,
  ListFieldsStaffingList,
  StaffingListItem,
} from '@stationwise/share-types';
import { getOverrideEmployeePositionFields } from './board';
import { getEmployeeSplits } from './getEmployeeSplits';
import { makeOvertimeEmployeeActiveId } from './id';
import { IShiftSummaryHelper } from './types';

export const getOvertimeEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  selectedStaffingList: ListFieldsStaffingList | undefined,
  employeeActiveId: string,
) => {
  const staffingListItem = selectedStaffingList?.items?.find((item) => {
    return employeeActiveId === makeOvertimeEmployeeActiveId(item.employee.id);
  });
  if (!staffingListItem) {
    return null;
  }
  return {
    ...staffingListItem.employee,
    activeId: makeOvertimeEmployeeActiveId(staffingListItem.employee.id),
    dataSource: RosterDataSource.OVERTIME,
    startDateTime: shiftSummaryHelper.shiftDuration.startTime,
    endDateTime: shiftSummaryHelper.shiftDuration.endTime,
    payCodes: staffingListItem.employee.payCodes,
    ...getOverrideEmployeePositionFields(),
  };
};

interface GetStaffingListEmployeeSplitsInput extends RosterEmployeeOffPayloads {
  shiftSummaryHelper: IShiftSummaryHelper;
  staffingListItems: StaffingListItem[];
}

export const getStaffingListItemEmployeeSplits = ({ staffingListItems, ...input }: GetStaffingListEmployeeSplitsInput) => {
  const employeeIds = new Set(staffingListItems.map((item) => item.employee.id));
  const employeeSplits = new Map<string, RosterEmployeeSplit[]>();
  if (employeeIds.size) {
    getEmployeeSplits(input).forEach((splits, employeeId) => {
      if (employeeIds.has(employeeId)) {
        employeeSplits.set(employeeId, splits);
      }
    });
  }
  return employeeSplits;
};
