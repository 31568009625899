import { Box } from '@mui/material';
import { memo } from 'react';
import { CustomTable, Loader, TableProps, TeamBadge } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface StaffingListItemsProps {
  isLoading: boolean;
  selectedStaffingList: ListFieldsStaffingList | undefined;
  searchInput: string;
  selectedTeam: string;
  selectedLastOvertimeDate: string;
  selectedStation: string;
}

export const StaffingListItems = memo((props: StaffingListItemsProps) => {
  const items = (props.selectedStaffingList?.items || []).filter((item) => {
    let match = true;
    if (props.searchInput) {
      match = match && item.employee.name.toLowerCase().includes(props.searchInput.toLowerCase());
    }
    if (props.selectedTeam) {
      match = match && item.employee.team?.name === props.selectedTeam;
    }
    if (props.selectedStation) {
      match =
        (match && item.employee.station.name === props.selectedStation) || item.attributes?.availableForAllStations === true;
    }
    if (props.selectedLastOvertimeDate) {
      match = match && item.attributes.lastThreeOvertimes?.[0]?.date === props.selectedLastOvertimeDate;
    }
    return match;
  });

  const getColumns = () => {
    const columns: TableProps['columns'] = [
      { id: 'order', label: 'NO.' },
      { id: 'firstName', label: 'FIRST NAME' },
      { id: 'lastName', label: 'LAST NAME' },
      { id: 'team', label: 'SHIFT' },
      { id: 'status', label: 'STATUS' },
      { id: 'station', label: 'STATION' },
    ];
    if (props.selectedStaffingList?.items?.some((item) => item.attributes.hasOwnProperty('availableForAllStations'))) {
      columns.push({ id: 'availableForAllStations', label: 'AVAILABLE FOR ALL STATIONS' });
    }
    if (props.selectedStaffingList?.items?.some((item) => item.attributes.hasOwnProperty('opportunityNumber'))) {
      columns.push({ id: 'opportunityNumber', label: 'OPPORTUNITY NUMBER' });
    }
    if (props.selectedStaffingList?.sorts?.some((sort) => sort.includes('SENIORITY'))) {
      columns.push({ id: 'hireDate', label: 'HIRE DATE' });
    }
    if (props.selectedStaffingList) {
      props.selectedStaffingList.sorts
        ?.filter((sort) => sort !== 'EMPLOYEE_CUSTOM_FIELD' && !sort.includes('SENIORITY'))
        .forEach((sort) => columns.push({ id: sort, label: sort.split('_').join(' ') }));
    }

    return columns;
  };

  const data = items.map((item) => {
    const [firstName, ...lastNames] = item.employee.name.split(' ');

    const relevantFieldsData: { [key: string]: string } = {};
    item.relevantFields.forEach((field) => (relevantFieldsData[field.key] = field.value));

    return {
      order: item.order,
      firstName,
      lastName: lastNames.join(' '),
      team: item.employee.team && <TeamBadge team={item.employee.team} />,
      status: item.employee.status.split('_').join(' '),
      station: item.employee.station.name,
      availableForAllStations: item.attributes.availableForAllStations ? 'Yes' : 'No',
      opportunityNumber: item.attributes.opportunityNumber,
      ...relevantFieldsData,
    };
  });

  return (
    <Box sx={{ position: 'relative', minHeight: 140 }}>
      {props.isLoading && (
        <Box sx={{ pt: 11, position: 'absolute', top: 0, left: 0, width: '100%' }}>
          <Loader data-cy="staffing-list-items-loader" />
        </Box>
      )}
      <Box sx={{ visibility: props.isLoading ? 'hidden' : undefined }}>
        <CustomTable columns={getColumns()} data={data} />
        {!props.selectedStaffingList?.items?.length && (
          <Box sx={{ display: 'flex', py: 3, justifyContent: 'center', typography: 'bodyMMedium' }}>
            {!props.selectedStaffingList ? 'There are no lists' : 'This list is empty'}
          </Box>
        )}
      </Box>
    </Box>
  );
});

StaffingListItems.displayName = 'Memo(StaffingListItems)';
