import { useCallback, useEffect, useRef, useState } from 'react';
import { useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { ShiftPlanCandidateAPIData, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { initializeAssignments } from '../helpers/readAssignments';

interface UseShiftPlanCandidatesProps {
  positionId: string | null;
  searchValue: string;
}

export const useShiftPlanCandidates = ({ positionId, searchValue }: UseShiftPlanCandidatesProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [data, setData] = useState<ShiftPlanCandidate[]>([]);

  const resetData = useCallback(() => {
    setPage(1);
    setHasNextPage(true);
    setData([]);
  }, []);

  useEffect(() => {
    resetData();
  }, [resetData, positionId, searchValue]);

  useEffect(() => {
    const fetchData = async () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      if (!positionId) {
        setIsLoading(false);
        setData([]);
        setHasNextPage(false);
        return;
      }

      setIsLoading(true);
      try {
        const url = 'shift/shift-plan-candidates/';
        const params = {
          page,
          positionId,
          search: searchValue || undefined,
        };
        const response = await client.get(url, {
          params,
          signal: abortControllerRef.current.signal,
        });
        setData((oldData) => {
          const keys = new Map<string, number>();
          const newData: ShiftPlanCandidate[] = [];
          const pushData = (result: ShiftPlanCandidate) => {
            const index = keys.get(result.id);
            if (typeof index === 'number') {
              newData[index] = result;
            } else {
              newData.push(result);
              keys.set(result.id, newData.length - 1);
            }
          };
          oldData.forEach(pushData);
          (response.data.results || []).forEach((result: ShiftPlanCandidateAPIData) => {
            const assignments = initializeAssignments(departmentInfoState.departmentInfo, result.assignments);
            pushData({ ...result, assignments });
          });
          return newData;
        });
        setHasNextPage(!!response.data.next);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && setData([]);
        !isCanceled && setHasNextPage(false);
        !isCanceled && setIsLoading(false);
      }
    };

    fetchData();
  }, [departmentInfoState.departmentInfo, positionId, searchValue, page]);

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  const setCandidateAssignments = (candidateId: string, assignments: ShiftPlanAssignment[]) => {
    setData((oldData) => {
      return oldData.map((result) => {
        if (result.id !== candidateId) {
          return result;
        }
        return { ...result, assignments };
      });
    });
  };

  return {
    isLoading,
    data,
    hasNextPage,
    loadMore,
    resetData,
    setCandidateAssignments,
  };
};
