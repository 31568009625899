/* eslint-disable no-restricted-imports */
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS } from 'date-fns/locale/en-US';
import { getAutoOpenTextFieldProps, useDateOrTimePicker } from './common';

type DateTimePickerProps<D extends Date> = MuiDateTimePickerProps<D>;

export const DateTimePicker = <D extends Date>({ sx, ...props }: DateTimePickerProps<D>) => {
  const { isDesktop, open, onOpen, onClose } = useDateOrTimePicker(props);

  return (
    <LocalizationProvider adapterLocale={enUS} dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        format="MMM dd, yyyy HH:mm"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        {...props}
        sx={[
          {
            '& .MuiInputBase-adornedStart .MuiInputBase-input': { pl: 0 },
            '& .MuiInputBase-adornedEnd .MuiInputBase-input': { pr: 0 },
          },
          ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
        ]}
        slotProps={{
          ...props.slotProps,
          textField: {
            ...(isDesktop && !props.disabled && getAutoOpenTextFieldProps(open, onOpen, onClose)),
            ...props.slotProps?.textField,
          },
        }}
      />
    </LocalizationProvider>
  );
};
