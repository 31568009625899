import { Box, Modal } from '@mui/material';
import {
  Button,
  DatePicker,
  Input,
  theme,
  useDepartmentInfoContext,
  MultipleSelect,
  AdminSelect,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { AddEmployeeProps } from './MyTeamMain';

interface AddEmployeeDetailsProps {
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeInfo: AddEmployeeProps;
  addEmployeeInfo: (employeeInfo: AddEmployeeProps) => void;
  initialState: AddEmployeeProps;
  setAddEmployeeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddEmployeeDetails = ({
  detailsOpen,
  setDetailsOpen,
  setDefaultsOpen,
  employeeInfo,
  addEmployeeInfo,
  initialState,
  setAddEmployeeOpen,
}: AddEmployeeDetailsProps) => {
  const { state: department } = useDepartmentInfoContext();

  const isDisabled = !employeeInfo.rank || !employeeInfo.type;

  const handleFieldChange = (fieldName: string, value: string | number | Date | string[] | null) => {
    addEmployeeInfo({ ...employeeInfo, [fieldName]: value });
  };

  const handleModalClose = () => {
    addEmployeeInfo(initialState);
    setDetailsOpen(false);
  };

  const openDefaultsModal = () => {
    setDetailsOpen(false);
    setDefaultsOpen(true);
  };

  const rankOptions = department.departmentInfo?.ranks.map((rank) => ({
    label: rank.name,
    value: rank.code,
  }));

  const handleBack = () => {
    setDetailsOpen(false);
    setAddEmployeeOpen(true);
  };

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const haveVolunteers = departmentInfoState.departmentInfo.settings.haveVolunteers;

  const typeOptions = [
    {
      label: 'Employee',
      value: 'FULL_TIME',
      helperText:
        'Choose this option for career firefighters, admin staff, and \nany folks that work a recurring shift (including part-time employees)',
    },
    {
      label: 'Volunteer',
      value: 'VOLUNTEER',
      helperText: 'Choose this option only for volunteer firefighters',
    },
  ];

  const licenseClassOptions = ['A', 'B', 'C', 'D', 'E'].map((cls) => ({
    label: '',
    value: cls,
  }));

  return (
    <Modal open={detailsOpen}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '95vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Add details
          </Box>
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Hire date
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <DatePicker
              value={employeeInfo.hireDate}
              onChange={(date) => handleFieldChange('hireDate', date)}
              maxDate={new Date()}
              sx={{ width: '100%' }}
              slotProps={{ textField: { placeholder: 'Choose date' } }}
            />
          </Box>

          {haveVolunteers && (
            <>
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Type <span style={{ color: theme.palette.stationRed[600] }}>*</span>
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                {typeOptions && (
                  <AdminSelect
                    items={typeOptions}
                    selectedItem={employeeInfo.type || ''}
                    setSelectedItem={(type) => handleFieldChange('type', type)}
                    placeholder="Choose employee type"
                  />
                )}
              </Box>
            </>
          )}

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Rank <span style={{ color: theme.palette.stationRed[600] }}>*</span>
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            {rankOptions && (
              <AdminSelect
                items={rankOptions}
                selectedItem={employeeInfo.rank || ''}
                setSelectedItem={(rank) => handleFieldChange('rank', rank)}
                placeholder="Choose a rank"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            {"Driver's license no."}
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={employeeInfo.driversLicenseNumber || ''}
              onChange={(event) => {
                handleFieldChange('driversLicenseNumber', event.target.value);
              }}
              placeholder="Enter a license number"
            />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            {"Driver's license classes"}
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <MultipleSelect
              items={licenseClassOptions}
              selectedItems={employeeInfo.driversLicenseClasses || []}
              setSelectedItems={(classes) => handleFieldChange('driversLicenseClasses', classes)}
              placeholder="Select license classes"
            />
          </Box>

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button variant="text" size="large" sx={{ width: '108px', mt: theme.spacing(5) }} onClick={() => handleBack()}>
              Back
            </Button>
            <Box
              sx={(theme) => ({
                justifyContent: 'flex-end',
                display: 'flex',
                mt: theme.spacing(5),
                width: '100%',
                gap: theme.spacing(2),
              })}
            >
              <Button variant="outlined" size="large" sx={{ width: '108px' }} onClick={() => handleModalClose()}>
                Cancel
              </Button>
              <Button variant="contained" size="large" sx={{ width: '108px' }} disabled={isDisabled} onClick={openDefaultsModal}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
