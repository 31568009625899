import { format } from 'date-fns';
import { useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import { Rank } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';

export const generateRankOrder = (startRankId: number, uniqueRanks: Rank[]): { rankId: number; isSelected: boolean }[] => {
  const startRankIndex = uniqueRanks.findIndex((rank) => rank.id === startRankId);
  if (startRankIndex === -1) return uniqueRanks.map((rank) => ({ rankId: rank.id, isSelected: false }));

  const higherRanks = uniqueRanks.slice(startRankIndex).map((rank) => ({ rankId: rank.id, isSelected: true }));
  const lowerRanks = uniqueRanks
    .slice(0, startRankIndex)
    .reverse()
    .map((rank) => ({ rankId: rank.id, isSelected: true }));
  return [...higherRanks, ...lowerRanks];
};

export const getFormattedTimes = (vacancy: HiringEngineVacancy) => {
  const startDateTime = new Date(new Date(vacancy.startDateTime).getTime());
  const endDateTime = new Date(new Date(vacancy.endDateTime).getTime());
  endDateTime < startDateTime && endDateTime.setMinutes(endDateTime.getMinutes() + 24 * 60);

  const differenceInMinutesTotal = differenceInUTCMinutes(endDateTime, startDateTime);

  return {
    startTimeFormatted: format(startDateTime, 'HH:mm'),
    endTimeFormatted: format(endDateTime, 'HH:mm'),
    hoursDifference: Math.floor(differenceInMinutesTotal / 60),
    minutesDifference: differenceInMinutesTotal % 60,
  };
};

export function useFlipAnimation<T>(
  items: T[],
  duration = 300,
  easing = 'ease',
): {
  flipRef: (item: T) => (node: HTMLElement | null) => void;
  updatePositions: () => void;
} {
  const itemRefs = useRef<Record<string, HTMLElement | null>>({});
  const positions = useRef<Record<string, number>>({});
  const isInitialMount = useRef(true);

  const updatePositions = useCallback(() => {
    Object.keys(itemRefs.current).forEach((key) => {
      const node = itemRefs.current[key];
      if (node) {
        positions.current[key] = node.getBoundingClientRect().top;
      }
    });
  }, []);

  useLayoutEffect(() => {
    const currentKeys = new Set(items.map(String));
    Object.keys(itemRefs.current).forEach((key) => {
      if (!currentKeys.has(key)) {
        const node = itemRefs.current[key];
        if (node) {
          node.style.transition = '';
          node.style.transform = '';
        }
        delete itemRefs.current[key];
        delete positions.current[key];
      }
    });
  }, [items]);

  useLayoutEffect(() => {
    if (isInitialMount.current) {
      items.forEach((item) => {
        const key = String(item);
        const node = itemRefs.current[key];
        if (node) {
          positions.current[key] = node.getBoundingClientRect().top;
        }
      });
      isInitialMount.current = false;
      return;
    }

    let deltas: number[] = [];
    items.forEach((item) => {
      const key = String(item);
      const node = itemRefs.current[key];
      if (node && positions.current[key] !== undefined) {
        const newTop = node.getBoundingClientRect().top;
        const delta = positions.current[key] - newTop;
        deltas.push(delta);
      }
    });

    const skipAnimation = deltas.length > 0 && deltas.every((d) => Math.abs(d - deltas[0]) < 1);

    items.forEach((item) => {
      const key = String(item);
      const node = itemRefs.current[key];
      if (node) {
        const newTop = node.getBoundingClientRect().top;
        const prevTop = positions.current[key];
        if (prevTop !== undefined && !skipAnimation) {
          const delta = prevTop - newTop;
          if (delta !== 0) {
            node.style.transition = 'none';
            node.style.transform = `translateY(${delta}px)`;

            void node.offsetHeight;

            requestAnimationFrame(() => {
              node.style.transition = `transform ${duration}ms ${easing}`;
              node.style.transform = '';

              const handleTransitionEnd = () => {
                node.style.transition = '';
                node.removeEventListener('transitionend', handleTransitionEnd);
              };
              node.addEventListener('transitionend', handleTransitionEnd);
            });
          }
        }
        positions.current[key] = newTop;
      }
    });
  }, [items, duration, easing]);

  const setRef = useCallback(
    (item: T) => (node: HTMLElement | null) => {
      itemRefs.current[String(item)] = node;
    },
    [],
  );

  useEffect(() => {
    const currentItemNodes = Object.values(itemRefs.current);
    return () => {
      currentItemNodes.forEach((node) => {
        if (node) {
          node.style.transition = '';
          node.style.transform = '';
        }
      });
    };
  }, []);

  return { flipRef: setRef, updatePositions };
}
