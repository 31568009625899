import { Box, Checkbox } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInDays, format, isValid } from 'date-fns';
import { useDeferredValue, useEffect, useState } from 'react';
import {
  AssignmentForm as SelectionForm,
  CandidateCard,
  DatePicker,
  Filter,
  getCertColors,
  MultipleSelect,
  SelectItem,
  useLoadedDepartmentInfoContext,
  SnackbarService,
  isPastDate,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { Candidate, ListFieldsStaffingList, StaffingListExemption } from '@stationwise/share-types';
import { useFetchExemptionCandidates } from '../../hooks/useFetchExemptionCandidates';

interface ExemptionFormProps {
  setDialogOpen: (open: boolean) => void;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList?: ListFieldsStaffingList;
  selectedDate: Date;
  forceRefetchExemptions: () => void;
  forceRefetchStaffingList: () => void;
  exemptions: StaffingListExemption[];
}
export const ExemptionForm = ({
  setDialogOpen,
  staffingLists,
  selectedStaffingList,
  selectedDate,
  forceRefetchExemptions,
  forceRefetchStaffingList,
  exemptions,
}: ExemptionFormProps) => {
  let exemptEmployeeIds: number[] = [];

  exemptions.forEach((exemption: StaffingListExemption) => {
    exemption.employeeIds.forEach((employeeId: number) => {
      exemptEmployeeIds.push(employeeId);
    });
  });

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const availableStaffingLists: SelectItem[] = staffingLists.map((sl) => ({ value: sl.id.toString(), label: sl.name }));
  const [selectedStaffingLists, setSelectedStaffingLists] = useState<string[]>(() =>
    selectedStaffingList ? [selectedStaffingList.id.toString()] : [],
  );
  const [startDate, setStartDate] = useState<Date | null>(selectedDate);
  const [endDate, setEndDate] = useState<Date | null>(selectedDate);
  const [multipleDays, setMultipleDays] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const deferredSearchValue = useDeferredValue(searchValue);

  const [selectedCertCodes, setSelectedCertCodes] = useState<Set<string>>(new Set());
  const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);

  const candidates = useFetchExemptionCandidates({
    searchValue: deferredSearchValue,
    certifications: selectedCertCodes,
    startDate: startDate && isValid(startDate) ? format(startDate, 'yyyy-MM-dd') : '',
  });

  useEffect(() => {
    if (!multipleDays) {
      setEndDate(startDate);
    }
  }, [startDate, multipleDays]);

  const filters = (
    <Filter
      value={selectedCertCodes}
      onChange={setSelectedCertCodes}
      filters={departmentInfoState.departmentInfo.certifications}
      getFilterColors={getCertColors}
    />
  );

  const onConfirmSubmit = async () => {
    try {
      await client.post('staffing-list/exemptions/', {
        startDate: startDate && isValid(startDate) ? format(startDate, 'yyyy-MM-dd') : '',
        endDate: endDate && isValid(endDate) ? format(endDate, 'yyyy-MM-dd') : '',
        staffingListIds: selectedStaffingLists.map((id) => Number(id)),
        employeeIds: selectedCandidates.map((candidate) => candidate.id),
      });
      forceRefetchExemptions();
      forceRefetchStaffingList();

      SnackbarService.notify({
        content: 'Exemption was successfully saved.',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data?.message || error.message : 'Error saving exemption';

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  const onSubmit = () => {
    setDialogOpen(false);

    if (selectedCandidates.length > 0) {
      onConfirmSubmit();
    }
  };

  const onSelectCandidate = (id: number, startDateTime?: Date) => {
    const selected = candidates.data.find(
      (candidate) =>
        candidate.id === id && (!candidate.startDateTime || (startDateTime && candidate.startDateTime === startDateTime)),
    );
    if (selected) {
      if (selectedCandidates.find((candidate) => candidate.id === selected.id)) {
        setSelectedCandidates((prev) => prev.filter((candidate) => candidate.id !== selected.id));
      } else {
        setSelectedCandidates((prev) => [...prev, selected]);
      }
    }
  };

  const searchResults = candidates.data
    .filter((candidate) => !exemptEmployeeIds.includes(candidate.id))
    .map((candidate) => (
      <CandidateCard
        key={`${candidate.id}_${candidate.startDateTime || ''}`}
        candidate={candidate}
        isSelected={selectedCandidates.map((c) => c.id).includes(candidate.id)}
        onClick={onSelectCandidate}
      />
    ));

  const aboveSearchContent = (
    <Box sx={{ mt: 2 }}>
      <MultipleSelect
        items={availableStaffingLists}
        selectedItems={selectedStaffingLists}
        setSelectedItems={setSelectedStaffingLists}
        placeholder="Select staffing lists"
        hideValue
        showLabel
      />
      <Box display={multipleDays ? 'flex' : 'inline-block'} width="100%" gap={2} mt={3}>
        <DatePicker
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          minDate={new Date()}
          sx={{ width: multipleDays ? '50%' : '100%' }}
          slotProps={{ textField: { placeholder: 'Start date' } }}
        />

        {multipleDays && (
          <DatePicker
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            minDate={startDate || undefined}
            sx={{ width: multipleDays ? '50%' : '100%' }}
            slotProps={{ textField: { placeholder: 'End date' } }}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <Box
          component="label"
          sx={[
            {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              typography: 'bodyMRegular',
              userSelect: 'none',
            },
          ]}
        >
          <Checkbox
            checked={multipleDays}
            onChange={() => {
              setMultipleDays((prev) => !prev);
            }}
            sx={(theme) => ({
              p: 0,
              '&:hover': { backgroundColor: 'transparent' },
              '&.Mui-checked': {
                color: theme.palette.stationBlue[600],
              },
            })}
          />
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[600],
            })}
          >
            Date range
          </Box>
        </Box>
        {multipleDays &&
          startDate &&
          endDate &&
          startDate.getDate() !== endDate.getDate() &&
          startDate !== null &&
          endDate !== null &&
          startDate < endDate && (
            <Box sx={{ typography: 'bodyMRegular' }}>{`Total: ${differenceInDays(endDate, startDate) + 1} days`}</Box>
          )}
      </Box>
    </Box>
  );

  return (
    <SelectionForm
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      title="Exempt employee(s)"
      subtitle=""
      aboveSearchContent={aboveSearchContent}
      belowSearchContent={filters}
      onCancel={() => setDialogOpen(false)}
      onSubmit={onSubmit}
      isLoading={false}
      hasNextPage={candidates.hasNextPage}
      loadMore={candidates.loadMore}
      disableSubmit={
        selectedCandidates.length === 0 ||
        selectedStaffingLists.length === 0 ||
        !startDate ||
        isPastDate(startDate) ||
        (multipleDays && !endDate) ||
        (startDate !== null && endDate !== null && startDate > endDate)
      }
      searchResults={searchResults}
      noResults={candidates.data.length === 0}
    />
  );
};
