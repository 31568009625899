import { Box, Theme, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ShiftSummaryOverride } from '@stationwise/share-types';
import { OverrideCircleBlueIcon } from '../../assets';
import { RankBadge } from '../Badge';
import { Button } from '../Button';

export interface OverridePopoverContentProps {
  override: ShiftSummaryOverride;
  handleEditClick: () => void;
  handleDeleteClick?: () => void;
  label?: string;
  noteLabel?: string | null;
  overrideByLabel?: string;
  colorMode?: 'light' | 'dark';
  icon?: React.ReactNode;
  noteProp?: string;
}

const getColor = (theme: Theme, colorMode: string) => {
  switch (colorMode) {
    case 'dark':
      return {
        background: theme.palette.stationGray[900],
        backgroundInner: theme.palette.stationGray[800],
        border: `1px solid ${theme.palette.stationGray[600]}`,
        borderInner: 'none',
        buttonHover: { background: theme.palette.stationGray[700] },
        buttonBorder: `1px solid ${theme.palette.stationGray[500]}`,
        text: theme.palette.common.white,
        title: theme.palette.stationGray[400],
        subtitle: theme.palette.stationGray[300],
      };
    default:
      return {
        background: theme.palette.common.white,
        backgroundInner: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderInner: `1px solid ${theme.palette.grey[200]}`,
        buttonHover: { background: theme.palette.stationGray[100] },
        buttonBorder: 'none',
        text: theme.palette.stationGray[900],
        title: theme.palette.stationGray[400],
        subtitle: theme.palette.stationGray[300],
      };
  }
};

export const OverridePopoverContent = ({
  override,
  label = 'Error override',
  noteLabel = 'Reason for the override',
  overrideByLabel = 'Overridden',
  colorMode = 'light',
  icon,
  handleEditClick,
  handleDeleteClick,
  noteProp,
}: OverridePopoverContentProps) => {
  const theme = useTheme();
  const colors = getColor(theme, colorMode);
  const formattedDate = format(parseISO(override.noteUpdatedAt), 'MMMM d, HH:mm');

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1.5}
      sx={(theme) => ({
        p: theme.spacing(2),
        background: colors.background,
        borderRadius: theme.spacing(1),
        border: colors.border,
        width: '264px',
      })}
      /* Prevent drags and clicks from propagating up to the anchor element. */
      onKeyDown={(e) => (e.code === 'Space' || e.code === 'Enter') && e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {icon !== undefined ? icon : <OverrideCircleBlueIcon />}
        <Box sx={{ color: colors.text, typography: 'bodyMMedium' }}>{label}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          background: colors.backgroundInner,
          border: colors.borderInner,
          borderRadius: theme.spacing(1),
          padding: '12px',
          mb: '12px',
        })}
      >
        {noteLabel && <Box sx={{ color: colors.title, typography: 'bodySMedium', mb: 1.5 }}>{noteLabel}</Box>}
        <Box sx={{ color: colors.text, typography: 'bodyMRegular' }}>{noteProp}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          background: colors.backgroundInner,
          border: colors.borderInner,
          borderRadius: theme.spacing(1),
          padding: '12px',
          mb: '12px',
        })}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Box sx={{ color: colors.subtitle, typography: 'bodySMedium' }}>{overrideByLabel}</Box>
          <Box sx={{ color: colors.title, typography: 'buttonS' }}>{formattedDate}</Box>
        </Box>
        <Box sx={{ display: 'flex', mb: 1 }}>
          <RankBadge rank={override.overrideBy.rank} rankField="name" dark={colorMode === 'dark'} />
        </Box>
        <Box sx={{ color: colors.text, typography: 'bodyMRegular' }}>{override.overrideBy.name}</Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, '&:empty': { display: 'none' } }}>
        <Button
          variant="outlined"
          size="large"
          sx={{ color: colors.text, border: colors.buttonBorder, '&:hover': colors.buttonHover }}
          onClick={handleEditClick}
        >
          Edit
        </Button>
        {handleDeleteClick && (
          <Button color="error" variant="contained" size="large" onClick={handleDeleteClick}>
            Remove
          </Button>
        )}
      </Box>
    </Box>
  );
};
