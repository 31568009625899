import { captureException } from '@sentry/react';
import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { client } from '@stationwise/share-api';
import { PayPeriod, PayrollData } from '@stationwise/share-types';
import { useLoadedAuthUserContext } from '../components/Auth/context/AuthUser';
import { useLoadedDepartmentInfoContext } from '../components/Department/context/DepartmentInfo';

export const useFetchPersonalPayroll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<PayrollData | null>(null);
  const { state: userState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<PayPeriod | null>(null);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(0);
  const [refetchCounter, setRefetchCounter] = useState(0);

  const departmentInfo = departmentContext.departmentInfo;
  const currentPayPeriods = departmentInfo?.currentPayPeriods;

  const fetchParams = useMemo(() => {
    if (!departmentInfo || !currentPayPeriods || !currentPayPeriods.length) {
      return { skip: true, refetchCounter };
    }
    const payPeriodType = userState.employee.rank.payPeriodType;
    const currentPayPeriod = currentPayPeriods.find((period) => period.payPeriodType === payPeriodType) || currentPayPeriods[0];
    const currentPayPeriodStartDate = currentPayPeriod.startDate;
    const currentPayPeriodEndDate = currentPayPeriod.endDate;

    if (!selectedPayPeriod) {
      setSelectedPayPeriod({
        startDate: currentPayPeriodStartDate,
        endDate: currentPayPeriodEndDate,
        duration: currentPayPeriod.duration,
        payPeriodType: payPeriodType,
        id: currentPayPeriod.id,
      });
    }

    return {
      startDate: selectedPayPeriod?.startDate || currentPayPeriodStartDate,
      endDate: selectedPayPeriod?.endDate || currentPayPeriodEndDate,
      refetchCounter,
    };
  }, [departmentInfo, refetchCounter, selectedPayPeriod, currentPayPeriods, userState.employee.rank.payPeriodType]);

  useEffect(() => {
    const { startDate, endDate, skip } = fetchParams;
    if (skip) {
      return;
    }
    if (refetchCounter === 0) {
      setIsLoading(true);
      setData(null);
    }
    setIsError(false);
    setError(null);

    client
      .get('/payroll/personal/', {
        params: {
          startDate: startDate ?? '',
          endDate: endDate ?? '',
        },
      })
      .then((data) => {
        if (data.status === 200) {
          setData(data.data);
        } else {
          setIsError(true);
          setError(data.statusText);
        }
      })
      .catch((error) => {
        setIsError(true);
        setError(error);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchParams, refetchCounter]);

  return {
    selectedPayPeriod,
    setSelectedPayPeriod,
    selectedPeriodIndex,
    setSelectedPeriodIndex,
    isLoading,
    isError,
    fetchError: error,
    payroll: data,
    startDate: fetchParams.startDate ? parseISO(fetchParams.startDate) : null,
    endDate: fetchParams.endDate ? parseISO(fetchParams.endDate) : null,
    refetchCounter,
    setRefetchCounter,
    currentPayPeriods,
  };
};
