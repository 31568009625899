import { Box } from '@mui/material';
import { CertBadge, CardCertBadges, CustomTable, Edit02Icon16, TableProps } from '@stationwise/component-module';
import { Certification } from '@stationwise/share-types';
import { makeTestIdentifier } from '../../../../../libs/share-utils/src/lib/string';

interface CertificationsTableProps {
  certifications: Certification[];
  handleEditAction: (certification: Certification) => void;
}

export const CertificationsTable = ({ certifications, handleEditAction }: CertificationsTableProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'name',
      label: 'NAME',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'code',
      label: 'CODE',
      minWidth: 100,
      align: 'left',
    },
    /* @NOTE: Hiding since the fields are only used internally so far. */
    // {
    //   id: 'childCertifications',
    //   label: 'CHILD CERTIFICATIONS',
    //   minWidth: 100,
    //   align: 'center',
    //   tooltip:
    //     'Child certifications are included under their related parent certification. Meeting the requirements of a parent certification eliminates the need for individual child certifications.',
    // },
    // {
    //   id: 'parentCertifications',
    //   label: 'PARENT CERTIFICATIONS',
    //   minWidth: 100,
    //   align: 'center',
    //   tooltip:
    //     'Parent certifications cover all related child certifications, meaning that holding a parent certification fulfills the requirements for its child certifications.',
    // },
    {
      id: 'actions',
      label: 'ACTIONS',
      minWidth: 100,
      align: 'left',
    },
  ];

  const data: TableProps['data'] = certifications.map((certification) => ({
    name: <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>{certification.name}</Box>,
    code: (
      <Box display="inline-flex">
        <CertBadge cert={certification} />
      </Box>
    ),
    childCertifications:
      certification.childCertifications && certification.childCertifications.length > 0 ? (
        <CardCertBadges certs={certification.childCertifications} />
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>-</Box>
      ),

    parentCertifications:
      certification.parentCertifications && certification.parentCertifications.length > 0 ? (
        <CardCertBadges certs={certification.parentCertifications} />
      ) : (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>-</Box>
      ),
    actions: (
      <Box>
        <Box
          onClick={() => handleEditAction(certification)}
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              color: theme.palette.stationGray[800],
            },
          })}
          title="Edit Certification"
          data-cy={`edit-certification-${makeTestIdentifier(certification.name)}`}
        >
          <Edit02Icon16 />
        </Box>
      </Box>
    ),
  }));
  return <CustomTable columns={columns} data={data} />;
};
