import { ReactComponent as ChevronLeftSymbol } from '@material-symbols/svg-400/outlined/chevron_left.svg';
import { ReactComponent as ChevronRightSymbol } from '@material-symbols/svg-400/outlined/chevron_right.svg';
import { Box, ButtonBase, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { SvgIcon } from '@stationwise/component-module';

interface MonthSelectorProps {
  value: Date;
  onChange: (newValue: Date) => void;
}

export const MonthSelector = (props: MonthSelectorProps) => {
  const theme = useTheme();

  const renderChevronButton = (direction: 'left' | 'right') => {
    return (
      <ButtonBase
        onClick={() => {
          const newValue = new Date(props.value);
          newValue.setMonth(newValue.getMonth() + (direction === 'right' ? 1 : -1));
          props.onChange(newValue);
        }}
        sx={{
          border: `1px solid ${theme.palette.text.primary}`,
          borderRadius: 1,
          color: theme.palette.text.primary,
          p: '4px 10px',
          '&:hover': { background: theme.palette.action.hover },
        }}
      >
        <SvgIcon component={direction === 'right' ? ChevronRightSymbol : ChevronLeftSymbol} sx={{ fontSize: '18px' }} />
      </ButtonBase>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {renderChevronButton('left')}
      <Box sx={{ flex: 1, typography: 'bodySMedium', textAlign: 'center' }}>{format(props.value, 'MMMM yyyy ')}</Box>
      {renderChevronButton('right')}
    </Box>
  );
};
