import { Box } from '@mui/material';
import { FormEvent } from 'react';
import { RefreshIcon20 } from '../../../assets';
import { Button } from '../../Button';

interface ResetPasswordResultPageBodyProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  isSubmitting: boolean;
  isSuccess: boolean;
}

export const ResetPasswordResultPageBody = ({ onSubmit, isSubmitting, isSuccess }: ResetPasswordResultPageBodyProps) => {
  return (
    <Box component="form" onSubmit={onSubmit}>
      <Box sx={{ mb: 5 }}>
        <Button
          variant="contained"
          size="large"
          type="submit"
          loading={isSubmitting}
          data-cy="reset-password-result-button"
          sx={{ gap: 1.5, width: '100%', '& svg path': { stroke: 'currentColor' } }}
        >
          {!isSuccess && <RefreshIcon20 />}
          {isSuccess ? 'Continue' : 'Try again'}
        </Button>
      </Box>
    </Box>
  );
};
