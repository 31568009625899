import { Box } from '@mui/material';
import { isEqual, isValid } from 'date-fns';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { formatShiftTime, getEmployeeSplitTradeKey } from '@stationwise/shift-summary-helper';
import { SplitErrorResult, SplitProps } from './types';

type GetSplitStatusesProps = Omit<SplitProps, 'splitStatuses' | 'split' | 'splitStatus' | 'index'>;

type GetSplitStatusProps = Omit<SplitProps, 'splitStatuses' | 'splitStatus'>;

export const getTradeSenderSplit = (props: GetSplitStatusProps) => {
  if (props.split.backup.reference.type === 'ASSIGNMENT' && props.split.backup.reference.trade) {
    return (props.employeeSplits.get(props.split.backup.reference.trade.requester.id) || []).find((s) => {
      return (
        s.reference.type === 'SHIFT_TRADE_REQUEST' &&
        s.reference.receiver.id === props.employee.id &&
        s.startDateTime <= props.split.backup.startDateTime &&
        s.endDateTime >= props.split.backup.endDateTime
      );
    });
  }
};

export const checkIsDateTimeDisabled = (props: GetSplitStatusProps, key: 'startDateTime' | 'endDateTime') => {
  const adjacentIndex = props.index + (key === 'endDateTime' ? 1 : -1);
  const adjacentSplit = props.splits[adjacentIndex];
  const senderSplit = getTradeSenderSplit(props);
  return (
    (props.isAdministration && !adjacentSplit) ||
    (props.isPlannedEmployee && !adjacentSplit) ||
    props.split.reference.type === 'SHIFT_TRADE_REQUEST' ||
    !!(
      senderSplit &&
      isEqual(props.split[key], senderSplit[key]) &&
      !(adjacentSplit && getEmployeeSplitTradeKey(props.split.backup) === getEmployeeSplitTradeKey(adjacentSplit.backup))
    )
  );
};

export const getDurationError = ({ split, ...props }: GetSplitStatusProps): SplitErrorResult => {
  const prevSplit = props.splits[props.index - 1];
  const nextSplit = props.splits[props.index + 1];
  const senderSplit = getTradeSenderSplit({ split, ...props });
  if (!isValid(split.startDateTime) || !isValid(split.endDateTime) || split.endDateTime <= split.startDateTime) {
    return true;
  } else if (split.startDateTime < split.minStartDateTime || split.endDateTime > split.maxEndDateTime) {
    return `This split must be between ${formatShiftTime(split.minStartDateTime)} - ${formatShiftTime(split.maxEndDateTime)}.`;
  } else if (prevSplit && split.startDateTime < prevSplit.endDateTime) {
    return 'This split overlaps with the previous split.';
  } else if (nextSplit && split.endDateTime > nextSplit.startDateTime) {
    return 'This split overlaps with the next split.';
  } else if (senderSplit && (split.startDateTime < senderSplit.startDateTime || split.endDateTime > senderSplit.endDateTime)) {
    return `This split must be between the shift trade ${formatShiftTime(senderSplit.startDateTime)} - ${formatShiftTime(senderSplit.endDateTime)}.`;
  } else {
    return false;
  }
};

export const getTimeOffReasonError = ({ split }: GetSplitStatusProps): SplitErrorResult => {
  if (!(split.backup.reference.type === 'ASSIGNMENT' && split.reference.type === 'TIME_OFF_REQUEST')) {
    return false;
  }
  return !split.reference.payCodeId;
};

export const getTimeOffUnsavedMinutes = ({ splits }: Pick<SplitProps, 'splits'>) => {
  const map = new Map<number, number>();
  splits.forEach((split) => {
    if (split.reference.type === 'TIME_OFF_REQUEST' && split.reference.id <= 0) {
      let minutes = map.get(split.reference.payCodeId) || 0;
      minutes += differenceInUTCMinutes(split.endDateTime, split.startDateTime);
      map.set(split.reference.payCodeId, minutes);
    }
  });
  return map;
};

export const getTimeOffAccrualLimitError = ({ split, ...props }: GetSplitStatusProps): SplitErrorResult => {
  if (
    !props.shiftSummaryHelper.departmentInfo.settings.accrualLogicEnabled ||
    !(split.backup.reference.type === 'ASSIGNMENT' && split.reference.type === 'TIME_OFF_REQUEST')
  ) {
    return false;
  }

  const option = props.timeOffReasonOptions.get(split.reference.payCodeId);
  if (!option || option.limit === null || option.isUnlimited) {
    return false;
  }

  const newAccruedDurationMinutes = getTimeOffUnsavedMinutes(props).get(split.reference.payCodeId) || 0;
  if (newAccruedDurationMinutes <= 60 * option.limit) {
    return false;
  }

  const durationMinutes = differenceInUTCMinutes(split.endDateTime, split.startDateTime);
  const limitMinutes = Math.max(0, 60 * option.limit - newAccruedDurationMinutes + durationMinutes);
  return (
    <>
      This split is {(durationMinutes / 60).toFixed(2)} hours, but this person only has{' '}
      <Box component="span" sx={{ fontWeight: 600 }}>
        {(limitMinutes / 60).toFixed(2)} accrued hours
      </Box>
      . Shorten the split or change the time off reason.
    </>
  );
};

export const getSplitStatuses = (props: GetSplitStatusesProps) => {
  return props.splits.map((split, index) => {
    const isStartDateTimeDisabled = checkIsDateTimeDisabled({ split, index, ...props }, 'startDateTime');
    const isEndDateTimeDisabled = checkIsDateTimeDisabled({ split, index, ...props }, 'endDateTime');

    const errors = new Map<string, SplitErrorResult>();
    errors.set('DURATION', getDurationError({ split, index, ...props }));
    errors.set('TIME_OFF_REASON', getTimeOffReasonError({ split, index, ...props }));
    errors.set('TIME_OFF_ACCRUAL_LIMIT', getTimeOffAccrualLimitError({ split, index, ...props }));

    return { isStartDateTimeDisabled, isEndDateTimeDisabled, errors };
  });
};
