import { Box, useTheme } from '@mui/material';
import { RankBadge, TeamBadge } from '@stationwise/component-module';
import { ShiftPlanAction, ShiftPlanCandidate, ShiftPlanAssignment } from '@stationwise/share-types';
import { shortenEmployeeName } from '@stationwise/share-utils';
import { prettifyTeamPatternString } from '../../../helpers/parseTeamPattern';
import { formatAssignment } from '../../../helpers/readAssignments';
import { useShiftPlanContext } from '../../ShiftPlanContext';

interface CandidateInfoProps {
  person: ShiftPlanCandidate | ShiftPlanAssignment | null;
  team: ShiftPlanAssignment['employee']['team'] | null;
}

export const CandidateInfo = ({ person, team }: CandidateInfoProps) => {
  const theme = useTheme();
  const { selectedAction } = useShiftPlanContext();

  const candidate = !person || 'employee' in person ? null : (person as ShiftPlanCandidate);
  if (selectedAction !== ShiftPlanAction.VIEW_CANDIDATE || !candidate) {
    return null;
  }

  return (
    <Box sx={{ p: 2, width: '376px', maxHeight: '670px' }}>
      <Box sx={{ typography: 'heading6' }}>Team detail</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pt: 1 }}>
        <RankBadge rank={candidate.rank} />
        <Box sx={{ typography: 'bodySMedium' }}>{shortenEmployeeName(candidate.name)}</Box>
      </Box>
      <Box sx={{ pt: 2 }}>
        {team && <TeamBadge team={team} />}
        {!team && <Box sx={{ typography: 'bodySMedium' }}>Unassigned</Box>}
      </Box>
      <Box sx={{ pt: 2 }}>
        <Box sx={{ color: theme.palette.text.secondary, pb: 0.5, typography: 'bodySMedium' }}>Pattern type</Box>
        {team ? prettifyTeamPatternString(team.pattern) : 'N/A'}
      </Box>
      {candidate.assignments.map((assignment) => {
        if (!team || assignment.employee.team.id !== team.id) {
          return null;
        }

        const assignmentTexts = formatAssignment(assignment);

        return (
          <Box key={assignment.id} sx={{ pt: 2 }}>
            <Box sx={{ color: theme.palette.text.secondary, pb: 0.5, typography: 'bodySMedium' }}>
              {`Effective ${assignmentTexts.activationDate} - ${assignmentTexts.deactivationDate}`}
            </Box>
            {`${assignmentTexts.location}, ${assignmentTexts.startTime} - ${assignmentTexts.endTime}`}
            <br />
            {`Pay codes: ${assignmentTexts.payCodes}`}
            <br />
            {`Detail codes: ${assignmentTexts.detailCodes}`}
          </Box>
        );
      })}
    </Box>
  );
};
