import { Tabs, Tab, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { ErrorBanner } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { WorkGroupPreview } from '@stationwise/share-types';
import { MyTeamMain } from './MyTeamMain';
import { WorkGroupsMain } from './WorkGroupsMain';

export const TeamSettings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [workGroups, setWorkGroups] = useState<WorkGroupPreview[]>([]);
  const [updateWorkGroups, setUpdateWorkGroups] = useState<number>(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setUpdateWorkGroups((prev) => prev + 1);
    setSelectedTab(newValue);
  };

  const fetchWorkGroupView = async (): Promise<WorkGroupPreview[]> => {
    try {
      const response = await client.get('/organization/workgroups/detailed-list/');
      setIsSubmitting(false);
      return response.data;
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data.error : '';
      if (!message) {
        captureException(error);
      }
      setErrorMessage(message || 'Something went wrong please try again later');
      setIsSubmitting(false);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsSubmitting(true);
      setErrorMessage('');
      const response = await fetchWorkGroupView();
      setWorkGroups(response);
    };
    fetchData();
  }, [updateWorkGroups]);
  const errorBanner = !isSubmitting && !!errorMessage && <ErrorBanner sx={{ mt: -1, mb: 2 }}>{errorMessage}</ErrorBanner>;

  return (
    <Box>
      <Box sx={{ px: 5, pt: 4, pb: 8 }}>
        <Box component="h1" sx={{ m: 0, typography: 'heading4' }}>
          Employees
        </Box>
      </Box>
      <Box sx={{ minWidth: '1130px', width: '80%', px: 5, mx: 'auto' }}>
        <Tabs
          sx={(theme) => ({
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.stationPurple[500],
            },
            textTransform: 'none',
          })}
          value={selectedTab}
          onChange={handleTabChange}
        >
          <Tab
            sx={(theme) => ({
              textTransform: 'none',
              color: theme.palette.stationGray[400],
              typography: 'bodyXLMedium',
              '&.Mui-selected': {
                color: theme.palette.stationGray[900],
              },
              '&:hover': {
                color: theme.palette.stationGray[900],
              },
            })}
            label="All Employees"
          />

          <Tab
            data-cy="work-groups-tab"
            sx={(theme) => ({
              textTransform: 'none',
              color: theme.palette.stationGray[400],
              typography: 'bodyXLMedium',
              '&.Mui-selected': {
                fontWeight: 'fontWeightMedium',
                color: theme.palette.stationGray[900],
              },
              '&:hover': {
                color: theme.palette.stationGray[900],
              },
            })}
            label="Work groups"
          />
        </Tabs>
        <Box mt={2}>
          {errorBanner}
          {selectedTab === 0 && <MyTeamMain workGroupsView={workGroups} updateFunc={setUpdateWorkGroups} />}
          {selectedTab === 1 && <WorkGroupsMain workGroupsView={workGroups} />}
        </Box>
      </Box>
    </Box>
  );
};
