import {
  ShiftSummaryEmployeeAssignmentReference,
  RosterStation,
  RosterApparatus,
  RosterPosition,
  RosterEmployee,
  RosterEmployeeSplit,
  RosterEmployeeOffPayloads,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { checkIsShift, checkIsEvent, checkIsAdministration, checkIsStrikeTeamApparatus } from './id';
import { IShiftSummaryHelper } from './types';

interface GetEmployeeSplitsInput extends RosterEmployeeOffPayloads {
  shiftSummaryHelper: IShiftSummaryHelper;
}

/**
 * This method is the single source of truth for employee splits, gathered from the following sources.
 * - Current battalion position assignments: input.shiftSummaryHelper.allStationCards
 * - Current battalion excess capacity assignments: input.shiftSummaryHelper.allStationCards
 * - Strike team assignments: input.shiftSummaryHelper.allStationCards
 * - Floater assignments: input.shiftSummaryHelper.unassignedCards
 * - Other battalion assignments: input.shiftSummaryHelper.employeeSplits
 * - Existing offs: input.shiftSummaryHelper.employeeSplits
 * - New offs: input.create{OffType}Payloads
 *
 * ⚠ For now, input.shiftSummaryHelper.employeeSplits is NOT the single source of truth.
 *   It may look like it tracks all types of splits, but actually it is only responsible for the two types listed above.
 *   The other types may be outdated.
 *
 * Eventually, the goal is to make input.shiftSummaryHelper.employeeSplits the single source of truth.
 * - Remove this method.
 * - Remove position.employees.
 */
export const getEmployeeSplits = (input: GetEmployeeSplitsInput) => {
  const { currentBattalion, shiftDuration } = input.shiftSummaryHelper;
  const employeeSplits = new Map<string, RosterEmployeeSplit[]>();

  const pushSplit = (employeeId: string, split: RosterEmployeeSplit) => {
    const splits = employeeSplits.get(employeeId) || [];
    splits.push(split);
    employeeSplits.set(employeeId, splits);
  };

  const strikeTeamApparatusIds = new Set<string>();
  input.shiftSummaryHelper.allStationCards.forEach((station) => {
    if (checkIsShift(station) || checkIsEvent(station) || checkIsAdministration(station)) {
      station.apparatuses.forEach((apparatus) => {
        if (checkIsStrikeTeamApparatus(apparatus) && apparatus.strikeTeam.fakeApparatusId > 0) {
          strikeTeamApparatusIds.add(`${apparatus.strikeTeam.fakeApparatusId}`);
        }
        apparatus.positions.forEach((position) => {
          position.employees.forEach((employee) => {
            pushSplit(employee.id, makeAssignmentSplit(input.shiftSummaryHelper, station, apparatus, position, employee));
          });
        });
      });
    }
  });

  input.shiftSummaryHelper.unassignedCards.forEach((employee) => {
    pushSplit(employee.id, makeAssignmentSplit(input.shiftSummaryHelper, null, null, null, employee));
  });

  const cancelledOffKeys = new Set([
    ...input.cancelTemporaryNonShiftAssignmentPayloads.map((id) => `TEMPORARY_NON_SHIFT:${id}`),
    ...input.cancelShiftTradePayloads.map(({ shiftTradeId: id }) => `SHIFT_TRADE_REQUEST:${id}`),
    ...input.cancelTimeOffPayloads.map(({ timeOffId: id }) => `TIME_OFF_REQUEST:${id}`),
  ]);
  input.shiftSummaryHelper.employeeSplits.forEach((splits, employeeId) => {
    splits.forEach((split) => {
      if (split.reference.type === 'ASSIGNMENT') {
        const isOtherBattalion = split.reference.battalion && split.reference.battalion.id !== currentBattalion.id;
        const isStrikeTeam = split.reference.apparatus && strikeTeamApparatusIds.has(`${split.reference.apparatus.id}`);
        if (isOtherBattalion && !isStrikeTeam) {
          pushSplit(employeeId, split);
        }
      } else {
        if (!cancelledOffKeys.has(`${split.reference.type}:${split.reference.id}`)) {
          pushSplit(employeeId, split);
        }
      }
    });
  });

  input.createTemporaryNonShiftAssignmentPayloads.forEach((payload) => {
    pushSplit(payload.candidateId, {
      startDateTime: shiftDuration.startTime,
      endDateTime: shiftDuration.endTime,
      minStartDateTime: shiftDuration.startTime,
      maxEndDateTime: shiftDuration.endTime,
      reference: { type: 'TEMPORARY_NON_SHIFT', id: 0 },
    });
  });

  employeeSplits.forEach((splits) => splits.sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime)));
  return employeeSplits;
};

export const makeAssignmentSplit = (
  { currentBattalion, shiftDuration }: IShiftSummaryHelper,
  station: RosterStation | null,
  apparatus: RosterApparatus | null,
  position: RosterPosition | null,
  employee: RosterEmployee,
) => {
  const reference: ShiftSummaryEmployeeAssignmentReference = {
    type: 'ASSIGNMENT',
    battalion: null,
    station: null,
    apparatus: null,
    position: null,
    payCodes: employee.payCodes,
    detailCodes: employee.detailCodes,
    trade: employee.trade,
  };
  if (station && apparatus) {
    reference.battalion = { id: currentBattalion.id, name: currentBattalion.name };
    reference.station = { id: `${station.stationId}`, name: station.stationName };
    reference.apparatus = { id: `${apparatus.id}`, name: apparatus.name };
  }
  if (position && !position.isTemporary) {
    reference.position = { id: `${position.id}`, rank: position.rank };
  }
  return {
    startDateTime: employee.startDateTime,
    endDateTime: employee.endDateTime,
    minStartDateTime: position && !position.isTemporary ? position.startDateTime : shiftDuration.startTime,
    maxEndDateTime: position && !position.isTemporary ? position.endDateTime : shiftDuration.endTime,
    employee,
    reference,
  };
};

export const getEmployeeSplitTradeKey = (split: Pick<RosterEmployeeSplit, 'reference'>) => {
  const trade = split.reference.type === 'ASSIGNMENT' && split.reference.trade;
  return !trade ? '' : `${trade.id}|${trade.requester.id}`;
};

export const getEmployeeSplitReferenceKey = (split: Pick<RosterEmployeeSplit, 'reference'>) => {
  const keys: string[] = [split.reference.type];
  if (split.reference.type === 'ASSIGNMENT') {
    const { apparatus, position } = split.reference;
    keys.push(`${apparatus?.id}`, `${position?.id}`, getEmployeeSplitTradeKey(split));
  } else if (split.reference.type === 'SHIFT_TRADE_REQUEST') {
    const { id, isOvertime, receiver, positionId } = split.reference;
    keys.push(`${id}`, `${isOvertime}`, `${receiver.id}`, `${positionId}`);
  } else if (split.reference.type === 'TIME_OFF_REQUEST') {
    keys.push(`${split.reference.id}`, `${split.reference.payCodeId}`, `${split.reference.positionId}`);
  } else {
    keys.push(`${split.reference.id}`);
  }
  return keys.join('|');
};
