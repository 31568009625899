import { EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EmployeeProfileDetailsView, RosterEmployee } from '@stationwise/share-types';
import { DrawerContent } from '../../../../../../app/pages/Calendar/components/DrawerContent';
import { Star05Icon16, XCloseIcon24 } from '../../assets';
import { RankBadge } from '../Badge';
import { Calendar, PayPeriodDateInfo, getDisplayOptionByName } from '../EmployeeCalendar';
import { formatDate, parseDateParam, fetchEvents, fetchPayPeriods } from '../EmployeeCalendar/calendarHelper';
import { GenericDrawerOrModal } from '../GenericDrawerOrModal';
import { TeamFlag } from '../TeamFlag';

interface CalendarModalProps {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalOpen: boolean;
  employee: RosterEmployee | EmployeeProfileDetailsView;
}

export const CalendarModal = ({ setModalOpen, modalOpen, employee }: CalendarModalProps) => {
  const [todayEvents, setTodayEvents] = useState<EventInput[]>([]);
  const [searchParams] = useSearchParams();
  const [selectedDate, setSelectedDate] = useState(() => parseDateParam(searchParams.get('date') || ''));
  const [selectedView, setSelectedView] = useState(() => getDisplayOptionByName(searchParams.get('display') || '').value);
  const [refetchEvents, setRefetchEvents] = useState<boolean>(false);
  const [_, setSelectedEvent] = useState<EventInput | EventImpl>({});
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [payPeriodDates, setPayPeriodDates] = useState<PayPeriodDateInfo[]>([]);

  useEffect(() => {
    const calculateCalendarHeight = () => {
      const windowHeight = window.innerHeight;
      const fixedBoxHeight = 140;
      const heightLeft = windowHeight - fixedBoxHeight - 179;
      setCalendarHeight(heightLeft);
    };

    calculateCalendarHeight();

    window.addEventListener('resize', calculateCalendarHeight);

    return () => window.removeEventListener('resize', calculateCalendarHeight);
  }, []);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        const dates = await fetchPayPeriodDates(startOfMonth(selectedDate), endOfMonth(selectedDate));
        setPayPeriodDates(dates);
      } catch {
        setPayPeriodDates([]);
      }
    };

    fetchDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayDayEvents = (dayEvents: EventInput[]) => {
    setTodayEvents(dayEvents);
  };

  const getDateIntervalEvents = (fetchInfo: EventSourceFuncArg) => {
    const formattedStartDate = formatDate(fetchInfo.start);
    const formattedEndDate = formatDate(fetchInfo.end);
    return fetchEvents({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      employeeId: employee.id,
    });
  };

  const fetchPayPeriodDates = async (startDate: Date, endDate: Date): Promise<PayPeriodDateInfo[]> => {
    return fetchPayPeriods({ startDate, endDate, employeeId: employee.id });
  };

  return (
    <GenericDrawerOrModal drawerOpen={modalOpen} handleOnClose={() => setModalOpen(false)} loading={false} anchor="bottom">
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '16px',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
          })}
        >
          <Box
            sx={(theme) => ({
              py: '10px',
              color: theme.palette.stationPurple[600],
              backgroundColor: theme.palette.stationPurple[100],
              height: '40px',
              width: '100%',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={(theme) => ({ display: 'flex', pl: theme.spacing(3), alignItems: 'center', gap: '8px' })}>
              <Star05Icon16 />
              <Typography variant="bodySMedium">Viewing as admin</Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: theme.palette.stationGray[400],
                pr: '10px',
                '&:hover': { color: theme.palette.stationGray[500] },
              })}
              onClick={(event) => {
                event.stopPropagation();
                setModalOpen(!modalOpen);
              }}
            >
              <XCloseIcon24 />
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              pt: theme.spacing(3),
              px: theme.spacing(3),
              mb: theme.spacing(2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <RankBadge rank={employee.rank} />
              <Typography variant="bodyXLSemibold">{employee.name}</Typography>
            </Box>
            {employee.team && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TeamFlag color={employee.team.color} />
                <Typography variant="bodySMedium" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
                  {employee.team.name}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              touchAction: 'unset',
              height: '100%',
              position: 'relative',
              minHeight: '1000px',
            }}
          >
            <Calendar
              displayDayEvents={displayDayEvents}
              getDateIntervalEvents={getDateIntervalEvents}
              selectedDate={selectedDate}
              selectedView={selectedView}
              refetchEvents={refetchEvents}
              setRefetchEvents={setRefetchEvents}
              setSelectedDate={setSelectedDate}
              setSelectedEvent={setSelectedEvent}
              setSelectedView={setSelectedView}
              heightOfCalendar={calendarHeight}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              viewingPersonalCalendar
              setDrawerOpen={setDrawerOpen}
              fetchPayPeriods={fetchPayPeriodDates}
            />
            {drawerOpen && (
              <Box
                sx={(theme) => ({
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  backgroundColor: theme.palette.common.white,
                  zIndex: theme.zIndex.drawer,
                })}
              >
                <DrawerContent
                  setDrawerOpen={setDrawerOpen}
                  selectedDate={selectedDate}
                  todayEvents={todayEvents}
                  selectedView={selectedView}
                  payPeriodDates={payPeriodDates}
                  viewOnly={true}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </GenericDrawerOrModal>
  );
};
