import { Box, Modal, TextField } from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { Button, Input, DatePicker, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { CustomField } from '@stationwise/share-types';
import { usePatchRequest } from './PatchRequestProvider';

interface EditExtraInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  customFields: CustomField[];
}

export const EditExtraInfo = ({ showModal, setShowModal, customFields }: EditExtraInfoProps) => {
  const [newCustomFields, setNewCustomFields] = useState<CustomField[]>(customFields);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const { handlePatchRequest } = usePatchRequest();

  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;

  const handleCustomFieldChange = (customField: CustomField, newValue: string | null) => {
    let fields: CustomField[] = [];
    newCustomFields.forEach((cf) => {
      if (cf.name == customField.name) {
        if (cf.type === 'FLOAT') {
          setIsSaveDisabled(isNaN(Number(newValue)));
        }
        cf.value = newValue;
      }
      fields.push(cf);
    });
    setNewCustomFields(fields);
  };

  const handleSave = () => {
    const data = {
      customFields: newCustomFields,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setNewCustomFields(customFields);
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Miscellaneous
          </Box>

          {newCustomFields.map((customField) => {
            return (
              <Box key={customField.name}>
                <Box
                  sx={(theme) => ({
                    typography: 'bodySMedium',
                    textAlign: 'left',
                    paddingTop: theme.spacing(2),
                    color: theme.palette.stationGray[700],
                  })}
                >
                  {`${customField.name} ${customField.name.includes('relief group') ? '(yes/no)' : customField.name.includes('Relief team') ? departmentInfo.teams.map((team) => team.name).join('|') : ''}`}
                </Box>

                <Box
                  sx={(theme) => ({
                    width: '100%',
                    mt: theme.spacing(1),
                  })}
                >
                  {(customField.type == 'TEXT' || customField.type == 'FLOAT') && (
                    <TextField
                      fullWidth
                      defaultValue={customField.value ?? ''}
                      onChange={(e) => handleCustomFieldChange(customField, e.target.value)}
                    />
                  )}
                  {customField.type == 'FLOAT' && (
                    <Box sx={{ mt: 1, typography: 'bodyXSRegular' }}>Use . as decimal separator if you need it.</Box>
                  )}
                  {customField.type == 'DATE' && (
                    <DatePicker
                      value={customField.value ? parseISO(customField.value.toString()) : null}
                      onChange={(newValue) =>
                        handleCustomFieldChange(
                          customField,
                          newValue && isValid(newValue) ? format(newValue, 'yyyy-MM-dd') : null,
                        )
                      }
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { placeholder: 'Choose date' } }}
                    />
                  )}
                  {customField.type == 'INT' && (
                    <Input
                      fullWidth
                      type="number"
                      defaultValue={customField.value ?? ''}
                      onChange={(e) => handleCustomFieldChange(customField, e.target.value)}
                    />
                  )}
                </Box>
              </Box>
            );
          })}

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button variant="outlined" size="large" sx={{ width: '216px' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              disabled={isSaveDisabled}
              variant="contained"
              size="large"
              sx={{ width: '216px' }}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
