import { Box, useTheme } from '@mui/material';
import { Edit02Icon16, Trash04Icon16 } from '@stationwise/component-module';
import { makeTestIdentifier } from '@stationwise/share-utils';
interface SubMenuProps {
  handleEdit: () => void;
  handleRemove: () => void;
}

export const SubMenu = ({ handleEdit, handleRemove }: SubMenuProps) => {
  const theme = useTheme();
  const menuItems = [
    {
      label: 'Edit',
      icon: Edit02Icon16,
      action: () => {
        handleEdit();
      },
      color: theme.palette.stationGray[600],
    },
    {
      label: 'Remove',
      icon: Trash04Icon16,
      action: () => {
        handleRemove();
      },
      color: theme.palette.stationRose[400],
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        padding: 1.5,
        borderRadius: 3,
        border: `1px solid ${theme.palette.stationGray[100]}`,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 4px 6px -2px rgba(10, 14, 22, 0.05), 0px 10px 15px -3px rgba(10, 14, 22, 0.10);',
      })}
    >
      {menuItems.map((item, index) => {
        const Icon = item.icon;
        return (
          <Box
            data-cy={`sub-menu-item-${makeTestIdentifier(item.label)}`}
            key={index}
            onClick={item.action}
            sx={(theme) => ({
              width: '144px',
              padding: '8px 12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 1.5,
              borderRadius: theme.spacing(1),
              color: item.color,
              typography: 'bodyMRegular',
              '&:hover': {
                backgroundColor: theme.palette.stationGray[100],
                cursor: 'pointer',
              },
            })}
          >
            <Icon />
            {item.label}
          </Box>
        );
      })}
    </Box>
  );
};
