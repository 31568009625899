import { Box } from '@mui/material';
import React from 'react';
import { User02Icon16 } from '../../../../assets';
import { Counter, CounterProps } from '../../../Counter';

interface MinReqRecordProps extends CounterProps {
  children: React.ReactNode;
  size?: 'sm' | 'lg';
}

export const MinReqRecord = ({ children, size = 'sm', ...props }: MinReqRecordProps) => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.stationGray[700],
        p: size === 'lg' ? '22px 12px' : '8px 12px',
        borderRadius: '12px',
        display: 'flex',
        gap: 1,
      })}
    >
      <Box sx={{ display: 'flex', flex: 1 }}>{children}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box component={User02Icon16} sx={(theme) => ({ color: theme.palette.stationGray[400] })} />
        <Counter mode="dark" {...props} />
      </Box>
    </Box>
  );
};
