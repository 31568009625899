import { useEffect } from 'react';
import { useShiftPlanContext } from '../ShiftPlanContext';

export const ShiftPlanCloseDialogResetContextEffect = () => {
  const { setIsSaving } = useShiftPlanContext();

  useEffect(() => {
    // Reset these values after the parent dialog is animating away.
    return () => {
      setIsSaving(false);
    };
  }, [setIsSaving]);

  return null;
};
