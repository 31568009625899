import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider } from '@mui/material';
import { startOfDay } from 'date-fns';
import {
  theme,
  UmbrellaIcon24,
  SwitchHorizontal02Icon24,
  BankNoteIcon24,
  ChevronRightIcon24,
  XCircleIcon24,
  useLoadedAuthUserContext,
  hasTemporaryNonShiftEvent,
  hasKellyDay,
  SHIFT_TITLES,
  CALENDAR_EVENT_TYPES,
  SHIFT_OVERVIEW_OPTIONS,
} from '@stationwise/component-module';

interface ShiftOverviewOptionsProps {
  handleSelect: (option: string) => void;
  shift: EventInput | EventImpl;
  todayEvents: EventInput[];
  handleClick?: () => void;
}

type DateRange = 'past' | 'future' | 'today';

export const ShiftOverviewOptions = ({ handleSelect, shift, todayEvents, handleClick }: ShiftOverviewOptionsProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();

  const determineDateRange = (shift: EventInput | EventImpl): DateRange => {
    const today = startOfDay(new Date());
    const tomorrow = startOfDay(new Date(today));
    tomorrow.setDate(today.getDate() + 1);
    if (shift.start && new Date(shift.start as string) >= tomorrow) {
      return 'future';
    } else if (shift.start && new Date(shift.start as string) < today) {
      return 'past';
    } else {
      return 'today';
    }
  };

  const hasShiftTrade = todayEvents.some((event) => event.title === SHIFT_TITLES.TRADE_REQUESTED);
  const disableTradeShift =
    todayEvents.some((event) => event.title === SHIFT_TITLES.OVERTIME) || shift.extendedProps?.['isOvertime'] === true;
  const dateRange = determineDateRange(shift);

  const handleOnClick = (option: string) => {
    handleSelect(option);
    if (handleClick) {
      handleClick();
    }
  };

  if (hasTemporaryNonShiftEvent(todayEvents) || hasKellyDay(todayEvents)) {
    return null;
  }

  if (shift.extendedProps?.['eventType'] === CALENDAR_EVENT_TYPES.STAFFING_LIST_MARK) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.CANCEL_OVERTIME_REQUEST)}
          sx={(theme) => ({
            py: theme.spacing(2),
            cursor: 'pointer',
          })}
        >
          <Box display="flex">
            <Box
              sx={(theme) => ({
                color: theme.palette.stationRose[400],
              })}
            >
              <XCircleIcon24 />
            </Box>
            <Box
              sx={{
                ml: theme.spacing(2),
                color: theme.palette.stationRose[600],
                typography: 'bodyLMedium',
              }}
            >
              {`Cancel ${shift.title?.toLowerCase()}`}
            </Box>
          </Box>

          <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
            <ChevronRightIcon24 />
          </Box>
        </Box>

        <Divider light />
      </>
    );
  }

  return (
    <>
      {!authUserState.employee.isNonShift && dateRange !== 'past' && !hasShiftTrade && !disableTradeShift ? (
        <>
          <Box
            data-cy="trade-shift-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.TRADE_SHIFT)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <SwitchHorizontal02Icon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Trade shift
              </Box>
            </Box>

            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'past' ? (
        <>
          <Box
            data-cy="request-time-off-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.REQUEST_TIME_OFF)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <UmbrellaIcon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Request time off
              </Box>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}

      {dateRange !== 'future' ? (
        <>
          <Box
            data-cy="request-additional-paid-time-button"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleOnClick(SHIFT_OVERVIEW_OPTIONS.ADDITIONAL_PAID_TIME)}
            sx={(theme) => ({
              py: theme.spacing(2),
              cursor: 'pointer',
            })}
          >
            <Box display="flex">
              <Box
                sx={(theme) => ({
                  '& svg': {
                    '& path': {
                      stroke: theme.palette.stationGray[400],
                    },
                  },
                })}
              >
                <BankNoteIcon24 />
              </Box>
              <Box
                sx={{
                  ml: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                  typography: 'bodyLMedium',
                }}
              >
                Request additional paid time
              </Box>
            </Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
              <ChevronRightIcon24 />
            </Box>
          </Box>
          <Divider light />
        </>
      ) : null}
    </>
  );
};
