import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { FeedbackMessage, GenericDrawerOrModal, SnackbarService, useFetchStaffingLists } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { CancelRequest } from '../CancelRequest';

interface CancelOvertimeRequestProps {
  handleOnClose: Dispatch<SetStateAction<boolean>>;
  drawerOpen: boolean;
  shift: EventInput | EventImpl;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
}

export const CancelOvertimeRequest = ({ shift, handleOnClose, drawerOpen, setRefetchEvents }: CancelOvertimeRequestProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const isOptOut = shift.title?.includes('OPT OUT');
  const { data } = useFetchStaffingLists();
  const staffingLists = data?.map((sl: ListFieldsStaffingList) => sl) || [];

  const cancelOvertimeRequest = async () => {
    let success = false;
    try {
      const response = await client.put(`/staffing-list/staffing-lists/${shift.id}/cancel-my-mark/`);
      success = response.status === 200;
      if (success) {
        setRefetchEvents(true);
      }
    } catch {
      success = false;
    } finally {
      setIsLoading(false);
      handleOnClose(false);
      SnackbarService.notify({
        content: success ? getSnackbarText() : 'There was an issue processing your request. ',
        severity: success ? 'success' : 'error',
        ...(success
          ? { showCloseButton: true }
          : {
              onCallToAction: () => handleRequest(),
              actionButtonText: 'Try again',
            }),
        duration: 5000,
      });
    }
  };

  const getSnackbarText = () => {
    return `Your ${shift.title?.toLowerCase()} was successfully canceled for the selected day.`;
  };

  const handleRequest = () => {
    setIsLoading(true);
    cancelOvertimeRequest();
  };

  const staffingList = staffingLists.find((sl) => sl.id === Number(shift?.extendedProps?.['staffingListId']));
  const signupAfterCancelAllowed =
    staffingList?.signUpableShiftDatesConfiguration?.allowSignupAfterCancel === undefined
      ? true
      : staffingList?.signUpableShiftDatesConfiguration?.allowSignupAfterCancel;
  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={() => handleOnClose(false)}
      loading={false}
      showHeader={false}
      disableFooter={true}
    >
      <div>
        <Box>
          <FeedbackMessage
            status="question"
            firstMessage={`Do you really wish to cancel the ${shift.title?.toLowerCase()}`}
            secondMessage={
              isOptOut
                ? ''
                : `After you cancel your ${shift.title?.toLowerCase()}, your battalion chief will be informed${!signupAfterCancelAllowed ? " and you won't be able to request it for this day again" : ''}.`
            }
          />
          <CancelRequest handleOnClose={handleOnClose} handleRequest={handleRequest} isOptOut={isOptOut} isLoading={isLoading} />
        </Box>
      </div>
    </GenericDrawerOrModal>
  );
};
